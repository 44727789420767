// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Servizee from '../../public/images/ProjectsImages/Servizee.png'
// import Elite from '../../public/images/ProjectsImages/EliteMoversandPackers.png'
// import QuickBucks from '../../public/images/ProjectsImages/Quickbucks.png'
// import tcs from '../../public/images/ProjectsImages/tcs.png'
// import Swiper from "swiper";
// import '../../styles/style.css'
// import oskin from '../../public/testmonials/oskin'


// export default function ApplicationDev() {
//     const navigate = useNavigate()

//     const [feedbackSlider, setfeedbackSlider] = useState(null);
//     useEffect(() => {
//         if (!feedbackSlider) {
//             const brandfeedbackSlider = new Swiper('#FeedbackSlider', {
//                 breakpoints: {
//                     0: { slidesPerView: 1 },
//                     576: { slidesPerView: 1 },
//                     768: { slidesPerView: 2 },
//                     992: { slidesPerView: 2 },
//                     1200: { slidesPerView: 3 },
//                 },
//                 loop: true,
//             });
//             setfeedbackSlider(brandfeedbackSlider);
//         }
//     }, [feedbackSlider]);

//     useEffect(() => {
//         const slideNextWithDelay = () => {
//             if (feedbackSlider) {
//                 setTimeout(() => {
//                     feedbackSlider.slideNext();
//                     slideNextWithDelay();
//                 }, 5000);
//             }
//         };

//         slideNextWithDelay();

//         return () => clearTimeout();
//     }, [feedbackSlider]);

//     const slideToPrev = () => {
//         if (feedbackSlider) {
//             feedbackSlider.slidePrev();
//         }
//     };

//     const slideToNext = () => {
//         if (feedbackSlider) {
//             feedbackSlider.slideNext();
//         }
//     };



//     return (
//         <>
//             <section class="bg-top-center space " style={{ heigh: '150px', width: '100%', paddingBottom: '50px', paddingLeft: '80px', paddingRight: '50px' }}>
//                 <div>
//                     <div class="slider-area">
//                         <div class="swiper th-slider has-shadow" id="FeedbackSlider" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'>
//                             <div class="swiper-wrapper">

//                                 <div class="swiper-slide" style={{ marginLeft: '10px', zIndex: '1' }}>
//                                     <div
//                                         data-aos="fade-up"
//                                         data-aos-delay="100"
//                                         data-aos-duration="500"
//                                         data-aos-once="true"
//                                     >
//                                         <div className="single-feedback-box">
//                                             <div className="feedback-desc">
//                                                 <p>
//                                                     I have taken their services about  3 month ago,getting much enquiries and business due to this company's hardwork.The team take accountability of the task with full professionalism and ethics....
//                                                 </p>
//                                             </div>

//                                             <div className="client-info">
//                                                 <img
//                                                     src='//assets/images/testimonials/thumbs/Hardcrop.jpeg'
//                                                     alt="img"
//                                                     width={150}
//                                                     height={150}
//                                                 />
//                                                 <h3>Vineetha Reddy</h3>
//                                                 <span>HARDCORP GYM, HYDERABAD</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div class="swiper-slide" style={{ marginLeft: '10px', zIndex: '1' }}>
//                                     <div
//                                         data-aos="fade-up"
//                                         data-aos-delay="100"
//                                         data-aos-duration="500"
//                                         data-aos-once="true"
//                                     >
//                                         <div className="single-feedback-box">
//                                             <div className="feedback-desc">
//                                                 <p>
//                                                     I personally recommended skill Technologies for Social media marketing & lead generation. for my business we got timely response and leads through Social media!
//                                                 </p>
//                                             </div>

//                                             <div className="client-info">
//                                                 <img
//                                                     src='//assets/images/testimonials/thumbs/oskin.png'
//                                                     alt="img"
//                                                     width={150}
//                                                     height={150}
//                                                 />
//                                                 <h3>Vineetha Reddy</h3>
//                                                 <span>Oskin Clinic, Hydrabad</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div class="swiper-slide" style={{ marginLeft: '10px', zIndex: '1' }}>
//                                     <div
//                                         data-aos="fade-up"
//                                         data-aos-delay="100"
//                                         data-aos-duration="500"
//                                         data-aos-once="true"
//                                     >
//                                         <div className="single-feedback-box">
//                                             <div className="feedback-desc">
//                                                 <p>

//                                                     I'm pretty. I am very happy share my review about skill technologies. it's is the one of the best social media agency in Hyderabad helped with Pinterest platform to drive traffic to our website. we received 5 organic leads
//                                                 </p>
//                                             </div>

//                                             <div className="client-info">
//                                                 <img
//                                                     src='//assets/images/testimonials/thumbs/blank.png'
//                                                     alt="img"
//                                                     width={150}
//                                                     height={150}
//                                                 />
//                                                 <h3>Vaishnavi</h3>
//                                                 <span>Hyderabad</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div class="swiper-slide" style={{ marginLeft: '10px', zIndex: '1' }}>
//                                     <div
//                                         data-aos="fade-up"
//                                         data-aos-delay="100"
//                                         data-aos-duration="500"
//                                         data-aos-once="true"
//                                     >
//                                         <div className="single-feedback-box">
//                                             <div className="feedback-desc">
//                                                 <p>

//                                                     When you choose Skill technologies you get a wonderful, professional team with innovative ideas, awesome customer service, and exactly what you’re looking for.
//                                                 </p>
//                                             </div>

//                                             <div className="client-info">
//                                                 <img
//                                                     src='	//assets/images/testimonials/thumbs/blank.png'
//                                                     alt="img"
//                                                     width={150}
//                                                     height={150}
//                                                 />
//                                                 <h3>Bhanu Teja</h3>
//                                                 <span>Hyderabad</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {/* <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
//                 <div
//               data-aos="fade-up"
//               data-aos-delay="100"
//               data-aos-duration="500"
//               data-aos-once="true"
//             >
//               <div className="single-feedback-box">
//                 <div className="feedback-desc">
//                   <p>
//                   I m client to skill technologies since 4 years. People are nice and humble in solving any issues. They solved an issue regarding google my business credentials to which i dont have acces since 3 years coz the business previous owner didn't gave me credentials or acces to run GMB. So when i approch them they undertook the process and removed acces to previous owner by consulting him. I recommend skill technologies for your business marketing. Thankyou 🤗.
//                   </p>
//                 </div>

//                 <div className="client-info">
//                   <img
//                     src='//assets/images/testimonials/thumbs/blank.png'
//                     alt="img"
//                     width={150}
//                     height={150}
//                   />
//                   <h3>RAJESH E</h3>
//                   <span>Hyderabad</span>
//                 </div>
//               </div>
//             </div>
//                 </div> */}

//                             </div>
//                         </div>
//                     </div>
//                     <button onClick={() => window.open('https://rb.gy/47rbgx')} className="btn btn-primary d-flex ms-auto">
//                         More Reviews
//                     </button>
//                 </div>
//             </section>
//         </>

//     );
// }



import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Servizee from '../../public/images/ProjectsImages/Servizee.png'
import Elite from '../../public/images/ProjectsImages/EliteMoversandPackers.png'
import QuickBucks from '../../public/images/ProjectsImages/Quickbucks.png'
import tcs from '../../public/images/ProjectsImages/tcs.png'
import Swiper from "swiper";
import '../../styles/style.css'
import oskin from '../../public/testmonials/oskin.png'
import hardcrop from '../../public/testmonials/hardcrop.png'
import blank from '../../public/testmonials/blank.png'
import blink from '../../public/testmonials/blink.png'

const testimonials = [
    {
        id: 1,
        text: "I have taken their services about 3 months ago, getting much enquiries and business due to this company's hard work. The team takes accountability of the task with full professionalism and ethics...",
        imgSrc: hardcrop,
        name: "HARDCORP GYM",
        position: "HARDCORP GYM, HYDERABAD"
    },
    {
        id: 2,
        text: "I personally recommended Skill Technologies for Social media marketing & lead generation. For my business, we got timely response and leads through Social media!",
        imgSrc: oskin,
        name: "Vineetha Reddy",
        position: "Oskin Clinic, Hyderabad"
    },
    {
        id: 3,
        text: "I'm pretty. I am very happy to share my review about Skill Technologies. It is one of the best social media agencies in Hyderabad, helped with Pinterest platform to drive traffic to our website. We received 5 organic leads.",
        imgSrc: blank,
        name: "Vaishnavi",
        position: "Hyderabad"
    },
    {
        id: 4,
        text: "When you choose Skill Technologies you get a wonderful, professional team with innovative ideas, awesome customer service, and exactly what you’re looking for.",
        imgSrc: blank,
        name: "Bhanu Teja",
        position: "Hyderabad"
    },
    // {
    //     id: 5,
    //     text: "I m client to skill technologies since 4 years.People are nice and humble in solving any issues. They solved an issue regarding google my business credentials to which i dont have acces since 3 years coz the business previous owner didn't gave me credentials or acces to run GMB. So when i approch them they undertook the process and removed acces to previous owner by consulting him. I recommend skill technologies for your business marketing. Thankyou 🤗.",
    //     imgSrc: blink,
    //     name: "Chandra sekhar",
    //     position: "Blink salon, Hyderabad"
    // }
];

export default function ApplicationDev() {
    const navigate = useNavigate();

    const [feedbackSlider, setFeedbackSlider] = useState(null);
    useEffect(() => {
        if (!feedbackSlider) {
            const brandFeedbackSlider = new Swiper('#FeedbackSlider', {
                breakpoints: {
                    0: { slidesPerView: 1 },
                    576: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    992: { slidesPerView: 2 },
                    1200: { slidesPerView: 3 },
                },
                loop: true,
            });
            setFeedbackSlider(brandFeedbackSlider);
        }
    }, [feedbackSlider]);

    useEffect(() => {
        const slideNextWithDelay = () => {
            if (feedbackSlider) {
                setTimeout(() => {
                    feedbackSlider.slideNext();
                    slideNextWithDelay();
                }, 5000);
            }
        };

        slideNextWithDelay();

        return () => clearTimeout();
    }, [feedbackSlider]);

    const slideToPrev = () => {
        if (feedbackSlider) {
            feedbackSlider.slidePrev();
        }
    };

    const slideToNext = () => {
        if (feedbackSlider) {
            feedbackSlider.slideNext();
        }
    };

    return (
        <>
            <section className="bg-top-center space" style={{ marginBottom: '150px', height: '150px', width: '100%', paddingBottom: '50px', paddingLeft: '80px', paddingRight: '50px' }}>
                <div>
                    <div className="slider-area">
                        <div className="swiper th-slider has-shadow" id="FeedbackSlider">
                            <div className="swiper-wrapper">
                                {testimonials.map(testimonial => (
                                    <div className="swiper-slide" style={{ marginLeft: '10px', zIndex: '1' }} key={testimonial.id}>
                                        <div
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                            data-aos-duration="500"
                                            data-aos-once="true"
                                        >
                                            <div className="single-feedback-box">
                                                <div className="feedback-desc">
                                                    <p>{testimonial.text}</p>
                                                </div>
                                                <div className="client-info">
                                                    <img
                                                        src={testimonial.imgSrc}
                                                        alt="img"
                                                        width={150}
                                                        height={150}
                                                    />
                                                    <h3>{testimonial.name}</h3>
                                                    <span>{testimonial.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <button onClick={() => window.open('https://rb.gy/47rbgx')} className="btn btn-primary d-flex ms-auto">
                        More Reviews
                    </button>
                </div>
            </section>
        </>
    );
}
