import React from 'react'
import Sidebar from './../AdminComponents/Sidebar'
const Admin = () => {
  return (
    <div>
      <Sidebar />
    </div>
  )
}

export default Admin