import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import './../../App.css'
import Transparency from '../../public/images/allinoneSolution.png'
import Feasibility from '../../public/images/Whatwedo/userCentric.png'
import Execution from '../../public/images/Whatwedo/team.png'
import experience from '../../public/images/Whatwedo/experience.png'
import quality from '../../public/images/quality.png'
import allinone from '../../public/images/allinone.png'
const WhatWeDo = () => {
  return (
    <>
      {/* <div className="what-we-do-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>Unraveling Our Story and Commitment</h2>
            <div className="bar"></div>
            <p className="">
              We at Skill Technologies ( A Unit of Infoswan Private Limited ), brings over 9 years of expertise in delivering innovative solutions. Our dedicated team is committed to excellence, ensuring client satisfaction at every step. With a focus on quality, reliability, and customer-centricity, we strive to exceed expectations and drive success for our partners.
            </p>
          </div>

          <div className="row justify-content-center">

            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={quality} alt='BusinessIntelligence' height={80} width={100} />
                </div>

                <h3>
                  <a to='#'>Quality </a>
                </h3>

                <p>
                  Excellence is the core of skill technologies. We strive tirelessly to deliver the best project outcomes possible.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={allinone} alt='BusinessIntelligence' height={80} width={100} />
                </div>
                <h3>
                  <a >All-in-one Solution</a>
                </h3>
                <p>
                  We're experts in corporate branding, digital marketing, product R&D, mobile app development, web design, and support.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={Transparency} alt='BusinessIntelligence' height={80} width={100} />
                </div>
                <h3>
                  <a to='#'>Transparency</a>
                </h3>
                <p>
                  Integrity forms the bedrock of all our actions. Our dedication to honesty, trustworthiness, and openness garners admiration and respect.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={Feasibility} alt='BusinessIntelligence' height={80} width={100} />
                </div>
                <h3>
                  <a to='#' >User Centric</a>
                </h3>
                <p>
                  We prioritize the end user's satisfaction, customizing our services to meet the unique needs of each business.
                </p>

              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={experience} alt='BusinessIntelligence' height={80} width={100} />
                </div>
                <h3>
                  <a to='#' >Experience</a>
                </h3>
                <p>
                  Skill Technologies, a reputable company with over 9 years of expertise, specializes in web design and development, mobile app development, insightful research, and comprehensive marketing services.
                </p>
              </div>
            </div>



            <div
              className="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-what-we-do-box">
                <div className="icon-w-d">
                  <img src={Execution} alt='BusinessIntelligence' height={80} width={100} />
                </div>
                <h3>
                  <a to='#'>Team</a>
                </h3>
                <p>
                  Our team comprises individuals with robust technical expertise, exceptional interpersonal abilities, unwavering trustworthiness, and adept problem-solving and decision-making capabilities.
                </p>

              </div>
            </div>




          </div>
        </div>
      </div> */}
    </>
  );
};

export default WhatWeDo;
