import React from "react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";

import servicesDetailsImg1 from "../../public/images/LogoSKT/Data-Engineering.png";
import PageBanner from "../Common/PageBanner";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import Contactformpopup from "../Contact/Contactformpopup";
const ServiceDetailsContent = () => {
  return (
    <>
    <NavbarStyleFour />
      <PageBanner pageTitle='DATA ENGINEERING' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>Data Engineering</h3>
                <p>
                  At Skill Technologies, we offer a comprehensive suite of engineering services to help businesses of all sizes to design, develop, and deploy data-driven solutions. Our team of experienced engineers has a deep understanding of the latest data technologies and trends, and we are committed to providing our clients with the highest quality services possible. <br />Our engineering services include:
                </p>
                {/* <h3>Data architecture and design</h3>
                <p>
                  We help businesses to design and implement data architectures that are scalable, secure, and efficient.
                </p>
                <h3>Data engineering</h3>
                <p>
                  We develop and maintain data pipelines and data warehouses to help businesses to collect, clean, and transform their data.
                </p>
                <h3>Data science and analytics</h3>
                <p>
                  We help businesses to extract insights from their data using a variety of data science and analytics techniques.
                </p>
                <h3>Machine learning and artificial intelligence</h3>
                <p>
                  We develop and deploy machine learning and artificial intelligence models to help businesses to automate tasks, make better decisions, and improve their bottom line.     </p> */}
                <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Data architecture and design</Accordion.Header>
                  <Accordion.Body>
                    <p>
                    We help businesses to design and implement data architectures that are scalable, secure, and efficient.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Data engineering</Accordion.Header>
                  <Accordion.Body>
                    <p>We develop and maintain data pipelines and data warehouses to help businesses to collect, clean, and transform their data.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Data science and analytics</Accordion.Header>
                  <Accordion.Body>
                    <p>We help businesses to extract insights from their data using a variety of data science and analytics techniques.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Machine learning and artificial intelligence</Accordion.Header>
                  <Accordion.Body>
                    <p> We develop and deploy machine learning and artificial intelligence models to help businesses to automate tasks, make better decisions, and improve their bottom line.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
            <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
             <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>
  );
};

export default ServiceDetailsContent;
