import React, { useState } from 'react';

const Applicants = () => {
    const [applicanttableData, setApplicantTableData] = useState([
        {
            id: 1,
            dateOfJoin: '2024-05-14',
            title: 'Executive',
            applicantName: 'John Doe',
            gender: 'Male',
            age: 30,
            experience: '5 years',
            contact: '123-456-7890',
            email: 'johndoe@example.com',
            cvLink: 'https://example.com/johndoe_cv'
        },
        {
            id: 2,
            dateOfJoin: '2024-05-15',
            title: 'Software Engineer',
            applicantName: 'Jane Smith',
            gender: 'Female',
            age: 28,
            experience: '3 years',
            contact: '987-654-3210',
            email: 'janesmith@example.com',
            cvLink: 'https://example.com/janesmith_cv'
        },
        {
            id: 3,
            dateOfJoin: '2024-05-16',
            title: 'Data Analyst',
            applicantName: 'Michael Johnson',
            gender: 'Male',
            age: 35,
            experience: '7 years',
            contact: '555-555-5555',
            email: 'michaeljohnson@example.com',
            cvLink: 'https://example.com/michaeljohnson_cv'
        },
        {
            id: 4,
            dateOfJoin: '2024-05-17',
            title: 'Project Manager',
            applicantName: 'Emily Brown',
            gender: 'Female',
            age: 32,
            experience: '6 years',
            contact: '111-222-3333',
            email: 'emilybrown@example.com',
            cvLink: 'https://example.com/emilybrown_cv'
        },
        {
            id: 5,
            dateOfJoin: '2024-05-18',
            title: 'Graphic Designer',
            applicantName: 'David Wilson',
            gender: 'Male',
            age: 25,
            experience: '2 years',
            contact: '444-444-4444',
            email: 'davidwilson@example.com',
            cvLink: 'https://example.com/davidwilson_cv'
        },
        {
            id: 6,
            dateOfJoin: '2024-05-19',
            title: 'HR Specialist',
            applicantName: 'Sarah Martinez',
            gender: 'Female',
            age: 29,
            experience: '4 years',
            contact: '777-777-7777',
            email: 'sarahmartinez@example.com',
            cvLink: 'https://example.com/sarahmartinez_cv'
        },
        {
            id: 7,
            dateOfJoin: '2024-05-20',
            title: 'Marketing Coordinator',
            applicantName: 'Daniel Jones',
            gender: 'Male',
            age: 27,
            experience: '3 years',
            contact: '999-999-9999',
            email: 'danieljones@example.com',
            cvLink: 'https://example.com/danieljones_cv'
        },
        {
            id: 8,
            dateOfJoin: '2024-05-21',
            title: 'Financial Analyst',
            applicantName: 'Olivia Anderson',
            gender: 'Female',
            age: 31,
            experience: '5 years',
            contact: '888-888-8888',
            email: 'oliviaanderson@example.com',
            cvLink: 'https://example.com/oliviaanderson_cv'
        },
        {
            id: 9,
            dateOfJoin: '2024-05-22',
            title: 'Sales Representative',
            applicantName: 'William Taylor',
            gender: 'Male',
            age: 33,
            experience: '8 years',
            contact: '666-666-6666',
            email: 'williamtaylor@example.com',
            cvLink: 'https://example.com/williamtaylor_cv'
        },
        {
            id: 10,
            dateOfJoin: '2024-05-23',
            title: 'Content Writer',
            applicantName: 'Emma Walker',
            gender: 'Female',
            age: 26,
            experience: '2 years',
            contact: '333-333-3333',
            email: 'emmawalker@example.com',
            cvLink: 'https://example.com/emmawalker_cv'
        },
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = applicanttableData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="section-title mt-5">
                <h3 id="about" className='fw-bold'>View Applicants</h3>
                <div className="bar"></div>
            </div>
            <table className='myjobtable'>
                <thead>
                    <tr>
                        <th>Date Of Join</th>
                        <th>Title</th>
                        <th>Applicant Name</th>
                        <th>Gender</th>
                        <th>Age</th>
                        <th>Experience</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>View CV</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(item => (
                        <tr key={item.id}>
                            <td>{item.dateOfJoin}</td>
                            <td>{item.title}</td>
                            <td>{item.applicantName}</td>
                            <td>{item.gender}</td>
                            <td>{item.age}</td>
                            <td>{item.experience}</td>
                            <td>{item.contact}</td>
                            <td>{item.email}</td>
                            <td>
                                <a href={item.cvLink} target="_blank" rel="noopener noreferrer">View CV</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={applicanttableData.length}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
    );
};

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='d-flex justify-content-center'>
            <ul className='pagination '>
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className='page-link '>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Applicants;
