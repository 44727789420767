import React, { useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import PageBanner from "./../Common/PageBanner";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";

import blogImg1 from "./../../public/images/blog-image/blog1.png";
import blogImg2 from "./../../public/images/blog-image/blog2.png";
import blogImg3 from "./../../public/images/blog-image/blog3.jpg";
import blogImg4 from "./../../public/images/blog-image/blog4.jpg";
import blogImg5 from "./../../public/images/blog-image/blog5.png";
import blogImg6 from "./../../public/images/blog-image/blog6.jpg";
import blogImg7 from  "./../../public/images/blog-image/blog15.jpeg";
import blogImg8 from  "./../../public/images/blog-image/blog16.jpeg";
import blogImg9 from  "./../../public/images/blog-image/blog17.jpeg";


const BlogsData = {
  "blogs": [
    {
      "id": 1,
      "title": "Importance Of Digital Marketing For Small And Large Businesses",
      "author": "Ankit",
      "date": "April 2024",
      "image": blogImg6,
      "content": "Social media marketing, and content marketing, businesses can effectively connect with their target demographic, regardless of geographical constraints..."
    },
    {
      "id": 2,
      "title": "Unlocking Business Potential with SEM Services",
      "author": "Vikas",
      "date": "March 2024",
      "image": blogImg2,
      "content": "Search Engine Marketing (SEM) offers immediate exposure on search results pages, targeting specific audiences and providing measurable ROI..."
    },
    {
      "id": 3,
      "title": "IOT(Internet of Things)",
      "author": "Satish T",
      "date": "March 2024",
      "image": blogImg7,
      "content": "In today's digital age, the *Internet of Things (IoT)* stands at the forefront of innovation, revolutionizing the way we interact..."
    },
    {
      "id": 4,
      "title": "AI and Machine Learning in Digital marketing",
      "author": "Saikiran",
      "date": "Feb 2024",
      "image": blogImg1,
      "content": "One of the main reasons that websites have become so important to businesses of all sizes is due to the ease of finding them online..."
    },
    {
      "id": 5,
      "title": "Why Website Is Important For Business",
      "author": "Sharon Raj",
      "date": "April 2023",
      "image": blogImg4,
      "content": "Micromanagement is evident when a leader excessively controls tasks, provides minimal autonomy, overlooks team input, demands..."
    },
    {
      "id": 6,
      "title": "Digital Marketing",
      "author": "Sharon Raj",
      "date": "August 2021",
      "image": blogImg8,
      "content": "In the digital era, the landscape of marketing has evolved significantly, with digital marketing emerging as a powerhouse strategy..."
    },
    {
      "id": 7,
      "title": "Data Science",
      "author": "Sharon Raj",
      "date": "July 2021",
      "image": blogImg9,
      "content": "Data science is the interdisciplinary field that involves extracting insights and knowledge from structured and unstructured data. Its..."
    },
    {
      "id": 9,
      "title": "Machine Learning",
      "author": "Srihari",
      "date": "July, 2021",
      "image": blogImg5,
      "content": "One of the main reasons that websites have become so important to businesses of all sizes is due to the ease of finding them online..."
    },
    {
      "id": 8,
      "title": "Artificial Intelligence",
      "author": "Sharon Raj",
      "date": "June 2021",
      "image": blogImg3,
      "content": "Technological implementation in all phases has resulted in new inventions, developments, and research outcomes for the entire world..."
    },
  ]
}

const BlogGridPost = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;

  // Calculate indexes for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = BlogsData.blogs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle="Unleash Your Curiosity" />
      <div className="blog-area ptb-80">
        <div className="container">
          <div className="row justify-content-center">
            {currentPosts.map((post) => (
              <div className="col-lg-4 col-md-6" key={post.id}>
                <div className="single-blog-post">
                  <div className="blog-image">
                    <Link to={`/blog/${post.id}`}>
                      <img
                        className="blog-img"
                        src={post.image}
                        alt={post.title}
                        width={860}
                        height={700}
                      />
                    </Link>

                    <div className="date">
                      <Icon.Calendar /> {post.date}
                    </div>
                  </div>

                  <div className="blog-post-content">
                    <h3>
                      <Link to={`/blog/${post.id}`}>{post.title}</Link>
                    </h3>

                    <span>
                      By <Link to={`/author/${post.author}`}>{post.author}</Link>
                    </span>

                    <p>{post.content}</p>

                    <Link to={`/blog/${post.id}`} className="read-more-btn">
                      Read More <Icon.ArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-lg-4 col-md-6">
              <div className="pagination-area">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                      <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>
                        Prev
                      </a>
                    </li>

                    {[...Array(Math.ceil(BlogsData.blogs.length / postsPerPage)).keys()].map((number) => (
                      <li className={`page-item ${currentPage === number + 1 && 'active'}`} key={number}>
                        <a className="page-link" href="#" onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </a>
                      </li>
                    ))}

                    <li className={`page-item ${currentPage === Math.ceil(BlogsData.blogs.length / postsPerPage) && 'disabled'}`}>
                      <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogGridPost;
