import React, { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { FiTwitter, FiFacebook } from "react-icons/fi";
import logo from '../../public/images/LogoSKT/SKT-LOGO-DARK.png';
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { useNavigate } from "react-router-dom";
import Contactformpopup from "../Contact/Contactformpopup";
const LoginForm = () => {
  const [viewPwd,setViewPwd]=useState('password')
  const loginJson = [
    {
      name: 'skill@gmail.com',
      password: 'skill@123'
    }
  ];
  
  const navigate = useNavigate();
  
  const handleLogin = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email === loginJson[0].name && password === loginJson[0].password) {
      navigate('/admin');
    } else {
      alert('Incorrect username or password');
    }
  };
  return (
    <>
      <NavbarStyleFour />
      <div className="" style={{marginTop:"100px"}}>
        <div className="container">
          <div className="auth-form">
            <div className="auth-head">
              <a href="/">
                <img src={logo} width={110} height={36} alt="Logo" />
              </a>
              <p>
                Don't have an account yet? <a href="/sign-up">Sign Up</a>
              </p>
            </div>

            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  name="email"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type={viewPwd}
                  className="form-control"
                  id="exampleInputPassword1"
                  name="password"
                />
              </div>
              <div className="mb-3">
                <p>
                  <a href="/forgot-password">Forgot Password</a>
                </p>
              </div>
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </form>
            <div className="foot">
              <p>or connect with</p>
              <ul>
                <li>
                  <a href="https://www.mail.com/" target="_blank" rel="noopener noreferrer">
                    <MdOutlineMail />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <FiFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
                    <FiTwitter />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Contactformpopup />
      </div>
      <Footer />
      
    </>
  );
};

export default LoginForm;


// import React from "react";
// import { MdOutlineMail } from "react-icons/md";
// import { FiTwitter, FiFacebook } from "react-icons/fi";
// import logo from '../../public/images/LogoSKT/SKT-LOGO-DARK.png';
// import NavbarStyleFour from "../_App/NavbarStyleFour";
// import Footer from "../_App/Footer";
// import { useNavigate } from "react-router-dom";
// const LoginForm = () => {
//   const loginJson = [
//     {
//       name: 'skill@gmail.com',
//       passowrd: 'skill@123'
//     }
//   ]
//   const navigate = useNavigate()
//   const handleLogin = (e) => {
//     e.preventDefault()
//     if (loginJson.name === 'skill@gmail.com' && loginJson.passowrd === 'skill@123') {
//       navigate('/admin')
//     }else{
//       alert('incorrect password')
//     }
//   }
//   return (
//     <>
//       <NavbarStyleFour />
//       <div className="ptb-80">
//         <div className="container">
//           <div className="auth-form">
//             <div className="auth-head">
//               <a href="/">
//                 <img src={logo} width={110} height={36} />
//               </a>
//               <p>
//                 Don't have an account yet? <a href="/sign-up">Sign Up</a>
//               </p>
//             </div>

//             <form onSubmit={handleLogin}>
//               <div className="mb-3">
//                 <label className="form-label">Email</label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   id="exampleInputEmail1"
//                 />
//               </div>
//               <div className="mb-3">
//                 <label className="form-label">Password</label>
//                 <input
//                   type="password"
//                   className="form-control"
//                   id="exampleInputPassword1"
//                 />
//               </div>
//               <div className="mb-3">
//                 <p>
//                   <a href="/forgot-password">Forgot Password</a>
//                 </p>
//               </div>
//               <button type="submit" className="btn btn-primary">
//                 Login
//               </button>
//               <button type="submit" className="btn btn-primary" onClick={() => navigate('/admin')}>
//                 Admin Login Test
//               </button>
//             </form>
//             <div className="foot">
//               <p>or connect with</p>
//               <ul>
//                 <li>
//                   <a href="https://www.mail.com/" target="_blank">
//                     <MdOutlineMail />
//                   </a>
//                 </li>
//                 <li>
//                   <a href="https://www.facebook.com/" target="_blank">
//                     <FiFacebook />
//                   </a>
//                 </li>
//                 <li>
//                   <a href="https://www.twitter.com/" target="_blank">
//                     <FiTwitter />
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default LoginForm;
