import React, { useState, useEffect } from "react";
import Servizee from '../../public/images/ProjectsImages/Servizee.png'
import Elite from '../../public/images/ProjectsImages/EliteMoversandPackers.png'
import QuickBucks from '../../public/images/ProjectsImages/Quickbucks.png'
import tcs from '../../public/images/ProjectsImages/tcs.png'
import Swiper from "swiper";
import '../../styles/style.css'

export default function ApplicationDev() {
  const [applicationslider, setapplicationslider] = useState(null);
  useEffect(() => {
    if (!applicationslider) {
      const brandapplicationslider = new Swiper('#ApplicationDev', {
        breakpoints: {
          0: { slidesPerView: 1 },
          576: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          992: { slidesPerView: 2 },
          1200: { slidesPerView: 3 },
        },
        loop: true,
      });
      setapplicationslider(brandapplicationslider);
    }
  }, [applicationslider]);

  useEffect(() => {
    const slideNextWithDelay = () => {
      if (applicationslider) {
        setTimeout(() => {
          applicationslider.slideNext();
          slideNextWithDelay();
        }, 2000);
      }
    };

    slideNextWithDelay();

    return () => clearTimeout();
  }, [applicationslider]);

  return (
    <>
      <section class="bg-top-center space " style={{ heigh: '150px', width: '100%', paddingBottom: '50px', paddingLeft: '80px', paddingRight: '50px' }}>
        <div>
          <div class="title-area text-center">
          <h3 class="sub-title">Application Development</h3>
          </div>
          <div class="slider-area">
            <div class="swiper th-slider has-shadow" id="ApplicationDev" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'>
              <div class="swiper-wrapper">

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1' }}>
                <div className="single-ml-projects-box">
                    <img src={Servizee} alt="image" width={850} height={850} />
                    <div className="plus-icon">
                      <a href="http://trivedhi.in/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={Elite} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://elitemoverelocations.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={QuickBucks} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://quickbucks.in/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={tcs} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://trivedhi.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
}


