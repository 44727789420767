import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour'
import Footer from '../../_App/Footer'

const SEO = () => {
    return (
        <div style={{marginTop:'100px'}}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>We use the best organic approaches to achieve the highest page rankings in the shortest amount of time. Our strategic methodology enables us to rank your company quickly. In 45 days, we met our clients' expectations.</p>
                        <p>Local SEO is an SEO strategy that helps your business appear more prominently in Google's local search results. Local SEO can be used by any company that has a physical location or serves a geographical area.</p>
                        <p>We use local SEO to reach out to different areas of customers in order to reach a wide range of clients. The services described as</p>
                        <ul>
                            <li>Initial Review & Analysis</li>
                            <li>On Page Optimization</li>
                            <li>Local Search Optimization</li>
                            <li>Content Marketing</li>
                            <li>Off Page optimization</li>
                            <li>Canonicalization</li>
                        </ul>
                        <p>
                            <span className="fw-bold">On Page Optimization :</span>
                            <p>On-page SEO (search engine optimization) is the process of optimizing individual web pages in order to improve search engine rankings and generate more relevant traffic.</p> 
                        </p>
                        <p>
                            <span className="fw-bold">Canonicalization  :</span>
                            <p>Canonicalization is useful for SEO because it helps Google understand duplicate content and reduces the risk of selecting the incorrect URL as the canonical version. If you use canonical tags, Google can accurately consolidate link equity, index, and rank the main version of your content for relevant queries. It also reduces crawl budget, allowing crawlers to spend more time exploring other important areas of your website.</p> 
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SEO