import React from 'react'
import Footer from '../../_App/Footer'
import NavbarStyleFour from '../../_App/NavbarStyleFour'

const YoutubeWt = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>
                        YouTube marketing is the method of promoting businesses and products on YouTube's platform through the uploading of useful videos to a company's YouTube channel or through the use of YouTube advertising.
                        </p>
                        <p>YouTube is the best place to start for video marketing. By utilizing multiple channels, you can improve your SEO, increase traffic and brand exposure, broaden your social reach, market to international audiences, increase your ROI, and diversify your video marketing strategy.</p>
                        <p>Not only is your audience on YouTube, but as the internet's second largest search engine, YouTube may also help you improve your SEO and overall brand presence.</p>
                        <p className='mb-5'>Marketers can use YouTube to create original content that is easy to watch and share. YouTube marketing can be a daunting tool for brands.</p>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default YoutubeWt;