
import React, { useState, useEffect } from 'react'

import './ApplicationForm.css'



const Page4 = () => {

  const [FormDataPage4, setFormDataPage4] = useState({
    compensation:
    {
      basic: "",
      hra: "",
      DearnessAllowance: "",
      ConveyanceAllowance: "",
      ProfessionalJournals: "",
      EducationAllowance: "",
      OtherAllowance: "",
      total: ""
    },
    Previous: {
      basic: "",
      hra: "",
      DearnessAllowance: "",
      ConveyanceAllowance: "",
      ProfessionalJournals: "",
      EducationAllowance: "",
      OtherAllowance: "",
      total: ""
    },
    appliedPreviously: null,
    testLocation: null,
    selectionOfInterview: null,
    madeAnOffer: null,
    releventDate: {
      testLocationDate: null,
      selectionOfInterviewDate: null,
      madeAnOfferDate: null,
    },
    directorAnyCompany: null,
    frimPatner: null,
    patnerDetails: '',
    legalObjection: null,
    legalObjectionDetails: "",
    criminaloffence: null,
    criminaloffenceDetails: "",
    Signplace: '',
    Signdate: '',
    signImage: '',
    forOfficeUseOnly: {
      Dateoftest: "",
      locationPreferrence: [
        {
          location1: "",
        },
        {
          location2: "",
        },
        {
          location3: "",
        },
        {
          location4: "",
        },
      ],
      DateOfInterview: '',
      score: '',
      testRefNo: '',
      panelEmpNo: '',
      role: '',
      personalBand: '',
      jobBand: '',
      ProposedBasic: '',
      joiningDate: '',
      TrainingRequirement: null,
      DurationOfTraining: "",
      ReasonforSelectionOrejection: "",

    }
  });


  const handleSave = e => {
    e.preventDefault();
    localStorage.setItem('FormDataPage4', JSON.stringify(FormDataPage4));
  };

  useEffect(() => {
    const savedFormDataPage4 = JSON.parse(localStorage.getItem('FormDataPage4'));
    if (savedFormDataPage4) {
      setFormDataPage4(savedFormDataPage4);
    }
  }, []);



  const handleSignatureImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size / 1024 <= 20) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          setFormDataPage4((prevFormData) => ({
            ...prevFormData,
            signImage: base64Image,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        alert('Image size should be less than 50KB.');
        e.target.value = '';
      }
    }
  };


  const handleSalaryInputChange = (e, category, field) => {
    const value = e.target.value;
    setFormDataPage4(prevState => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [field]: value
      }
    }));
  };

  const handlePreviousEmp = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, appliedPreviously: value }));
  };

  const handletestLocation = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, testLocation: value }));
  };

  const handleselectionOfInterview = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, selectionOfInterview: value }));
  };

  const handlemadeAnOffer = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, madeAnOffer: value }));
  };

  const handledirectorAnyCompany = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, directorAnyCompany: value }));
  };

  const handlefrimPatner = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, frimPatner: value }));
  };

  const handlelegalObjection = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, legalObjection: value }));
  };

  const handlecriminaloffence = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, criminaloffence: value }));
  };

  const handleTrainingRequirmnent = (value) => {
    setFormDataPage4(prevData => ({ ...prevData, TrainingRequirement: value }));
  };

  const handleDateChange = (e, fieldName) => {
    const { value } = e.target;
    setFormDataPage4(prevState => ({
      ...prevState,
      releventDate: {
        ...prevState.releventDate,
        [fieldName]: value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataPage4(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleOfficeInputChange = (e, category, field) => {
    const { value } = e.target;
    setFormDataPage4(prevState => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [field]: value,
      },
    }));
  };


  const handleLocation = (e, index) => {
    const { name, value } = e.target;
    const updatedLocations = [...FormDataPage4.forOfficeUseOnly.locationPreferrence];
    updatedLocations[index][name] = value;
    setFormDataPage4(prevState => ({
      ...prevState,
      forOfficeUseOnly: {
        ...prevState.forOfficeUseOnly,
        locationPreferrence: updatedLocations
      }
    }));
  };


  return (
   <div className="container">
     <form className='border m-3 p-3 res-remove-margin' onSubmit={handleSave}>
      <div className='form-container border p-3 m-3 res-remove-margin'>
        <div className="form-left-datails pass-span-container col-sm-d-none mr-2 ">
          <span>C</span>
          <span>O</span>
          <span>M</span>
          <span>P</span>
          <span>E</span>
          <span>N</span>
          <span>S</span>
          <span>A</span>
          <span>T</span>
          <span>I</span>
          <span>O</span>
          <span>N</span>
        </div>
        <div className='border m-1 row w-100 res-remove-margin'>
          <div className='border res-border-none col-md-6'>
            <h3 className='text-center app-language-heading p-2'>COMPENSATION</h3>
            <div className='border res-border-none p-2'>
              <div className='d-flex flex-wrap w-100  mb-4'>
                <h3 className='app-main-heading mt-1'>Basic</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.basic}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'basic')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading mt-1'>HRA</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.hra}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'hra')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Dearness Allowance</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.DearnessAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'DearnessAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Conveyance <br /> Allowance / Reimbursement</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.ConveyanceAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'ConveyanceAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Professional Journals</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.ProfessionalJournals}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'ProfessionalJournals')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Education Allowance</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.EducationAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'EducationAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Other Monthly Allowance</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.compensation.OtherAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'compensation', 'OtherAllowance')}
                />
              </div>

            </div>
            <div className='border res-border-none d-flex flex-wrap w-100 p-2'>
              <h3 className='app-main-heading'>TOTAL</h3>
              <input
                type='text'
                className='form-control'
                value={FormDataPage4.compensation.total}
                onChange={(e) => handleSalaryInputChange(e, 'compensation', 'total')}
              />
            </div>
          </div>
          <div className='border col-md-6'>
            <h3 className='text-center app-language-heading p-2'>PREVIOUS</h3>
            <div className='border res-border-none p-2'>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading mt-1'>Basic</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.Previous.basic}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'basic')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading mt-1'>HRA</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.Previous.hra}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'hra')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Dearness Allowance</h3>
                <input
                  type='text'
                  className='form-control'
                  value={FormDataPage4.Previous.DearnessAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'DearnessAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Conveyance <br /> Allowance / Reimbursement</h3>
                <input
                  type='text'
                  className='form-control ml-3'
                  value={FormDataPage4.Previous.ConveyanceAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'ConveyanceAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Professional Journals</h3>
                <input
                  type='text'
                  className='form-control ml-3'
                  value={FormDataPage4.Previous.ProfessionalJournals}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'ProfessionalJournals')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Education Allowance</h3>
                <input
                  type='text'
                  className='form-control ml-3'
                  value={FormDataPage4.Previous.EducationAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'EducationAllowance')}
                />
              </div>
              <div className='d-flex flex-wrap w-100 mb-4'>
                <h3 className='app-main-heading'>Other Monthly Allowance</h3>
                <input
                  type='text'
                  className='form-control ml-3'
                  value={FormDataPage4.Previous.OtherAllowance}
                  onChange={(e) => handleSalaryInputChange(e, 'Previous', 'OtherAllowance')}
                />
              </div>

            </div>
            <div className='border res-border-none d-flex flex-wrap w-100 p-2'>
              <h3 className='app-main-heading'>TOTAL</h3>
              <input
                type='text'
                className='form-control ml-3'
                value={FormDataPage4.Previous.total}
                onChange={(e) => handleSalaryInputChange(e, 'Previous', 'total')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* MISCELLANEOUS */}
      <h3 className='text-heading mb-2 mt-3'>MISCELLANEOUS </h3>
      <div className='form-container res-border-none res-remove-margin mr-3  '>
        <div className="form-left-datails pass-span-container col-sm-d-none mr-1 ">
          <span>M</span>
          <span>I</span>
          <span>S</span>
          <span>C</span>
          <span>E</span>
          <span>L</span>
          <span>L</span>
          <span>A</span>
          <span>N</span>
          <span>E</span>
          <span>O</span>
          <span>U</span>
          <span>S</span>
        </div>

        <div className=' w-100 res-remove-margin'>
          <div className='border'>
            <div className="row p-2 m-3">
              <div className="col-12 col-md-7">
                <div className='d-flex justify-content-between'>
                  <h3 className='app-main-heading' >HAVE YOU APPLIED TO INFOSWAN PREVIOUSLY?</h3>
                  <div className='res-display-felx-row'>
                    <label className='res-m-0 res-ml-2'>NO
                      <input
                        type="checkbox"
                        className=''
                        checked={FormDataPage4.appliedPreviously === false}
                        onChange={() => handlePreviousEmp(false)}
                      />
                    </label>
                    <label className='ml-5 res-m-0 res-ml-2 margin-input-lest' >YES
                      <input
                        type="checkbox"
                        className='ml-2 '
                        checked={FormDataPage4.appliedPreviously === true}
                        onChange={() => handlePreviousEmp(true)}
                      />
                    </label>
                  </div>
                </div>
                <h3 className='app-main-heading'>IF YES,</h3>

                <div className="d-flex justify-content-between">
                  <h3 className='res-m-0 app-main-heading ' >WERE YOU - CALLED FOR A TEST?</h3>
                  <div className='res-display-felx-row'>
                    <label className='ml-5 res-m-0 res-ml-2' >NO
                      <input
                        type="checkbox"
                        className='res-ml-2 ml-3'
                        checked={FormDataPage4.testLocation === false}
                        onChange={() => handletestLocation(false)}
                        readOnly={FormDataPage4.appliedPreviously === false}
                      />
                    </label>
                    <label className='ml-5 res-m-0 res-ml-2 ' >YES
                      <input
                        type="checkbox"
                        className='res-ml-2 ml-2'
                        checked={FormDataPage4.testLocation === true}
                        onChange={() => handletestLocation(true)}
                        readOnly={FormDataPage4.appliedPreviously === false}
                      />
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <h3 className='res-m-0 app-main-heading' >SELECTED FOR INTERVIEW?</h3>
                  <div className='res-display-felx-row'>
                    <label className='ml-5 res-m-0 res-ml-2 ' >NO
                      <input
                        type="checkbox"
                        className='res-ml-2 ml-2 '
                        checked={FormDataPage4.selectionOfInterview === false}
                        onChange={() => handleselectionOfInterview(false)}
                        readOnly={FormDataPage4.appliedPreviously === false}
                      />
                    </label>
                    <label className='ml-5 res-m-0 res-ml-2 ' >YES
                      <input
                        type="checkbox"
                        className='res-ml-2 ml-2'
                        checked={FormDataPage4.selectionOfInterview === true}
                        onChange={() => handleselectionOfInterview(true)}
                        readOnly={FormDataPage4.appliedPreviously === false}
                      />
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-3">
                <h3 className='res-m-0 app-main-heading' >MADE AN OFFER?</h3>
                <div className='res-display-felx-row'>
                  <label className='res-m-0 res-ml-2'  >NO
                    <input
                      type="checkbox"
                      className='res-ml-2 ml-2'
                      checked={FormDataPage4.madeAnOffer === false}
                      onChange={() => handlemadeAnOffer(false)}
                      readOnly={FormDataPage4.appliedPreviously === false}
                    />
                  </label>
                  <label className=' res-m-0 res-ml-2'  >YES
                    <input
                      type="checkbox"
                      className='res-ml-2 ml-2'
                      checked={FormDataPage4.madeAnOffer === true}
                      onChange={() => handlemadeAnOffer(true)}
                      readOnly={FormDataPage4.appliedPreviously === false}
                    />
                  </label>
                </div>
                </div>
              </div>

              <div className="col-12 col-md-5">
                <div className='mt-2 res-m-0'>
                  <h3>MENTION RELEVANT DATES</h3>
                  <input
                    type='date'
                    className='form-control mt-2'
                    readOnly={FormDataPage4.appliedPreviously === false}
                    value={FormDataPage4.releventDate.testLocationDate || ''}
                    onChange={(e) => handleDateChange(e, 'testLocationDate')}
                  />
                  <input
                    type='date'
                    className='form-control mt-2'
                    readOnly={FormDataPage4.appliedPreviously === false}
                    value={FormDataPage4.releventDate.selectionOfInterviewDate || ''}
                    onChange={(e) => handleDateChange(e, 'selectionOfInterviewDate')}
                  />
                  <input
                    type='date'
                    className='form-control mt-2 mb-3'
                    readOnly={FormDataPage4.appliedPreviously === false}
                    value={FormDataPage4.releventDate.madeAnOfferDate || ''}
                    onChange={(e) => handleDateChange(e, 'madeAnOfferDate')}
                  />
                </div>

              </div>
            </div>
          </div>

          {/* <div className='d-flex flex-wrap  p-2'>
            <div className='res-j-c-start'>
              <div className='d-flex flex-wrap justify-content-around res-ml mb-2 '>
                <h3 className='app-main-heading' >HAVE YOU APPLIED TO INFOSWAN PREVIOUSLY?</h3>
                <label className='res-m-0 res-ml-2'>NO
                  <input
                    type="checkbox"
                    className=''
                    checked={FormDataPage4.appliedPreviously === false}
                    onChange={() => handlePreviousEmp(false)}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2 margin-input-lest' >YES
                  <input
                    type="checkbox"
                    className='ml-2 '
                    checked={FormDataPage4.appliedPreviously === true}
                    onChange={() => handlePreviousEmp(true)}
                  />
                </label>
              </div>
              <h3 className='app-main-heading'>IF YES,</h3>
              <div className='d-flex flex-wrap justify-content-around mt-3 mb-2 '>
                <h3 className='res-m-0 app-main-heading ' >WERE YOU - CALLED FOR A TEST?</h3>
                <label className='ml-5 res-m-0 res-ml-2' >NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.testLocation === false}
                    onChange={() => handletestLocation(false)}
                    readOnly={FormDataPage4.appliedPreviously === false}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2 ' >YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.testLocation === true}
                    onChange={() => handletestLocation(true)}
                    readOnly={FormDataPage4.appliedPreviously === false}
                  />
                </label>
              </div>
              <div className='d-flex flex-wrap justify-content-around mb-2'>
                <h3 className='res-m-0 app-main-heading' >MADE AN OFFER?</h3>
                <div className='res-display-felx-row'>
                  <label className='res-m-0 res-ml-2'  >NO
                    <input
                      type="checkbox"
                      className='res-ml-2 ml-2'
                      checked={FormDataPage4.madeAnOffer === false}
                      onChange={() => handlemadeAnOffer(false)}
                      readOnly={FormDataPage4.appliedPreviously === false}
                    />
                  </label>
                  <label className=' res-m-0 res-ml-2'  >YES
                    <input
                      type="checkbox"
                      className='res-ml-2 ml-2'
                      checked={FormDataPage4.madeAnOffer === true}
                      onChange={() => handlemadeAnOffer(true)}
                      readOnly={FormDataPage4.appliedPreviously === false}
                    />
                  </label>
                </div>
              </div>


            </div>



          </div> */}

          <div className='border p-3 '>
            <h3 className='app-main-heading'>ARE YOU EMPLOYED AS:</h3>
            <div className='d-flex mt-3 res-display-felx-column'>
              <h3 className='res-m-0 app-main-heading' style={{ marginRight: '235px' }}>A. A DIRECTOR IN ANY OTHER COMPANY?</h3>
              <div className='res-display-felx-row'>
                <label className='ml-5 res-m-0 res-ml-2' style={{ marginRight: "10px" }} >NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.directorAnyCompany === false}
                    onChange={() => handledirectorAnyCompany(false)}
                    readOnly={FormDataPage4.directorAnyCompany === false}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2' >YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.directorAnyCompany === true}
                    onChange={() => handledirectorAnyCompany(true)}
                  />
                </label>
              </div>
            </div>

            <div className='d-flex mt-3 res-display-felx-column mb-2'>
              <h3 className='res-m-0 app-main-heading' style={{ marginRight: '360px' }}>B. A PARTNER IN ANY FIRM?</h3>
              <div className='res-display-felx-row'>
                <label className='ml-5 res-m-0 res-ml-2' htmlFor="applied" style={{ marginRight: "10px" }}>NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.frimPatner === false}
                    onChange={() => handlefrimPatner(false)}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2' htmlFor="applied">YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.frimPatner === true}
                    onChange={() => handlefrimPatner(true)}
                  />
                </label>
              </div>

            </div>
            <h3 className='mb-2 app-main-heading'>IF YES, PLEASE MENTION DETAILS OF THE SAME</h3>
            <textarea
              className='form-control w-100'
              name='patnerDetails'
              value={FormDataPage4.patnerDetails}
              onChange={handleInputChange}
              readOnly={FormDataPage4.directorAnyCompany === false}
            />
          </div>


          <div className='border p-3 '>
            <div className='d-flex mt-3 res-display-felx-column'>
              <h3 className='res-m-0 app-main-heading' style={{ marginRight: '50px' }}>ARE YOU UNDER ANY LEGAL OBLIGATION TO YOUR CURRENT EMPLOYER?</h3>
              <div className='res-display-felx-row'>
                <label className='ml-5 res-m-0 res-ml-2' style={{ marginRight: "10px" }}>NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.legalObjection === false}
                    onChange={() => handlelegalObjection(false)}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2'>YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.legalObjection === true}
                    onChange={() => handlelegalObjection(true)}
                  />
                </label>
              </div>

            </div>
            <h3 className='mb-3 app-main-heading'>IF YES, PLEASE CLARIFY</h3>
            <textarea
              className='form-control w-100'
              name='legalObjectionDetails'
              value={FormDataPage4.legalObjectionDetails}
              onChange={handleInputChange}
              readOnly={FormDataPage4.legalObjection === false}
            />
          </div>

          <div className='border p-3 '>
            <div className='d-flex res-display-felx-column mt-3 '>
              <h3 className='res-m-0 app-main-heading' style={{ marginRight: '50px' }} >Have you at any time been convicted by a court of India for any criminal offence and sentenced to imprisonment, <br /> or any criminal proceedings are
                pending against you before a court in India, or an order prohibiting your <br />departure from India has been issued by a court,</h3>
              <div className='res-display-felx-row mt-3 d-flex'>
                <label className='ml-5 res-m-0 res-ml-2' htmlFor="applied" style={{ marginRight: "10px" }}>NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.criminaloffence === false}
                    onChange={() => handlecriminaloffence(false)}
                  />
                </label>
                <label className='ml-5 res-m-0 res-ml-2' htmlFor="applied">YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.criminaloffence === true}
                    onChange={() => handlecriminaloffence(true)}
                  />
                </label>
              </div>
            </div>
            <h3 className='mb-3 mt-3 app-main-heading'>IF YES, PLEASRE GIVE DETAILS OF THE SAME</h3>
            <textarea
              className='form-control w-100'
              name='criminaloffenceDetails'
              value={FormDataPage4.criminaloffenceDetails}
              onChange={handleInputChange}
              readOnly={FormDataPage4.criminaloffence === false}
            />
          </div>

          <div className='border p-3 '>
            <h3 className='res-m-0 app-main-heading' style={{ marginRight: '50px' }}>I certify that the above statements made by me are true, complete and correct. All the academic marks / percentages / CGPA are simple average for all
              subjects / semesters / years. I agree that in case the company finds at any time that the information given by me in this form is not correct, true or
              complete, the company will have the right to withdraw my letter of appointment or to terminate my appointment at any time without notice or
              compensation.</h3>

            <div className='d-flex res-display-felx-column mt-3 w-100'>
              <label>PLACE</label>
              <input
                type="text"
                name='Signplace'
                className='form-control w-25 remove-w-25'
                value={FormDataPage4.Signplace}

                onChange={handleInputChange}
              />
            </div>

            <div className='d-flex justify-content-between res-display-felx-column mt-3 w-100'>
              <div className='d-flex res-display-felx-column mb-3 mt-3 w-100 '>
                <label>DATE</label>
                <input
                  type="date"
                  name='Signdate'
                  className='form-control w-25 remove-w-25'
                  value={FormDataPage4.Signdate}

                  onChange={handleInputChange}
                />
              </div>

              <div className='text-center res-m-0 '>
                <div className='avator-uploader'>
                  <label htmlFor="imageInput">
                    <div
                      style={{
                        width: '150px',
                        height: '50px',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      {FormDataPage4.signImage ? (
                        <img
                          src={FormDataPage4.signImage}
                          alt="Selected Avatar"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ddd',
                            color: '#666',
                          }}
                        >
                          <span className='text-center p-3'>Upload Signature</span>
                        </div>
                      )}
                    </div>
                  </label>
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleSignatureImageChange}
                  />
                </div>
                <label>Signature</label>
              </div>
            </div>
          </div>
        </div>


      </div>

      <h3 className='text-heading mb-2 mt-3'>FOR OFFICE USE ONLY</h3>
      <div className='form-container mr-3'>
        <div className="form-left-datails  pass-span-container col-sm-d-none mr-2">
          <span>F</span>
          <span>O</span>
          <span>R</span>
          <span><br /></span>
          <span>O</span>
          <span>F</span>
          <span>F</span>
          <span>I</span>
          <span>C</span>
          <span>E</span>
          <span><br /></span>
          <span>U</span>
          <span>S</span>
          <span>E</span>
          <span><br /></span>
          <span>O</span>
          <span>N</span>
          <span>L</span>
          <span>Y</span>
        </div>

        <div className='border p-3 w-100'>
          <div className='row justify-content-between w-100'>
            <div className='col-md-6'>
              <div className='d-flex flex-wrap mb-3'>
                <h3 className='app-main-heading'>Date of test:</h3>
                <input
                  type="date"
                  className='form-control w-50 remove-w-25'
                  value={FormDataPage4.forOfficeUseOnly.Dateoftest}
                  onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'Dateoftest')} />
              </div>
              <div className='d-flex flex-wrap mb-3'>
                <h3 className='app-main-heading'>Date of Interview:</h3>
                <input
                  type="date"
                  className='form-control w-50 remove-w-25'
                  value={FormDataPage4.forOfficeUseOnly.DateOfInterview}
                  onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'DateOfInterview')} />
              </div>
              <div className='d-flex flex-wrap mb-3'>
                <h3 className='app-main-heading mt-3'>Score: </h3>
                <input
                  type="text"
                  className='form-control w-50 remove-w-25'
                  value={FormDataPage4.forOfficeUseOnly.score}
                  onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'score')} />
              </div>
              <div className='d-flex flex-wrap mb-3'>
                <h3 className='app-main-heading'>Written Test Ref. No:</h3>
                <input
                  type="text"
                  className='form-control w-50 remove-w-25'
                  value={FormDataPage4.forOfficeUseOnly.testRefNo}
                  onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'testRefNo')} />
              </div>
              <div className='d-flex flex-wrap mb-3'>
                <h3 className='app-main-heading'>Panel & Employee No.:</h3>
                <input
                  type="text"
                  className='form-control w-50 remove-w-25'
                  value={FormDataPage4.forOfficeUseOnly.panelEmpNo}
                  onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'panelEmpNo')} />
              </div>
            </div>
            <div className='col-md-5 col-12'>
              <h3 className='app-main-heading'>LOCATION PREFERENCE</h3>
              {FormDataPage4.forOfficeUseOnly.locationPreferrence.map((location, index) => (
                <input
                  key={index}
                  type="text"
                  name={`location${index + 1}`}
                  className='form-control'
                  value={FormDataPage4.forOfficeUseOnly.locationPreferrence[index][`location${index + 1}`]}
                  onChange={(e) => handleLocation(e, index)}
                />
              ))}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4  mb-3'>
              <h3 className='app-main-heading'>Role:</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.role}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'role')} />
            </div>
            <div className='col-md-4  mb-3'>
              <h3 className='app-main-heading'>Personal Band (if any):</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.personalBand}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'personalBand')} />
            </div>

            <div className='col-md-4  mb-3'>
              <h3 className='app-main-heading'>Job Band:</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.jobBand}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'jobBand')} />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4 mb-3'>
              <h3 className='app-main-heading'>Proposed Basic:</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.ProposedBasic}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'ProposedBasic')} />
            </div>
            <div className='col-md-4 mb-3'>
              <h3 className='app-main-heading'>Likely Joining Date:</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.joiningDate}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'joiningDate')} />
            </div>

            <div className='col-md-4 mb-3 mt-4 d-flex res-display-felx-column'>
              <h3 className='app-main-heading' style={{ marginRight: '20px' }}>Training Requirement:</h3>
              <div className='res-display-felx-row'>
                <label className='ml-5 res-m-0 res-ml-2' style={{ marginRight: '10px' }} >NO
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.TrainingRequirement === false}
                    onChange={() => handleTrainingRequirmnent(false)}
                  />
                </label>

                <label className='ml-5 res-m-0 res-ml-2'>YES
                  <input
                    type="checkbox"
                    className='res-ml-2 ml-2'
                    checked={FormDataPage4.TrainingRequirement === true}
                    onChange={() => handleTrainingRequirmnent(true)}
                  />
                </label>
              </div>
            </div>


          </div>

          <div>
            <div className='d-flex flex-wrap mb-3'>
              <h3 className='app-main-heading'>Duration Of Training:</h3>
              <input
                type="text"
                className='form-control'
                value={FormDataPage4.forOfficeUseOnly.DurationOfTraining}
                onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'DurationOfTraining')}
                readOnly={FormDataPage4.TrainingRequirement === false}
              />
            </div>
            <h3 className='app-main-heading'>Reason for selection / Rejection :</h3>
            <textarea
              className='form-control w-100'
              value={FormDataPage4.forOfficeUseOnly.ReasonforSelectionOrejection}
              onChange={(e) => handleOfficeInputChange(e, 'forOfficeUseOnly', 'ReasonforSelectionOrejection')}

            />
          </div>
        </div>

      </div>
      <button type="submit" className='d-block ms-auto btn btn-primary mt-3'>Save</button>
    </form>
   </div>
  )
}




export default Page4;
