import React, { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import contactImg from "../../public/images/contactbnr.jpg";
import baseUrl from "../../utils/baseUrl";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import PageBanner from '../Common/PageBanner'
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { FormatColorTextTwoTone } from "@mui/icons-material";
import emailjs from '@emailjs/browser'
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);



// Form initial state
const INITIAL_STATE = {
  from_name: "",
  email: "",
  contact_id: "",
  service: "",
  message: "",
};

const INITIAL_ERRORS = {
  from_name: "",
  email: "",
  contact_id: "",
  service: "",
  message: "",
};


const ContactForm = () => {
  const navigate = useNavigate()
  const [contact, setContact] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERRORS);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const form = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: value }));
    // console.log(contact)
  };


  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validate()) {
      emailjs
        .sendForm('service_buupobd', 'template_566oki3', form.current, {
          publicKey: 'oP4hzCENEVwSPS29A',
        })
        .then(
          () => {
            navigate('/contact/successGIT');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    }
  };

  const validate = () => {
    let tempErrors = { ...INITIAL_ERRORS };
    let isValid = true;

    if (!contact.from_name.trim()) {
      tempErrors.from_name = "Name is required.";
      isValid = false;
    }
    if (!contact.email.trim()) {
      tempErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(contact.email)) {
      tempErrors.email = "Email is not valid.";
      isValid = false;
    }
    if (!contact.contact_id.trim()) {
      tempErrors.contact_id = "Phone number is required.";
      isValid = false;
    } else if (!/^\d+$/.test(contact.contact_id)) {
      tempErrors.contact_id = "Phone number is not valid.";
      isValid = false;
    }
    if (!contact.service.trim()) {
      tempErrors.service = "Service selection is required.";
      isValid = false;
    }
    if (!contact.message.trim()) {
      tempErrors.message = "Message is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle="Contact Us" />

      <div className="contact-info-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="contact-info-box">
                <div className="icon">
                  <CiMail />
                </div>
                <h3>Email</h3>
                <p>
                  <a href="mailto:info@skilltechnologies.in">info@skilltechnologies.in</a>
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="contact-info-box">
                <div className="icon">
                  <CiLocationOn />
                </div>
                <h3>Location</h3>
                <p>
                #301, 3rdFloor, Plot 187, Raghavendra colony, Kondapur, Above Allen Solly Showroom, Raghavendra Colony, Kondapur, Telangana 500084.
                </p>
                <hr />
                <p>
                  722 Grant Street, Suite G Herndon, VA 20170 USA And 1020 Elden Street, Suite 102 Herndon, VA 20170 USA
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="contact-info-box">
                <div className="icon">
                  <BsTelephone />
                </div>
                <h3>Phone</h3>
                <p>
                <a href="tel:+04049523129">040 49523129</a>
                </p>
                <p>
                  <a href="tel:+919014183086">9014183086</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3805.7956962939707!2d78.35938427516678!3d17.469489983431764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI4JzEwLjIiTiA3OMKwMjEnNDMuMSJF!5e0!3m2!1sen!2sin!4v1719825779207!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>


      <div className="contact-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>Get In Touch</h2>
            <div className="bar"></div>
            <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <img
                src={contactImg}
                alt="contact"
                className="contact-img"
                width={685}
                height={694}
              />
            </div>

            <div className="col-lg-6 col-md-12 res-mt-sm">

              <form ref={form} onSubmit={sendEmail} >
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="from_name"
                        placeholder="Name"
                        className="form-control"
                        value={contact.from_name}
                        onChange={handleChange}
                        required
                      />
                      {errors.from_name && <p className="text-danger">{errors.from_name}</p>}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email address"
                        className="form-control"
                        value={contact.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && <p className="text-danger">{errors.email}</p>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="contact_id"
                        placeholder="Phone number"
                        className="form-control"
                        value={contact.contact_id}
                        onChange={handleChange}
                        required
                      />
                      {errors.contact_id && <p className="text-danger">{errors.contact_id}</p>}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <select
                        id="service"
                        name="service"
                        className="form-select w-100"
                        value={contact.service}
                        onChange={handleSelectChange}
                        required
                      >
                        <option>Basic Website</option>
                        <option>Wordpress Development</option>
                        <option>Application Development</option>
                        <option>Ecommerce Website</option>
                        <option>Social Media Marketing</option>
                        <option>SEO Services</option>
                        <option>Youtube Marketing</option>
                        <option>Other Services</option>
                      </select>
                      {errors.service && <p className="text-danger">{errors.service}</p>}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        cols="30"
                        rows="5"
                        placeholder="Write message..."
                        className="form-control"
                        value={contact.message}
                        onChange={handleChange}
                        required
                      />
                      {errors.message && <p className="text-danger">{errors.message}</p>}
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        By checking this, you agree to our{" "}
                        <a href="/terms">Terms</a> and{" "}
                        <a href="/privacypolicy">Privacy policy</a>.
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <button type="submit" className="btn btn-primary" disabled={!isCheckboxChecked}>
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default ContactForm;
