import React, { useEffect, useState, useRef } from 'react'
import NavbarStyleFour from '../_App/NavbarStyleFour'
import Footer from '../_App/Footer'
import './Tabs.css';
import seo from './../../public/images/Others/seo.png'
import seo2 from './../../public/images/Others/seo2.png'
import seo3 from './../../public/images/Others/seo3.png'
import soc3 from './../../public/images/Others/soc1.png'
import soc2 from './../../public/images/Others/soc2.png'
import a from './../../public/images/Others/soc3.png'
import yt1 from './../../public/images/Others/yt1.png'
import yt2 from './../../public/images/Others/y2.png'
import yt3 from './../../public/images/Others/yt3.png'
import google1 from './../../public/images/Others/google.png'
import google2 from './../../public/images/Others/google2.png'
import google3 from './../../public/images/Others/google3.png'
import { Link } from 'react-router-dom';
import Contactformpopup from "../Contact/Contactformpopup";
const DigitalMarketingTabs = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    const [noclients, setNoclients] = useState(500);
    const [stragigicapproch, setStragigicApproach] = useState(220);
    const [rank, setRank] = useState(500);
    const [successrate, setSuccessRate] = useState(500);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            // Increment counters gradually
            setNoclients(prev => Math.min(prev + 1, 1000));
            setStragigicApproach(prev => Math.min(prev + 1, 2512));
            setRank(prev => Math.min(prev + 1, 1784));
            setSuccessRate(prev => Math.min(prev + 1, 1000));

            // Stop the interval if all counters have reached their desired values
            if (noclients === 1000 && stragigicapproch === 2512 && rank === 1784 && successrate === 1000) {
                clearInterval(intervalRef.current);
            }
        }, 2); // Interval in milliseconds (adjust as needed)

        // Clear interval when component unmounts
        return () => clearInterval(intervalRef.current);
    }, []);
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="tabs-container">
                <div className="tabs">
                    <div>
                        <h1 className='text-white text-center pb-5 pt-5 fw-bold'>Digital Marketing</h1>
                    </div>
                    <div className='d-flex flex-wrap justify-content-between'>
                        <div
                            className={`tab btn btn-primary ${activeTab === 1 ? 'active' : ''}`}
                            onClick={() => handleTabClick(1)}
                        >
                            Search Engine Optimization
                        </div>
                        <div
                            className={`tab btn btn-primary ${activeTab === 2 ? 'active' : ''}`}
                            onClick={() => handleTabClick(2)}
                        >
                            Social Media Marketing
                        </div>
                        <div
                            className={`tab btn btn-primary ${activeTab === 3 ? 'active' : ''}`}
                            onClick={() => handleTabClick(3)}
                        >
                            Youtube Marketing
                        </div>
                        <div
                            className={`tab btn btn-primary ${activeTab === 4 ? 'active' : ''}`}
                            onClick={() => handleTabClick(4)}
                        >
                            Google Adwords
                        </div>
                        <div
                            className={`tab btn btn-primary ${activeTab === 5 ? 'active' : ''}`}
                            onClick={() => handleTabClick(5)}
                        >
                            App Store Optimization (ASO) Plans
                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    {activeTab === 1 &&
                        <>
                            <div className="d-flex flex-wrap justify-content-around">
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={seo} alt="Card image cap" />
                                    <div class="card-body">
                                        <p className="text-primary">Local SEO Services</p>
                                        <h5 class="card-title">Local SEO Services</h5>
                                        <p class="card-text">We use the best organic approaches to achieve the highest page rankings in the shortest amount of time. Our strategic methodology enables ......</p>
                                        <Link to="/seo/local" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={seo2} alt="Card image cap" />
                                    <div class="card-body">
                                        <p className="text-primary">Global SEO Services</p>
                                        <h5 class="card-title">Global SEO Services</h5>
                                        <p class="card-text">Get result-oriented global SEO services that will assist your company in expanding its international reach and increasing sales. We can handle ......</p>
                                        <Link to="/seo/global" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={a} alt="Card image cap" />
                                    <div class="card-body">
                                        <p className="text-primary">E-Commerce SEO Services</p>
                                        <h5 class="card-title">E - Commerce SEO Services</h5>
                                        <p class="card-text">Our E-Commerce SEO experts can help you enhance your website, mobile app, and product promotions for search engines. Automotive We can .....</p>
                                        <Link to="/seo/ecom" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {activeTab === 2 &&
                        <>
                            <div className="d-flex justify-content-around">
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={soc3} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">We Are Best In</h5>
                                        <p class="card-text">
                                            <ul>
                                                <li>Developing a business page</li>
                                                <li>Insight competitor analysis</li>
                                                <li>Facebook Campaign Ads....</li>
                                            </ul>
                                        </p>
                                        <Link to="/seo/bestsmm" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={soc2} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Why Social Media Marketing ?</h5>
                                        <p class="card-text">Social media has become the most influential and important virtual space where the platform is not only used for social networking but .....</p>
                                        <Link to="/seo/whysmm" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={a} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Our Services</h5>
                                        <ul>
                                            <li>Facbook</li>
                                            <li>Twitter</li>
                                            <li>Instagram....</li>
                                        </ul>
                                        <Link to="/seo/servicessm" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {activeTab === 3 &&
                        <>
                            <div className="d-flex justify-content-around">
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={yt1} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">What Is YouTube Marketing?</h5>
                                        <p class="card-text">
                                            YouTube marketing is the method of promoting businesses and products on YouTube's platform through the uploading of useful videos to a .......
                                        </p>
                                        <Link to="/Youtube/What-is-youtube-marketing" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={yt2} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Importance Of YouTube Marketing</h5>
                                        <p class="card-text">YouTube marketing is important for small businesses because your video has the potential to go "viral" or receive a large number of .....</p>
                                        <Link to="/Youtube/importance-of-youtube-marketing" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={yt3} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Advantages Of YouTube Marketing</h5>
                                        <p>If you want to know the benefits of YouTube marketing, all you have to do is look at the most recent data extracted from the most recent .....</p>
                                        <Link to="/Youtube/advantages-of-youtube-marketing" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {activeTab === 4 &&
                        <>
                            <div className="d-flex justify-content-around">
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={google1} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Do You Want To Grow Your Business With Google Adwords</h5>
                                        <p class="card-text">
                                            We use the best organic approaches to achieve the highest page rankings in the shortest amount of time. Our strategic methodology .....
                                        </p>
                                        <Link to="/googleadwords/want-to-grow" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={google2} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">How Useful Google Adwords To Grow Your Business</h5>
                                        <p class="card-text">Organizations today require a strong bench of leaders to drive business outcomes and satisfy employees, customers, and ......</p>
                                        <Link to="/googleadwords/useful-of-google-adwords" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                                <div class="card" style={{ width: '20rem' }}>
                                    <img class="card-img-top" src={google3} alt="Card image cap" />
                                    <div class="card-body">
                                        <h5 class="card-title">Why Choose Skill Technologies For Google Adwords Services</h5>
                                        <p>Online advertising enables you to target people who are likely to be interested in your products and services while excluding those who aren't.......</p>
                                        <Link to="/googleadwords/why-choose-skill-technologies" class="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {activeTab === 5 && <>
                        <table className='dm-table frontend-table'>
                            <thead>
                                <tr>
                                    <td>App Off Page/Off-Site Optimization	</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ASO Pricing Plans</td>
                                    <td>Silver</td>
                                    <td>Gold</td>
                                    <td>Platinum</td>
                                </tr>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr>
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>6 Months Advance Payment (₹)</td>
                                    <td>₹81,000</td>
                                    <td>₹108,000</td>
                                    <td>₹162,000</td>
                                    <td>₹216,000</td>
                                </tr>
                                <tr>
                                    <td>6 Months Savings (%)</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>12 Months Advance Payment (₹)</td>
                                    <td>₹144,000</td>
                                    <td>₹192,000</td>
                                    <td>₹288,000</td>
                                    <td>₹384,000</td>
                                </tr>
                                <tr>
                                    <td>12 Months Savings (%)</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                </tr>
                                <tr>
                                    <td>Timeframe</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                </tr>
                                <tr>
                                    <td>Website Initial Technical Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>On-Page Recommendation & Suggestion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Keyword Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Initial Ranking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Competitive Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Localization</td>
                                    <td>No</td>
                                    <td>1 Language</td>
                                    <td>2 Languages</td>
                                    <td>3 Languages</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>App On-Page/On-Site Optimization</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>6 Months Advance Payment (₹)</td>
                                    <td>₹81,000</td>
                                    <td>₹108,000</td>
                                    <td>₹162,000</td>
                                    <td>₹216,000</td>
                                </tr>
                                <tr>
                                    <td>6 Months Savings (%)</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                    <td>10%</td>
                                </tr>
                                <tr>
                                    <td>12 Months Advance Payment (₹)</td>
                                    <td>₹144,000</td>
                                    <td>₹192,000</td>
                                    <td>₹288,000</td>
                                    <td>₹384,000</td>
                                </tr>
                                <tr>
                                    <td>12 Months Savings (%)</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                    <td>20%</td>
                                </tr>
                                <tr>
                                    <td>Timeframe</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                    <td>Monthly</td>
                                </tr>
                                <tr>
                                    <td>Website Initial Technical Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>On-Page Recommendation & Suggestion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Keyword Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Initial Ranking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Competitive Analysis</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Localization</td>
                                    <td>No</td>
                                    <td>1 Language</td>
                                    <td>2 Languages</td>
                                    <td>3 Languages</td>
                                </tr>
                                <tr>
                                    <td>App Store Optimization</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Title Optimization</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Description Optimization</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Google Play for Android</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>iTunes for iOS</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Windows Store for Windows Phone</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>BlackBerry World for BlackBerry</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Icon</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Type & Category Selection</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Ratings & Reviews</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Downloads</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <td>App Off Page/Off-Site Optimization	</td>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>Image Promotion & Submission</td>
                                    <td>15 (5 Images*3 Sources)</td>
                                    <td>25 (5 Images*5 Sources)</td>
                                    <td>50 (10 Images*5 Sources)</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Real Review Posting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Customer Reviews/Ratings</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Local Classified Live URL Social Bookmarking</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Local Business Listing/Citations</td>
                                    <td>10</td>
                                    <td>15</td>
                                    <td>20</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Article Profile Creation</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Article Optimization & Submission</td>
                                    <td>2</td>
                                    <td>4</td>
                                    <td>6</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Share Live Article on Social Media</td>
                                    <td>20</td>
                                    <td>35</td>
                                    <td>50</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Infographics Creation & Posting</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Blog Creation & Posting</td>
                                    <td>2</td>
                                    <td>4</td>
                                    <td>6</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Blog Live URL Social Bookmarking</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Search Engine Submission*</td>
                                    <td>10</td>
                                    <td>15</td>
                                    <td>20</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Weekly Work Update</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Rank & Stats Reporting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Social Media Marketing</td>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td>Promote App Link on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Promote App Video on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Back Linking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>PR & Blogger Outreach</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Screenshot the App & Include a Demo</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Social Media Marketing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>Image Promotion & Submission</td>
                                    <td>15 (5 Images*3 Sources)</td>
                                    <td>25 (5 Images*5 Sources)</td>
                                    <td>50 (10 Images*5 Sources)</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Real Review Posting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Customer Reviews/Ratings</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Social Media Marketing</td>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td>Promote App Link on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Promote App Video on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Back Linking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>PR & Blogger Outreach</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Screenshot the App & Include a Demo</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Video Marketing	</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>Image Promotion & Submission</td>
                                    <td>15 (5 Images*3 Sources)</td>
                                    <td>25 (5 Images*5 Sources)</td>
                                    <td>50 (10 Images*5 Sources)</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Real Review Posting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Customer Reviews/Ratings</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Social Media Marketing</td>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td>YouTube Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Vimeo Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Dailymotion Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Promote App Link on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Promote App Video on Social Media</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Back Linking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>PR & Blogger Outreach</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Screenshot the App & Include a Demo</td>
                                    <td>10</td>
                                    <td>20</td>
                                    <td>30</td>
                                    <td>N/A</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>App Store Reports</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>Image Promotion & Submission</td>
                                    <td>15 (5 Images*3 Sources)</td>
                                    <td>25 (5 Images*5 Sources)</td>
                                    <td>50 (10 Images*5 Sources)</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Real Review Posting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Customer Reviews/Ratings</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Social Media Marketing</td>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td>YouTube Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Vimeo Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Dailymotion Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Installations Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Monthly Work Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Customer Support</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <th>Parameter</th>
                                    <th>Minimum Downloads</th>
                                    <th>1000 Downloads</th>
                                    <th>1500 Downloads</th>
                                    <th>2000 Downloads</th>
                                </tr> */}
                                <tr>
                                    <td>Price Per Month (Starts from ₹)</td>
                                    <td>₹15,000</td>
                                    <td>₹20,000</td>
                                    <td>₹30,000</td>
                                    <td>₹40,000</td>
                                </tr>
                                <tr>
                                    <td>Image Promotion & Submission</td>
                                    <td>15 (5 Images*3 Sources)</td>
                                    <td>25 (5 Images*5 Sources)</td>
                                    <td>50 (10 Images*5 Sources)</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Real Review Posting</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Customer Reviews/Ratings</td>
                                    <td>1</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>N/A</td>
                                </tr>
                                <tr>
                                    <td>Social Media Marketing</td>
                                    <td colspan="4"></td>
                                </tr>
                                <tr>
                                    <td>YouTube Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Vimeo Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Dailymotion Account Set Up & App Preview Video Promotion</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Google Analytics Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>App Installations Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Monthly Work Report</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Email, Phone, Chat</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>Project Management System Tracking</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </>}
                </div>
            </div> 
            <div className="container">
                <div class="container-section">
                    <div class="card-counter">
                        <div class="card-counter-title">
                            {noclients}
                        </div>
                        <div class="card-counter-text">
                            No of clients
                        </div>
                    </div>
                    <div class="card-counter">
                        <div class="card-counter-title">
                            {stragigicapproch}
                        </div>
                        <div class="card-counter-text">
                            Strategic approach
                        </div>
                    </div>
                    <div class="card-counter">
                        <div class="card-counter-title">
                            {rank}
                        </div>
                        <div class="card-counter-text">
                            Top page ranking
                        </div>
                    </div>
                    <div class="card-counter">
                        <div class="card-counter-title">
                            {successrate}
                        </div>
                        <div class="card-counter-text">
                            Success rate
                        </div>
                    </div>
                </div>
            </div>
            <Contactformpopup />
            <Footer />
        </div>
    )
}

export default DigitalMarketingTabs