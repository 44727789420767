import React from 'react'
import intro from './../../public/images/Others/tandc.png'
import termsservices from './../../public/images/Others/Terms-service.png'
import qualityeff from './../../public/images/Others/quality-eff.png'
import secuAvali from './../../public/images/Others/info-security.png'
import plan from './../../public/images/Others/pricingplans.png'
import customersupport from './../../public/images/Others/customersupport.png'
import Confidentiality from './../../public/images/Others/Confidentiality.png'
import Intellectual from './../../public/images/Others/property.png'
import Amendments from './../../public/images/Others/Amendments.png'
import Governinglaw from './../../public/images/Others/GoverningLaw.png'
import NavbarStyleFour from '../_App/NavbarStyleFour'
import Footer from '../_App/Footer'
import PageBanner from '../Common/PageBanner'
const Terms = () => {
    return (
        <div>
            <NavbarStyleFour />
            <PageBanner pageTitle="Terms and Conditions" />
            <div className="container tandc-section">
                <div className="row">
                    <div className="col-md-8">
                        <h3>Introduction</h3>
                        <p>These Terms and Conditions govern your use of Skill Technologies' services and products. By accessing or using our services, you agree to be bound by these terms. Please read them carefully before proceeding.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={intro} alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <img src={termsservices} alt="" />
                    </div>
                    <div className="col-md-8">
                        <h3>Services</h3>
                        <p>
                            Skill Technologies provides IT solutions including Customized Application Development, Web-based Applications, mobile and web apps, e-commerce solutions, interior apps, salon management software, and ERP systems for various sectors such as B2B, e-commerce, hospitality, beauty, gaming, and entertainment.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Quality and Efficiency</h3>
                        <p>We are committed to delivering high-quality services and products that meet industry standards. Our focus is on efficiency, ensuring timely delivery and optimal performance of solutions.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={qualityeff} alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <img src={secuAvali} alt="" />
                    </div>
                    <div className="col-md-8">
                        <h3>Security and Availability</h3>
                        <p>We prioritize the security and availability of your business data and systems. Our team works diligently to maintain a secure environment and ensure uninterrupted access to our services.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Pricing and Plans</h3>
                        <p>TExplore our flexible pricing plans tailored to meet your specific needs. We offer transparent pricing structures, customizable options, and scalable solutions to provide value for your investment.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={plan} alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <img src={customersupport} alt="" />
                    </div>
                    <div className="col-md-8">
                        <h3>Customer Support</h3>
                        <p>Our qualified experts are available to assist you with any requests, issues, revisions, contracts, assets, solutions, or reports. We strive to provide exceptional customer support to ensure your satisfaction.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Confidentiality</h3>
                        <p>We respect the confidentiality of your data and information. We implement strict measures to protect your sensitive data and maintain confidentiality agreements as required.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={Confidentiality} alt="" />
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-4">
                        <img src={Intellectual} alt="" />
                    </div>
                    <div className="col-md-8">
                        <h3>Intellectual Property</h3>
                        <p>All intellectual property rights related to our services and products belong to Skill Technologies unless otherwise stated. Unauthorized use or reproduction of our intellectual property is strictly prohibited.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h3>Amendments</h3>
                        <p>Skill Technologies reserves the right to amend these Terms and Conditions at any time. Any changes will be communicated to users via our website or other appropriate channels.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={Amendments} alt="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <img src={Governinglaw} alt="" />
                    </div>
                    <div className="col-md-8">
                        <h3>Governing Law</h3>
                        <p>These Terms and Conditions are governed by the laws of India. Any disputes arising from or related to these terms shall be resolved through arbitration in Hyderabad, India.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Terms