import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import WhatWeDo from "./WhatWeDo";
import DiscoverArea from "./DiscoverArea";
import Services from "./Services";
import Team from "../Common/Team";
import BigdataFunFacts from "./BigdataFunFacts";
import Feedback from "./Feedback";
import IndustriesWeServe from "./IndustriesWeServe";
import NewsletterStyleTwo from "../Common/NewsletterStyleTwo";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import Brand from "../_App/Technologies";
import About from "../AboutUs/About";
import Contactformpopup from "../Contact/Contactformpopup";
import WhySkt from '../BigdataAnalytics/WhySkt'
import emailjs from '@emailjs/browser';
import Modal from 'react-modal';
import { AiOutlineClose } from "react-icons/ai";
// import { HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";

const MainBanner = () => {
  const form = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    from_name: "",
    email: "",
    message: "",
    contact_id: "",
  });

  const [formErrors, setFormErrors] = useState({
    from_name: "",
    email: "",
    message: "",
    contact_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs
        .sendForm('service_buupobd', 'template_566oki3', form.current, {
          publicKey: 'oP4hzCENEVwSPS29A',
        })
        .then(
          () => {
            navigate('/contact/successGIT');
            closeModal();
            console.log("Success");
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!formData.from_name.trim()) {
      errors.from_name = "Name is required";
      valid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      valid = false;
    }
    if (!formData.contact_id.trim()) {
      errors.contact_id = "Phone Number is required";
      valid = false;
    } else if (!/^[0-9]+$/.test(formData.contact_id)) {
      errors.contact_id = "Phone Number should contain only numbers";
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };
  return (
    <>
      <NavbarStyleFour />
      <div
        className="bigdata-analytics-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="bigdata-analytics-content" style={{ marginTop: "100px" }}>
                <h1
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  Empowering Your Digital Journey
                </h1>

                <p
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  At SkillTechnologies, we are dedicated to delivering <b>best</b> innovative solutions that empower businesses to thrive in the digital age.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary ms-2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  Reach Us
                </Link>
                <Link
                  to="/companyprofile/"
                  className="btn btn-primary ms-2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  Our Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-boxes-area">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <div className="single-banner-boxes">
                  <div className="icon">
                    <Icon.Server />
                  </div>
                  <h3>Quality</h3>
                  <p>
                    Excellence is the core of skill technologies. We strive tirelessly to deliver the best project outcomes possible.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <div className="single-banner-boxes">
                  <div className="icon">
                    <Icon.Code />
                  </div>
                  <h3>All-in-one Solution</h3>
                  <p>
                    We're experts in corporate branding, digital marketing, product R&D, mobile app development, web design, and support.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-sm-6"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <div className="single-banner-boxes">
                  <div className="icon">
                    <Icon.Users />
                  </div>
                  <h3>Empowering Digital Success</h3>
                  <p>
                    Skill Technologies, a trusted leader for over 9 years, specializes in web design, mobile app development, research, and marketing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Brand />
      <About />
      <Services />
      <WhySkt />
      <WhatWeDo />
      <DiscoverArea />

      <Team />
      <BigdataFunFacts />
      <Feedback />
      <IndustriesWeServe />
      <NewsletterStyleTwo />
      <Contactformpopup />
      <Footer />
      {/* <div>
        <Contactformpopup />
      </div> */}
      {/* Contact from chat */}
      {/* <div className="chaticon">
        <HiOutlineChatBubbleOvalLeft className="chat-flot" onClick={()=>openModal()}/>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="popup-content" // Use the class name for modal content styling
        overlayClassName="modal-overlay" // Use the class name for overlay styling
      >
        <>
          <div className="modal-body modal-form">
            <form ref={form} onSubmit={handleSubmit} className=''>
              <div>
                <label className='auto-popup-label' htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleInputChange}
                  className='form-control'
                />
                {formErrors.from_name && <p className="error-message text-danger">{formErrors.from_name}</p>}
              </div>

              <div>
                <label className='auto-popup-label' htmlFor="contact_id">Phone Number:</label>
                <input
                  type="Number"
                  id="contact_id"
                  name="contact_id"
                  value={formData.contact_id}
                  onChange={handleInputChange}
                  className='form-control'
                />
                {formErrors.contact_id && <p className="error-message text-danger">{formErrors.contact_id}</p>}
              </div>
              <div>
                <label className='auto-popup-label' htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className='form-control'
                />
                {formErrors.email && <p className="error-message text-danger">{formErrors.email}</p>}
              </div>
              <div>
                <label className='auto-popup-label' htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className='form-control'
                />
              </div>

              <button type="submit" className='btn btn-primary mt-2'>Submit</button>
            </form>
          </div>
          <div className="modal-footer">
            <AiOutlineClose className='close-btn text-white pointer' onClick={closeModal} />
          </div>
        </>
      </Modal> */}

    </>
  );
};

export default MainBanner;
