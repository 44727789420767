import React from "react";

import newsletter from "./../../public/images/bigdata-analytics/newsletter.jpg";
import trivadilogo from './../../public/images/project-logo/trivadi.png'
import servizeelogo from './../../public/images/project-logo/Servizee.png'
import quickbucks from './../../public/images/project-logo/quickbucks.png'
import { Link, useNavigate } from "react-router-dom";
const NewsletterStyleTwo = () => {
  const navigate = useNavigate()
  const handleScrolltop=()=>{
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className="newsletter-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="newsletter-image">
                <img
                  src={newsletter}
                  alt="image"
                  width={985}
                  height={600}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 p-0">
              <div className="newsletter-content">
                <h4 className="text-white">Discover Excellence: Explore Our Latest Products</h4>
                <p className="w-100">Skill Technologies is a leading technology firm specializing in innovative solutions for businesses across various industries. With a focus on cutting-edge technologies and a talented team of experts, we strive to deliver impactful solutions that drive growth and efficiency for our clients.</p>
                <div className="product-img d-flex flex-wrap justify-content-between mt-2">
                  <img src={trivadilogo} alt="" width={70} height={50} onClick={() => window.open('http://trivedhi.com')} />
                  <img src={servizeelogo} alt="" width={150} height={10} className="blended" onClick={() => window.open('http://trivedhi.in/')} />
                  <img src={quickbucks} alt="" width={120} height={10} onClick={() => window.open('http://quickbucks.in/')} />
                </div>
                <div className="d-flex flex-wrap justify-content-end mt-4">
                  <Link to='/contact' className="btn btn-primary" onClick={handleScrolltop}>Let's Talk</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsletterStyleTwo;
