import React, { useState, useEffect,  } from 'react';
import './ApplicationForm.css'
const Page1 = () => {

  const [FormDataPage1, setFormDataPage1] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    placeOfBirth: '',
    city: '',
    country: '',
    gender: '',
    citizenship: '',
    isPermanentSameAsPresent: null,
    presentAddress: {
      houseNo: '',
      streetName: '',
      landmark: '',
      villageName: '',
      cityName: '',
      state: '',
      pincode: '',
    },
    permanentAddress: {
      houseNo: '',
      streetName: '',
      landmark: '',
      villageName: '',
      cityName: '',
      state: '',
      pincode: '',
      mobileNo: '',
      email: '',
    },

    surgeries: '',
    familyDetails: [
      { relationship: 'Mother', relName: '', reloccupation: '', relAddress: '' },
      { relationship: 'Father', relName: '', reloccupation: '', relAddress: '' },
      { relationship: 'Spouse', relName: '', reloccupation: '', relAddress: '' },
    ],
    visionRight: '',
    visionLeft: '',
    majorIllness: '',
    bloodGroup: '',
    allergies: "",
    image: '',
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size / 1024 <= 50) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          setFormDataPage1((prevFormData) => ({
            ...prevFormData,
            image: base64Image,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        alert('Image size should be less than 50KB.');
        e.target.value = '';
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setFormDataPage1((prevState) => ({
      ...prevState,
      isPermanentSameAsPresent: isChecked,
      permanentAddress: isChecked ? FormDataPage1.presentAddress : { ...FormDataPage1.permanentAddress, mobileNo: '', email: '' },
    }));
  };


  const handlePermanentAddressChange = (e) => {
    const { name, value } = e.target;
    setFormDataPage1((prevState) => ({
      ...prevState,
      permanentAddress: {
        ...prevState.permanentAddress,
        [name]: value,
      },
    }));
  };

  const handlePresentAddressChange = (e) => {
    const { name, value } = e.target;
    setFormDataPage1((prevState) => ({
      ...prevState,
      presentAddress: {
        ...prevState.presentAddress,
        [name]: value,
      },
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'dob' && !isNaN(new Date(value).getTime())) {

      setFormDataPage1({
        ...FormDataPage1,
        [name]: value,

        age: calculateAge(value),
      });
    } else {
      setFormDataPage1({
        ...FormDataPage1,
        [name]: value,
      });
    }

  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const [formErrors, setFormErrors] = useState({});

  const handleSave = (e) => {
    e.preventDefault();
    let errors = {}; // Temporary object to store validation errors

    // Validation checks
    if (!FormDataPage1.firstName) {
      errors.firstName = 'Please enter First Name.';
    }
    if (!FormDataPage1.lastName) {
      errors.lastName = 'Please enter Last Name.';
    }
    if (!FormDataPage1.dob) {
      errors.dob = 'Please enter Date of Birth.';
    }
    if (!FormDataPage1.presentAddress.houseNo) {
      errors.houseNo = 'Please enter House Number.';
    }
    if (!FormDataPage1.presentAddress.streetName) {
      errors.streetName = 'Please enter Street Name.';
    }
    if (!FormDataPage1.presentAddress.landmark) {
      errors.landmark = 'Please enter Landmark.';
    }
    if (!FormDataPage1.presentAddress.state) {
      errors.state = 'Please enter State.';
    }
    if (!FormDataPage1.presentAddress.pincode) {
      errors.pincode = 'Please enter Pincode.';
    }
    if (!FormDataPage1.presentAddress.cityName) {
      errors.cityName = 'Please enter City Name.';
    }
    if (!FormDataPage1.presentAddress.villageName) {
      errors.villageName = 'Please enter Village Name.';
    }
    if (!FormDataPage1.bloodGroup) {
      errors.bloodGroup = 'Please enter Blood Group.';
    }
    if (!FormDataPage1.gender) {
      errors.gender = 'Please Select Gender.';
    }
    if (!FormDataPage1.citizenship) {
      errors.citizenship = 'Please Enter Citizen Ship.';
    }
    if (!FormDataPage1.permanentAddress.houseNo) {
      errors.perhouseNo = 'Please enter House Number.';
    }
    if (!FormDataPage1.permanentAddress.streetName) {
      errors.perstreetName = 'Please enter Street Name.';
    }
    if (!FormDataPage1.permanentAddress.landmark) {
      errors.perlandmark = 'Please enter Landmark.';
    }
    if (!FormDataPage1.permanentAddress.state) {
      errors.perstate = 'Please enter State.';
    }
    if (!FormDataPage1.permanentAddress.pincode) {
      errors.perpincode = 'Please enter Pincode.';
    }
    if (!FormDataPage1.permanentAddress.cityName) {
      errors.percityName = 'Please enter City Name.';
    }
    if (!FormDataPage1.permanentAddress.villageName) {
      errors.pervillageName = 'Please enter Village Name.';
    }
    if (!FormDataPage1.permanentAddress.mobileNo) {
      errors.permobileNo = 'Please enter Village Name.';
    }
    if (!FormDataPage1.permanentAddress.email) {
      errors.peremail = 'Please enter Village Name.';
    }
    if (!FormDataPage1.placeOfBirth) {
      errors.placeOfBirth = 'Please enter Place Of Birth.';
    }
    if (!FormDataPage1.city) {
      errors.city = 'Please enter City.';
    }
    if (!FormDataPage1.country) {
      errors.country = 'Please enter Country.';
    }

    setFormErrors(errors); // Update formErrors with validation errors

    if (Object.keys(errors).length === 0) {
      localStorage.setItem('FormDataPage1', JSON.stringify(FormDataPage1));
    }
  };

  useEffect(() => {
    const savedFormDataPage1 = JSON.parse(localStorage.getItem('FormDataPage1'));
    if (savedFormDataPage1) {
      setFormDataPage1(savedFormDataPage1);
    }
  }, []);

  const handleFamilyInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    const updatedFamilyDetails = [...FormDataPage1.familyDetails];
    updatedFamilyDetails[index][fieldName] = value;
    setFormDataPage1({ ...FormDataPage1, familyDetails: updatedFamilyDetails });
  };

  return (
    <div className="container">
      <form className='border p-2 m-2 res-remove-margin' onSubmit={handleSave}>
        <div className='application-con m-3 border '>
          <div className='form-details row '>
            <div className=' mt-3 col-md-9 text-center'>
              <h2 className='form-heading text-center '>Infoswan Private Limited</h2>
              <p style={{ margin: '0px', padding: '0px' }}>#402, Plot No:14, Green Hamlet, Silpa Park, Kondapur, Hyderabad-89. Telangana, India.</p>
              <p style={{ margin: '0px', padding: '0px' }}>Tel: +91-40-35843505, Mob: +91-9014183086</p>
              <p style={{ margin: '0px', padding: '0px' }}>Employee Personal Information</p>
              <p style={{ margin: '0px', padding: '0px' }}>Please answer each column fully and neatly in your own handwriting</p>
            </div>

            <div className='col-md-3 mt-3 res-remove-margin'>
              <div className='avator-uploader d-flex justify-content-start'>
                <label htmlFor="imageInput">
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    className='res-photo'
                  >
                    {FormDataPage1.image ? (
                      <img
                        src={FormDataPage1.image}
                        alt="Selected Avatar"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#ddd',
                          color: '#666',
                        }}
                      >
                        <span className='text-center p-3'>Please Choose Your Passport Photo Here</span>
                      </div>
                    )}
                  </div>
                </label>
                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
          <p className='ml-5 mt-3'>Please √ in the <span>
            <input type='checkbox' />
          </span> wherever applicable</p>
        </div>
        <div>
          {/* PERSONAL INFORMATION */}
          <div className="d-flex justify-content-between">
            <h2 className='text-heading mb-2'>PERSONAL INFORMATION : </h2>
            <div style={{marginLeft:'-40px'}}>
              <label htmlFor="">Position Applying </label>
              <input type="text" className="form-control" />
            </div>
          </div>

          <div className="form-container border p-3 m-3 res-remove-margin">

            <div className="form-left-datails mr-2 col-sm-d-none">
              <span>P</span>
              <span>E</span>
              <span>R</span>
              <span>S</span>
              <span>O</span>
              <span>N</span>
              <span>A</span>
              <span>L</span>
              <span> <br /> </span>
              <span>D</span>
              <span>E</span>
              <span>T</span>
              <span>A</span>
              <span>I</span>
              <span>L</span>
              <span>S</span>
            </div>

            <div className="form-fill">
              <div className="row container mb-2 p-3 ">
                <h3 className='app-main-heading'>NAME IN FULL :</h3>
                <p className='paragraph-app'>(in block letters)</p>
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <input type="text" placeholder='FIRST' className='form-control w-75' name="firstName" value={FormDataPage1.firstName} onChange={handleChange} />
                      {formErrors.firstName && <div className='text-danger'>{formErrors.firstName}</div>}
                    </div>
                    <div className="col-md-4 col-12">
                      <div className=''>
                        <input type="text" placeholder='MIDDLE' className='form-control w-75' name="middleName" value={FormDataPage1.middleName} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className=''>
                        <input type="text" placeholder='LAST' className='form-control w-75' name="lastName" value={FormDataPage1.lastName} onChange={handleChange} />
                        {formErrors.firstName && <div className='text-danger'>{formErrors.lastName}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='tbl-personal-details border ml-0 p-3'>
                  <div className='row border res-border-none'>
                    <div className="col-md-6 p-3 border res-border-none">
                      <h3 className='text-center fw-bold app-main-heading'>PRESENT ADDRESS</h3>
                      <input
                        type="text"
                        className={`form-control mr-2 w-100 `}
                        placeholder="House No / Flat No"
                        name="houseNo"
                        value={FormDataPage1.presentAddress.houseNo}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.houseNo && <div className='text-danger'>{formErrors.houseNo}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="StreetName"
                        name="streetName"
                        value={FormDataPage1.presentAddress.streetName}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.streetName && <div className='text-danger'>{formErrors.streetName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Land Mark"
                        name="landmark"
                        value={FormDataPage1.presentAddress.landmark}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.landmark && <div className='text-danger'>{formErrors.landmark}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Village Name"
                        name="villageName"
                        value={FormDataPage1.presentAddress.villageName}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.villageName && <div className='text-danger'>{formErrors.villageName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="City Name"
                        name="cityName"
                        value={FormDataPage1.presentAddress.cityName}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.cityName && <div className='text-danger'>{formErrors.cityName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="State"
                        name="state"
                        value={FormDataPage1.presentAddress.state}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.state && <div className='text-danger'>{formErrors.state}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Pincode"
                        name="pincode"
                        value={FormDataPage1.presentAddress.pincode}
                        onChange={handlePresentAddressChange}
                      />
                      {formErrors.pincode && <div className='text-danger'>{formErrors.pincode}</div>}
                      <div className='d-flex flex-wrap h-25 mt-3'>
                        <p className='mt-2 paragraph-app'>Tel No. (specify area code):</p> <input type="text" className='form-control w-25 remove-w-25' value={FormDataPage1.presentAddress.telNo} onChange={handlePermanentAddressChange} />
                      </div>
                    </div>
                    <div className="col-md-6 p-3 border">
                      <h3 className='text-center fw-bold app-main-heading'>PERMANENT ADDRESS</h3>
                      <div className='d-flex justify-content-center'>
                        <h3 className='text-center fw-bold mr-2 app-main-heading'>IS SAME AS PRESENT ADDRESS</h3>
                        <input type='checkbox' checked={FormDataPage1.isPermanentSameAsPresent} onChange={handleCheckboxChange} />
                      </div>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="House No/Flat No"
                        name="houseNo"
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.houseNo : FormDataPage1.permanentAddress.houseNo}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.perhouseNo && <div className='text-danger'>{formErrors.perhouseNo}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Street Name"
                        name="streetName"
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.streetName : FormDataPage1.permanentAddress.streetName}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.perstreetName && <div className='text-danger'>{formErrors.perstreetName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder='Land Mark'
                        name='landmark'
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.landmark : FormDataPage1.permanentAddress.landmark}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.perlandmark && <div className='text-danger'>{formErrors.perlandmark}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder='Village Name'
                        name='villageName'
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.villageName : FormDataPage1.permanentAddress.villageName}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.pervillageName && <div className='text-danger'>{formErrors.pervillageName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder='City Name'
                        name='cityName'
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.cityName : FormDataPage1.permanentAddress.cityName}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.cityName && <div className='text-danger'>{formErrors.cityName}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder='State'
                        name='state'
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.state : FormDataPage1.permanentAddress.state}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />
                      {formErrors.perstate && <div className='text-danger'>{formErrors.perstate}</div>}
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder='Pincode'
                        name='pincode'
                        value={FormDataPage1.isPermanentSameAsPresent ? FormDataPage1.presentAddress.pincode : FormDataPage1.permanentAddress.pincode}
                        onChange={handlePermanentAddressChange}
                        readOnly={FormDataPage1.isPermanentSameAsPresent}
                      />

                      {formErrors.perpincode && <div className='text-danger'>{formErrors.perpincode}</div>}
                      <input
                        type="text"
                        placeholder='Day time contact No. (optional)'
                        className='form-control w-100'
                        name='daytimeContactNo'
                        value={FormDataPage1.permanentAddress.daytimeContactNo}
                        onChange={handlePermanentAddressChange}
                      />

                      <div className='h-25 mt-3'>
                        <div className="d-flex flex-wrap">
                          <p className='mt-2 paragraph-app'>Mobile No.:</p> <input type="text" className='form-control w-50 remove-w-25' name='mobileNo' value={FormDataPage1.permanentAddress.mobileNo} onChange={handlePermanentAddressChange} />

                        </div>
                        {formErrors.permobileNo && <div className='text-danger'>{formErrors.permobileNo}</div>}
                        <div className="d-flex flex-wrap">
                          <p className='mt-2 paragraph-app'>Email:</p> <input type="text" className='form-control w-50 remove-w-25' name='email' value={FormDataPage1.permanentAddress.email} onChange={handlePermanentAddressChange} />

                        </div>
                        {formErrors.peremail && <div className='text-danger'>{formErrors.peremail}</div>}
                      </div>
                    </div>
                  </div>

                  {/* responsive 1 */}
                  <div className='row border'>
                    <div className='col-md-3 border p-2'>
                      <h3 className='text-center mt-5 app-main-heading'>DATE OF BIRTH:</h3>
                      <center>
                        <input
                          type="date"
                          id="dob"
                          name="dob"
                          className='form-control'
                          value={FormDataPage1.dob}
                          onChange={handleChange}
                        />
                        {formErrors.dob && <div className='text-danger'>{formErrors.dob}</div>}
                      </center>
                    </div>
                    <div className='col-md-3 border p-2 d-flex flex-wrap text-center align-items-center'>
                      <label >AGE:</label>
                      <center>
                        <input
                          type="text"
                          id="age"
                          name="age"
                          className='form-control remove-w-25'
                          value={FormDataPage1.age}
                          readOnly // Age will be automatically calculated, so set it as readOnly
                        />
                      </center>

                    </div>
                    <div className='col-md-3 border p-3'>
                      <h3 className='paragraph-app'>PLACE OF BIRTH:</h3>
                      <input
                        type="text"
                        className='form-control remove-w-25 w-75'
                        value={FormDataPage1.placeOfBirth}
                        onChange={(e) => setFormDataPage1({ ...FormDataPage1, placeOfBirth: e.target.value })}
                      />
                      {formErrors.placeOfBirth && <div className='text-danger'>{formErrors.placeOfBirth}</div>}
                      <div className="d-flex flex-wrap mt-3">
                        <p className='paragraph-app mt-3'>CITY:</p>
                        <input
                          type="text"
                          className='form-control remove-w-25 w-75'
                          value={FormDataPage1.city}
                          onChange={(e) => setFormDataPage1({ ...FormDataPage1, city: e.target.value })}
                        />
                      </div>
                      {formErrors.city && <div className='text-danger'>{formErrors.city}</div>}
                      <div className="d-flex flex-wrap mt-3">
                        <p className='paragraph-app mt-3'>COUNTRY:</p>
                        <input
                          type="text"
                          className='form-control remove-w-25 w-75'
                          value={FormDataPage1.country}
                          onChange={(e) => setFormDataPage1({ ...FormDataPage1, country: e.target.value })}
                        />

                      </div>
                      {formErrors.country && <div className='text-danger'>{formErrors.country}</div>}
                    </div>
                    <div className='col-md-3 border p-3'>
                      <h3 className='text-center paragraph-app'>DOMICILE OF(STATE IN INDIA)</h3>
                      <center><input type="text" className='form-control remove-w-25 w-75' value={FormDataPage1.stateInIndia} onChange={(e) => console.log(e.target.value)} /></center>
                    </div>
                  </div>
                </div>
                <div className="row res-border-none p-3">
                  <div className='col-md-6'>
                    <h3 className='app-main-heading'>CITIZENSHIP(S)</h3>
                    <input
                      type="text"
                      className='form-control remove-w-25 w-75'
                      value={FormDataPage1.citizenship}
                      onChange={(e) => setFormDataPage1({ ...FormDataPage1, citizenship: e.target.value })}
                    />
                    {formErrors.citizenship && <div className='text-danger'>{formErrors.citizenship}</div>}
                  </div>
                  <div className="col-md-6 ">
                    <div className=" d-flex flex-wrap gender res-display-felx-column mt-4" style={{ marginTop: '20px', marginLeft: '50px' }}>
                      <p className="ms-5 mr-2 paragraph-app">GENDER: </p>
                      <div className='res-display-felx-row d-flex flex-wrap'>
                        <label className='flex-res-appa'> &nbsp; MALE<input type="radio" id="maleRadio" name="gender" value="MALE" className='mr-3 ml-3 me-5 m-l-t-res' onChange={handleChange} checked={FormDataPage1.gender === 'MALE'} /></label>
                        <label className='flex-res-appa'> &nbsp; FEMALE<input type="radio" id="femaleRadio" name="gender" value="FEMALE" className='me-3 ml-2 m-l-t-res' onChange={handleChange} checked={FormDataPage1.gender === 'FEMALE'} /></label>
                      </div>
                    </div>
                    {formErrors.gender && <div className='text-danger text-center'>{formErrors.gender}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAMILY INFORMATION */}
          <h2 className='text-heading mt-3 mb-2'>FAMILY INFORMATION : </h2>
          <div className="form-container border p-3 m-3 res-remove-margin">
            <div className="form-left-datails relationship-sec-span mr-2 col-sm-d-none">
              <span>F</span>
              <span>A</span>
              <span>M</span>
              <span>I</span>
              <span>L</span>
              <span>Y</span>
              <span> <br /> </span>
              <span>P</span>
              <span>A</span>
              <span>R</span>
              <span>T</span>
              <span>I</span>
              <span>C</span>
              <span>U</span>
              <span>L</span>
              <span>A</span>
              <span>R</span>
              <span>S</span>
            </div>
            <div className="form-fill relationship-sec w-100 overflow-y-table-app">
              <table className='family-particulars w-100 overflow-y-table-app'>
                <thead>
                  <tr>
                    <th className='p-2 border'>RELATIONSHIP</th>
                    <th className='p-2 border'>NAME</th>
                    <th className='p-2 border'>OCCUPATION</th>
                    <th className='p-2 border'>ADDRESS</th>
                  </tr>
                </thead>
                <tbody>

                  {FormDataPage1.familyDetails.map((family, index) => (
                    <tr key={index}>
                      <td className='p-4 paragraph-app border'>{family.relationship}</td>
                      <td className='border'>
                        <textarea
                          cols={5}
                          type="text"
                          className="p-4 form-control res-w-200"
                          value={family.relName}
                          onChange={(e) => handleFamilyInputChange(e, index, 'relName')}
                        />
                      </td>
                      <td className='border'>
                        <textarea
                          cols={5}
                          type="text"
                          className="p-4 form-control res-w-200"
                          value={family.reloccupation}
                          onChange={(e) => handleFamilyInputChange(e, index, 'reloccupation')}
                        />
                      </td>
                      <td className='border'>
                        <textarea
                          cols={5}
                          type="text"
                          className="p-4 form-control res-w-200"
                          value={family.relAddress}
                          onChange={(e) => handleFamilyInputChange(e, index, 'relAddress')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>


          {/* HEALTH INFORMATION*/}
          <h2 className='text-heading mb-2 mt-3'>HEALTH INFORMATION : </h2>
          <div className="form-container border p-3 m-3 res-remove-margin ">
            <div className="form-left-datails col-sm-d-none mr-2 ">
              <span>H</span>
              <span>E</span>
              <span>A</span>
              <span>L</span>
              <span>T</span>
              <span>H</span>
            </div>
            <div className="p-3 w-100">
              <div className='row'>
                <div className="col-6 border res-font-size ">
                  <p className='paragraph-app'>BLOOD GROUP:</p>
                  <textarea
                    type='text'
                    className='form-control'
                    name='bloodGroup'
                    value={FormDataPage1.bloodGroup}
                    onChange={handleChange}
                  />
                  {formErrors.bloodGroup && <div className='text-danger'>{formErrors.bloodGroup}</div>}
                </div>
                <div className="col-6 border res-font-size  ">
                  <p className='paragraph-app'>ALLERGIES, IF ANY:</p>
                  <textarea
                    type='text'
                    className='form-control'
                    name='allergies'
                    value={FormDataPage1.allergies}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <section className='row'>
                <div className="col-md-6  border p-3 ">
                  <div className='vision-inputs res-display-felx-column '>
                    <h3 className='mt-3 mr-5 application-heading'>VISION: </h3>
                    <div className='d-flex res-display-felx-column mt-3 '>
                      <div className='d-flex flex-wrap align-items-center res-remove-margin'>
                        <h3 className='mr-3 res-remove-margin app-main-heading'>LEFT:</h3>
                        <input
                          type='number'
                          className='inputs-vision w-50'
                          name='visionLeft'
                          value={FormDataPage1.visionLeft}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='d-flex align-items-center mt-2 '>
                        <h3 className='mr-3 ml-5 res-remove-margin app-main-heading'>RIGHT:</h3>
                        <input
                          type='number'
                          className='inputs-vision w-50'
                          name='visionRight'
                          value={FormDataPage1.visionRight}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                  </div>
                  {/* wtrwerwtwewe */}
                </div>
                <div className="col-md-6  border p-3 ">
                  <h3 className='app-main-heading'>LAST MAJOR ILLNESS / SURGERY (specify date)</h3>
                  <textarea
                    name='surgeries'
                    className='form-control border'
                    value={FormDataPage1.surgeries}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </section>
            </div>
          </div>
          <button className='d-flex ms-auto btn btn-primary mt-2 p-2' type="submit" >Save</button>
        </div>
      </form>
    </div>
  )
}



export default Page1;