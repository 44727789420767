import React from "react";
import google from './../../public/images/Others/google.png'
import FeedbackSwiper from './Feedbackswiper'
const Feedback = () => {
  return (
    <>
      <div className="bigdata-feedback-area pt-80 pb-50">
        <div className="container">
          <div className="section-title">
            {/* <h2>Hear What Our Customers Have to Say!</h2> */}
            <h2>Empowering Success : Client Experiences with Skill Technologies!</h2>
            
           
            {/* <img src={google} alt="" style={{float:'right'}} className="img-con-rating" height={80} width={80} /> */}
          
            <div className="bar"></div>
            <p>
              Discover the wealth of feedback and testimonials from our clients spanning years of excellence in Skill Technologies.
            </p>
          
            
          </div>

          <div className="row justify-content-center">
            <FeedbackSwiper />
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
