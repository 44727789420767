import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Swiper from 'swiper';
import banking from './../../public/images/industry-we-serve/banking.png'
import chemical from './../../public/images/industry-we-serve/chemical.png'
import ecom from './../../public/images/industry-we-serve/Ecom.png'
import education from './../../public/images/industry-we-serve/Education.png'
import health from './../../public/images/industry-we-serve/health.png'
import hospital from './../../public/images/industry-we-serve/hospitality.png'
import manufacture from './../../public/images/industry-we-serve/manufacture.png'
import retail from './../../public/images/industry-we-serve/retail.png'
import travel from './../../public/images/industry-we-serve/travel.png'

const Industries = () => {
    const [industries, setindustries] = useState(null);
    useEffect(() => {
        if (!industries) {
            const brandindustries = new Swiper('#brandindustries1', {
                breakpoints: {
                    0: { slidesPerView: 7 },
                    576: { slidesPerView: 5 },
                    768: { slidesPerView: 5 },
                    992: { slidesPerView: 5 },
                    1200: { slidesPerView: 5 },
                    1400: { slidesPerView: 6 },
                },
                loop: true, // Enable continuous loop
            });
            setindustries(brandindustries);
        }
    }, [industries]);

    useEffect(() => {
        const slideNextWithDelay = () => {
            if (industries) {
                setTimeout(() => {
                    industries.slideNext();
                    slideNextWithDelay();
                }, 2000);
            }
        };

        slideNextWithDelay();

        return () => clearTimeout();
    }, [industries]);

    return (
        <>
            <div className="brand-area py-5 mt-1">
                <div className="container th-container">
                    <div className="swiper th-slider" id="brandindustries1">
                        <div className="swiper-wrapper">

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={banking} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Banking</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={chemical} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Chemical</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={ecom} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Retail</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={education} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Education</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={health} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Health</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={hospital} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Hospitality</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={manufacture} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Manufacture</div>
                            </div>

                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={retail} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Retail</div>
                            </div>
                            <div className="swiper-slide" style={{ marginLeft: '16px' }}>
                                <img src={travel} alt="" className='industry-swipe-img' />
                                <div className='ms-3'>Travel</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Industries;


