import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from './../../public/images/LogoSKT/Skilltechnologies_transparent_logo.png';
import logoLight from './../../public/images/LogoSKT/Skilltechnologies_transparent_logo.png';
import SktLogo from './../../public/images/LogoSKT/lightlogo.png'

const NavbarStyleFour = () => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (location.pathname === '/') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  const [menu, setMenu] = useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("header");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {
        if (window.scrollY > 150) {
          elementId.classList.add("is-sticky");
        } else {
          elementId.classList.remove("is-sticky");
        }
      });
    };
  }, []);


  const classOne = menu ? "collapse navbar-collapse" : "collapse navbar-collapse show";
  const classTwo = menu ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right";
  const handleNavLinkClick = () => {
    toggleNavbar(); // Close the navbar after clicking a link
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const handleScrolltop = () => {
    window.scrollTo(0, 0);
  }

  const [isDigitalMarketingOpen, setIsDigitalMarketingOpen] = useState(false);

  const handleDigitalLinkClick = () => {
    setIsDigitalMarketingOpen(!isDigitalMarketingOpen);
  };

  return (
    <>
      <header id="header" className="headroom navbar-color-white navbar-style-four">
        <div className="startp-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                {scrolled ? (
                  <img src={logo} alt="Logo" width={150} height={40} />
                ) : (
                  <img src={location.pathname === '/' ? SktLogo : logo} alt="Logo" width={150} height={40} />
                )}
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/" && "active"}`}
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/projects/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/projects/" && "active"}`}
                    >
                      Projects
                    </Link>
                  </li>

                  <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown">
                        <Link
                          to="/services/"
                          onClick={(e) => {
                            handleNavLinkClick(e);
                            window.location.href = "/services/"; // Redirect to services page
                          }}
                          className={`nav-link ${location.pathname === "/services/" && "active"}`}
                        >
                          Services
                        </Link>
                        <ul className={`dropdown-menu dropdown-menu-dark ${isDigitalMarketingOpen ? 'show' : ''}`}>
                          <li><Link className="dropdown-item" to="/digitalmarketing">Digital Marketing</Link></li>
                          <li><Link className="dropdown-item" to="/applicationdevelopment">Application Devalopment</Link></li>
                          <li><Link className="dropdown-item" to="/websitedevelopment">Website Design & Development</Link></li>
                          <li><Link className="dropdown-item" to="/e-commerce">E-Commerce Applications</Link></li>
                          <li><Link className="dropdown-item" to="/processexcellence">Process Excellence</Link></li>
                          <li><Link className="dropdown-item" to="/dataengineering">Data Engineering</Link></li>
                          <li><Link className="dropdown-item" to="/managedservices">Managed Services</Link></li>
                          <li><Link className="dropdown-item" to="/businessintelegence">Business Intelligence</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <li className="nav-item d-none-768">
                    <Link
                      to="/services/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/services/" && "active"}`}
                    >
                      Services
                    </Link>
                  </li>


                  <li className="nav-item">
                    <Link
                      to="/careers/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/careers/" && "active"}`}
                    >
                      Careers
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/blog/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/blog/" && "active"}`}
                    >
                      Blog
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/contact/"
                      onClick={handleNavLinkClick}
                      className={`nav-link ${location.pathname === "/contact/" && "active"}`}
                    >
                      Contact
                    </Link>
                    <Link to="/login" onClick={handleScrolltop} className="btn btn-info p-1 ps-2 pe-2 login-res-show text-white">
                      Login
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Other options */}
              <div className="others-option">
                <Link to="/login" onClick={handleScrolltop} className="btn btn-info me-1 login-res text-white">
                  Login
                </Link>
                <button  onClick={()=>window.open('https://rzp.io/l/skilltech')} className="btn btn-info text-white me-1">
                  PayDues
                </button>
                <Link to="/contact" onClick={handleScrolltop} className="btn btn-info text-white">
                  Support
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarStyleFour;
