import React from 'react'
import Footer from '../../_App/Footer'
import NavbarStyleFour from '../../_App/NavbarStyleFour'

const Youtube02 = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>
                        YouTube marketing is important for small businesses because of the potential for your video to go "viral" or get large number of views from a lot of potential customers.
                        </p>
                        <p>It is shared with thousands of people via social media and email, resulting in increased traffic to your film and website.</p>
                        <p>Every minute, YouTube users upload more than 100 hours of video. It's one of the most effective ways to reach a large audience, whether you're promoting a program or giving students information. Every month, nearly 1 billion people worldwide visit YouTube.</p>
                        <p className='mb-5'>There are many videos on YouTube that can help students improve their skills. YouTube saves people both time and money. It is one of them.</p>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Youtube02;