import React from "react";
import { Link } from "react-router-dom";

const BigdataFunFacts = () => {
  const handleScrolltop=()=>{
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className="funfacts-area bg-image ptb-80">
        <div className="container pb-5">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <h2>We always try to understand users expectation</h2>
            <div className="bar"></div>
            <p>
            Prioritizing User Expectations: Our Commitment to Understanding, Anticipating, and Fulfilling User Needs for Exceptional User Experiences and Satisfaction.
            </p>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-3 col-md-3 col-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-funfact">
                <h3>100+</h3>
                <p>Projects</p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 col-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-funfact">
                <h3>80+</h3>
                <p>Feedback</p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 col-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-funfact">
                <h3>20+</h3>
                <p>Employees</p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 col-6 col-sm-6"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-funfact">
                <h3>30+</h3>
                <p>Appearance</p>
              </div>
            </div>
          </div>

          <div
            className="contact-cta-box"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="500"
            data-aos-once="true"
          >
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us</p>

            <Link to="/contact" onClick={handleScrolltop} className="btn btn-primary">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BigdataFunFacts;
