import React, { useState } from 'react'
import './admin.css'
import Modal from 'react-modal';
import { GoArrowUpLeft, GoArrowDownRight } from "react-icons/go";
const Createorder = () => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  const fulldate = `${day}-${month}-${year}`
  const data = [
    { id: 1, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 2, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 3, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 4, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 5, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 }
  ];
  const [isRecurring, setIsRecurring] = useState(false);

  const handleSelectRec = (event) => {
    setIsRecurring(event.target.value === 'recuring');
  };
  // MOdal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alignToCenter, setAlignToCenter] = useState(true);
  const toggleAlignment = () => {
    setAlignToCenter((prevAlignToCenter) => !prevAlignToCenter);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const modalStyle = {
    content: {
      position: 'absolute',
      top: alignToCenter ? '50%' : 'auto',
      right: alignToCenter ? '50%' : 0,
      bottom: alignToCenter ? '50%' : 0,
      left: alignToCenter ? '50%' : 'auto',
      transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
      margin: 0,
      height: alignToCenter ? '80%' : '80%',
      width: alignToCenter ? '40%' : '30%', // Adjusted width when centered
      borderRadius: '10px',
    },
  };
  return (
    <div className='container'>
      <div className="d-flex flex-wrap justify-content-between w-100">
        <div>Date : {fulldate}</div>
        <div>OrderID: 11A</div>
      </div>
      <h5 className='mt-3 bg-theme text-white pt-3 pb-3'>Create New Order</h5>
      <div className="d-flex flex-wrap ">
        <div className='me-3'>
          <label htmlFor="name">Customer Name</label>
          {/* <input type="text" className='form-control w-100' /> */}
          <select className="form-select">
            <option value="">--Please Select Customer--</option>
          </select>
        </div>
        <a onClick={openModal} className="btn-success mt-5 me-3 ms-2 text-success font-10px pointer">Add New +</a>
        <div className=''>
          <label htmlFor="address">Business Name</label>
          <input type="text" className='form-control w-75 ' />
        </div>
        <div className=''>
          <label htmlFor="address">Address</label>
          <input type="text" className='form-control w-75' />
        </div>
        <div className='ms-3 res-ms-3'>
          <label htmlFor="name">Service</label>
          <select name="" id="" className="form-select w-100">
            <option>Basic Website</option>
            <option>Wordpress Development</option>
            <option value="">Application Development</option>
            <option value="">Ecommerce Website</option>
            <option value="">Social Media Marketing</option>
            <option value="">SEO Services</option>
            <option value="">Youtube Marketing</option>
            <option value="">Other Services</option>
          </select>
        </div>
        <div className=''>
          <label htmlFor="name">Service Type</label>
          <select onChange={handleSelectRec} className="form-select w-100">
            <option>One Time</option>
            <option value="recuring">Recurring</option>
          </select>
        </div>
        {isRecurring && (
          <div className='ms-3 res-ms-3'>
            <label htmlFor="name">Select Duration</label>
            <select className='form-select'>
              <option value="">Monthly</option>
              <option>Three-Months</option>
              <option value="">Six-Months</option>
              <option value="">Twelve-Months</option>
            </select>
          </div>
        )}
      </div>
      <div className="d-flex flex-wrap mt-3">
        <div className='me-3'>
          <label htmlFor="start">Start Date</label>
          <input type="date" className='form-control w-100' />
        </div>
        <div>
          <label htmlFor="end">End Date</label>
          <input type="date" className='form-control w-100' />
        </div>
        <div className='ms-3 res-ms-3'>
          <label htmlFor="ordervalue">Order Value</label>
          <input type="number" onWheel={(e) => e.target.blur()} className='form-control w-100' />
        </div>
      </div>
      <button className="btn btn-primary mt-3 d-flex ms-auto zindex--">Save</button>
      {alignToCenter && isModalOpen && (
        <div
          className="Overlay"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with 50% opacity
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          onClick={closeModal} // Close modal if clicked outside
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add User Modal"
        id='modal-res'
        overlayClassName="Overlay fade-in"
        style={modalStyle}
      >
        <>
          {/* <a onClick={toggleAlignment}>{alignToCenter ? <GoArrowDownRight className='arrow-icon' /> : <GoArrowUpLeft className='arrow-icon' />}</a> */}
          <a onClick={closeModal} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
            <div className="cross" style={{ height: '20px', width: '20px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </div>
          </a>
          <h3 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Add Customer</h3>
          <div className="d-flex flex-wrap justify-content-around">
            <div>
              <label htmlFor="">Name</label>
              <input type="text" className='form-control' />
            </div>
            <div>
              <label htmlFor="">Business Name</label>
              <input type="text" className='form-control' />
            </div>
          </div>
          <div className="d-flex flex-wrap  justify-content-around">
            <div>
              <label htmlFor="">Location</label>
              <input type="text" className='form-control' />
            </div>
            <div>
              <label htmlFor="">Phone</label>
              <input type="number" className='form-control' />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-around">
            <div>
              <label htmlFor="">Email</label>
              <input type="text" className='form-control' />
            </div>
            <div>
              <label htmlFor="">Website</label>
              <input type="text" className='form-control' />
            </div>
          </div>
          <div className='ms-4'>
            <label htmlFor="">GST IN</label>
            <input type="text" className="form-control w-50" />
          </div>
          <button className="btn btn-primary d-flex ms-auto">Save</button>
        </>
      </Modal>
    </div>
  )
}

export default Createorder