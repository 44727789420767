import React, { useState } from 'react'
import { FaHistory } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import Modal from 'react-modal';

const Vieworders = () => {
  const data = [
    { id: 1, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 2, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 3, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 4, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 },
    { id: 5, date: '04/01/2024', paidThrough: 'XYZ', value: 1000, balance: 1000 }
  ];


  // MOdal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alignToCenter, setAlignToCenter] = useState(true);
  const toggleAlignment = () => {
    setAlignToCenter((prevAlignToCenter) => !prevAlignToCenter);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const modalStyle = {
    content: {
      position: 'absolute',
      top: alignToCenter ? '50%' : 'auto',
      right: alignToCenter ? '50%' : 0,
      bottom: alignToCenter ? '50%' : 0,
      left: alignToCenter ? '50%' : 'auto',
      transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
      margin: 0,
      height: alignToCenter ? '80%' : '80%',
      width: alignToCenter ? '40%' : '30%', // Adjusted width when centered
      borderRadius: '10px',
    },
  };
  // history
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [alignToCenter2, setAlignToCenter2] = useState(true);

  const toggleAlignment2 = () => {
    setAlignToCenter2((prevAlignToCenter) => !prevAlignToCenter);
  };

  const openModal2 = () => {
    setIsModalOpen2(true);
  };
  

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };
  const [formData, setFormData] = useState({
    orderId: '',
    orderValue: '',
    customer: '',
  });
  const [payments, setPayments] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    closeModal();
  };

  const fetchPayments = () => {
    const dummyData = [
      { id: 1, date: '2024-05-01', value: 100, due: 50, refId: '123ABC', status: 'Paid', mode: 'Bank Transfer' },
      { id: 2, date: '2024-05-02', value: 200, due: 100, refId: '456DEF', status: 'Pending', mode: 'Card Payment' },
      { id: 3, date: '2024-05-03', value: 150, due: 75, refId: '789GHI', status: 'Paid', mode: 'Wallets/UPI' },
    ];
    setPayments(dummyData);
    openModal2(); 
  };
  return (
    <div className='container'>
      <h4>Order Details</h4>
      <div className="d-flex flex-wrap justify-content-between bg-theme p-3 mt-3">
        <div>
          <label className='text-white' htmlFor="">Order ID</label>
          <select className="form-select">
            <option value="">--Select Order ID--</option>
          </select>
        </div>
        <div>
          <label className='text-white' htmlFor="">Name</label>
          <input type="text" className="form-control h-50" />
        </div>
        <div>
          <label className='text-white' htmlFor="">Business Name</label>
          <input type="text" className="form-control h-50" />
        </div>
        <div>
          <label className='text-white' htmlFor="">Order Value</label>
          <input type="text" className="form-control h-50" />
        </div>
        <div>
          <label className='text-white' htmlFor="">Due Amount</label>
          <input type="text" className="form-control h-50" />
        </div>
      </div>
      <hr style={{ marginTop: '100px' }} />
      <div className='table-container-2'>
        <table className='frontend-table'>
          <thead className='thead-view sticky-top zindexsm' >
            <tr className='bg-theme text-white' style={{backgroundColor:'#0A1F41'}}>
              <th scope="col" className='text-white zindexsm'>Order ID</th>
              <th scope="col" className='text-white zindexsm'>Name</th>
              <th scope="col" className='text-white zindexsm'>Business Name</th>
              <th scope="col" className='text-white zindexsm'>Order Value</th>
              <th scope="col" className='text-white zindexsm'>Paid</th>
              <th scope="col" className='text-white zindexsm'>Due Amount</th>
              <th scope="col" className='text-white zindexsm'>Actions</th>
            </tr>
          </thead>
          <tbody className='scroll-con2 table-view-orders2'>
            {data.map(item => (
              <tr key={item.id}>
                <td data-label="Account">{item.id}</td>
                <td data-label="Due Date">{item.date}</td>
                <td data-label="Amount">{item.paidThrough}</td>
                <td data-label="Period">{item.value}</td>
                <td>ls</td>
                <td data-label="Period">{item.balance}</td>
                <td data-label="Period">
                  <CiCirclePlus onClick={openModal} className='lead' />
                  <FaHistory className='ms-2 lead' onClick={openModal2} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
      <div className="mt-3 d-block justify-content-end">
        <CiCirclePlus style={{ fontSize: '20px' }} /> <span className=' lead font-10px' > Record Payment </span><br />
        <FaHistory style={{ fontSize: '20px' }} /> <span className=' lead font-10px' >View History</span>
      </div>

      <>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Add User Modal"
          id='modal-res'
          overlayClassName="Overlay zindexpp fade-in"
          style={modalStyle}
        >
          <>
            <a onClick={closeModal} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
              <div className="cross" style={{ height: '20px', width: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              </div>
            </a>
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-wrap">
                <div>
                  <label htmlFor="orderId">Order ID</label>
                  <input type="text" id="orderId" name="orderId" className="form-control w-75" onChange={handleInputChange} value={formData.orderId} />
                </div>
                <div>
                  <label htmlFor="value">Value</label>
                  <input type="text" id="value" name="value" className="form-control w-75" onChange={handleInputChange} value={formData.value} />
                </div>
                <div>
                  <label htmlFor="due">Due</label>
                  <input type="text" id="due" name="due" className="form-control w-75" onChange={handleInputChange} value={formData.due} />
                </div>
              </div>
              <h4 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Record New Payment</h4>
              <div className="d-flex justify-content-between">
                <div>
                  <label htmlFor="date">Date</label>
                  <input type="date" id="date" name="date" className="form-control" onChange={handleInputChange} value={formData.date} />
                </div>
                <div>
                  <label htmlFor="payment">Payment</label>
                  <input type="text" id="payment" name="payment" className="form-control" onChange={handleInputChange} value={formData.payment} />
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <div>
                  <label htmlFor="mode">Mode</label>
                  <select id="mode" name="mode" className='form-select' onChange={handleInputChange} value={formData.mode}>
                    <option value="">Bank Transfer</option>
                    <option value="">Wallets/UPI</option>
                    <option value="">Card Payment</option>
                    <option value="">Cash</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="reference">Reference</label>
                  <input type="text" id="reference" name="reference" className='form-control' onChange={handleInputChange} value={formData.reference} />
                </div>
              </div>

              <button type="submit" className="btn btn-primary d-flex ms-auto mt-2">
                Update
              </button>
            </form>
          </>
        </Modal>
        {/* history */}
        <Modal
          isOpen={isModalOpen2}
          onRequestClose={closeModal2}
          contentLabel="Add User Modal"
          id='modal-res'
          overlayClassName="Overlay zindexpp fade-in"
          style={modalStyle}
        >
          <>
            <a onClick={closeModal2} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
              <div className="cross" style={{ height: '20px', width: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              </div>
            </a>
            <h4 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Payment Details</h4>
            <table>
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Value</th>
                  <th scope="col">Due</th>
                  <th scope="col">Ref Id</th>
                  <th scope="col">Status</th>
                  <th scope="col">Mode</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.date}</td>
                    <td>{payment.value}</td>
                    <td>{payment.due}</td>
                    <td>{payment.refId}</td>
                    <td>{payment.status}</td>
                    <td>{payment.mode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </Modal>
      </>
    </div>
  )
}

export default Vieworders