import React, { useState } from 'react';
import PageBanner from '../Common/PageBanner';
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { Link } from 'react-router-dom';
import { FaPlus, FaMinus } from "react-icons/fa";
import Divider from '@mui/material/Divider';
import role from '../../public/Careers/role.png';

const jobOpportunities = [
  {
    title: ".NET Developer",
    location: "Hyderabad",
    requirements: [
      "2+ experience as a .NET Developer.",
      "Degree in Web Design, Software Engineering, or similar.",
      "In-depth knowledge of .NET infrastructure.",
      "Experience developing web-based applications in C#, HTML, JavaScript, MVC Framework",
      "Experience working with MS SQL Server and MySQL Knowledge of practices and procedures for full software design life cycle",
      "Outstanding analytical, problem-solving, and communication skills."
    ]
  },
  {
    title: "Java With Spring Boot",
    location: "Hyderabad",
    requirements: [
      "2+ experience as a Java with Spring boot.",
      "Java and Spring Boot.",
      "Proficient knowledge of JPA and Hibernate.",
      "Good knowledge in Database Schema design.",
      "Expertise in deploying Spring Boot Spring Application.",
      "Experience on Front - end technologies likes Angular React is Plus."
    ]
  },
  {
    title: "Php Developer",
    location: "Hyderabad",
    requirements: [
      '3 + experience as a Php Developer.',
      ' Knowledge of front - end technologies including CSS3, JavaScript, and HTML5.',
      ' Understanding of object - oriented PHP programming.',
      ' Integration of multiple data sources and databases into one system.',
      ' Proficient with code versioning tools including Git, Mercurial, CVS, and SVN.',
      ' Familiarity with SQL / NoSQL databases.',
    ]
  },
  {
    title: "UI/UX Developer",
    location: "Hyderabad",
    requirements: [
      '2+ experience as a UI/UX Developer.',
      'Proficiency in HTML, CSS, and JavaScript for rapid prototyping.',
      'Conceptualize and design UI/UX solutions for websites, web apps and mobile apps.',
      'Ensure high-quality standards and brand consistency.',
      'Solid experience in creating wireframes, storyboards, user flows, process flows and site maps.',
      'Demonstrable UI design skills with a strong portfolio.',
    ]
  },
  {
    title: "UX Designer",
    location: "Hyderabad",
    requirements: [
      '2+ experience as a UX Designer.',
      'Proficiency in Photoshop, Illustrator, or other visual design and wire-framing tools.',
      'Solid experience in creating wireframes, storyboards, user flows, process flows.',
      'Understanding of component libraries.',
      'Curiosity and creativity - ability to think outside the box through iterative design.',
      'Excellent organizational, project management, and collaboration skills to manage a wide range of stakeholder.'
    ]
  },
  {
    title: "SEO & SMM Executive",
    location: "Hyderabad",
    requirements: [
      '1+ experience as a SEO & SMM Executive.',
      'Search engine optimization.',
      'Social Media Marketing.',
      'Link Building.',
      'Product Promotion.',
      'Online marketing.',
    ]
  },
  {
    title: "Business Development Executive",
    location: "Hyderabad",
    requirements: [
      ' Build relationships with prospective clients',
      'Maintain consistent contact with existing clients',
      'Manage sales pipeline',
      ' Analyze market and establish competitive advantages',
      'Track metrics to ensure targets are hit.',
      `Bachelor's degree 2+ years in sales industry`,
      'Experience in full sales cycle including deal',
      '3closing Demonstrated sales success',
      ' Strong negotiation skills',
      'Strong communication and presentation skills',
      'CRM experience is preferred',
    ]
  },
  {
    title: "Business Development Manager",
    location: "Hyderabad",
    requirements: [
      'Build relationships with prospective clients',
      'Maintain consistent contact with existing clients',
      'Manage sales pipeline',
      ' Analyze market and establish competitive advantages',
      'Track metrics to ensure targets are hit.',
      `Bachelor's degree 5+ years in sales industry`,
      'IT Service and product sales with good communication skills.',
      'closing Demonstrated sales success',
      ' Strong negotiation skills',
      'Strong communication and presentation skills',
      'CRM experience is preferred.',
      'Ability to generate leads and approach clients for closures to meet targets.',
    ]
  },
  {
    title: "Telecom Marketing Executive",
    location: "Hyderabad",
    requirements: [
      'Build relationships with prospective clients',
      'Maintain consistent contact with existing clients',
      'Manage sales pipeline',
      ' Analyze market and establish competitive advantages',
      'Track metrics to ensure targets are hit.',
      ' 3+ experience as a Telecom Marketing Executive.',
      'Excellent communication skills, written and verbal.',
      ' Good know- how of telecom campaigns, up selling etc.',
      ' Strong negotiation skills',
      ' Strong Marketing, Corporate Sales account management experience in Banking/ Insurance / Telecom / VAS field.',
      ' Knowledge of Telecom VAS, Apps Market place, e commerce, Call- Center scenario, Content based services etc. is added advantage',
    ]
  },
];

const chunkSize = 3;

const Careers = () => {
  const [bdeExpand, setbdeExpand] = useState(false);
  const [DigitalExpand, setDigitalExpand] = useState(false);
  const [itExpand, setitExpand] = useState(false);

  const handledigitalToggle = () => {
    setDigitalExpand(!DigitalExpand);
  };

  const handleItToggle = () => {
    setitExpand(!itExpand);
  };

  const handleToggle = () => {
    setbdeExpand(!bdeExpand);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(jobOpportunities.length / chunkSize);

  const startIndex = (currentPage - 1) * chunkSize;
  const endIndex = Math.min(startIndex + chunkSize, jobOpportunities.length);

  const currentPageJobs = jobOpportunities.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const [expandedJob, setExpandedJob] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedJob(expandedJob === index ? null : index);
  };

  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='Embark on a journey to your new career' />
      <div className='container' style={{ marginTop: '20px', marginBottom: '100px' }}>
        <h3 className='text-center mt-2 mb-2' style={{ fontSize: '24px' }}>Launch Your Career: Step into the World of Internships Today!</h3>
        <div className="row">
          <div className='col-12 col-md-4'>
            <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
              <div className='d-flex justify-content-between pointer' onClick={handleItToggle}>
                <h3 style={{ fontSize: '18px', color: '#ffffff' }}>IT Internship</h3>
                {itExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
              </div>
              {itExpand && (
                <div>
                  <p className='text-white'>Internship on various technologies like html,css,bootstrap,php,angular,java,node etc with realtime experience</p>
                  <a className='btn btn-primary' href='/applicationform' target='_blank'>Enroll Now</a>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
              <div className="d-flex justify-content-between pointer" onClick={handleToggle}>
                <h3 style={{ fontSize: '18px', color: '#ffffff' }}>Business Development Executive</h3>
                {bdeExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
              </div>
              {bdeExpand && (
                <div>
                  <p className='text-white'>Internship on various technologies like Sales Department, Marketing Department, Customer support etc with realtime experience</p>
                  <a className='btn btn-primary' href='/applicationform' target='_blank'>Enroll Now</a>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 col-md-4'>
            <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
              <div className='d-flex justify-content-between pointer' onClick={handledigitalToggle}>
                <h3 style={{ fontSize: '18px', color: '#ffffff' }}>Digital Marketing</h3>
                {DigitalExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
              </div>
              {DigitalExpand && (
                <div>
                  <p className='text-white'>Internship on various digital marketing technoques in SEO, Social media etc with realtime experience</p>
                  <a className='btn btn-primary' href='/applicationform' target='_blank'>Enroll Now</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className='text-center' style={{ fontSize: '24px' }}>New Opportunities</h3>
        <div id='New-opportunities'>
          {currentPageJobs.map((job, index) => (
            <div key={index} className="container card p-3">
              <div className='row'>
                <div className="col-12 col-md-4">
                  <div className="d-flex">
                    <p className='career-page-heading'>Full TIME</p>
                    <p>{job.location}</p>
                  </div>
                  <div className='d-flex'>
                    <img src={role} alt="job role" style={{ height: '25px', width: '25px', marginRight: '5px', marginTop: '3px' }} />
                    <h3>{job.title}</h3>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h4>We are currently hiring {job.title},</h4>
                  <h5>Requirements:</h5>
                  <ol>
                    {job.requirements.slice(0, expandedJob === index ? job.requirements.length : 3).map((requirement, reqIndex) => (
                      <li key={reqIndex}>{requirement}</li>
                    ))}
                  </ol>
                  {job.requirements.length > 3 && (
                    <a className='btn-link pointer' onClick={() => toggleReadMore(index)}>
                      {expandedJob === index ? "See Less" : "See More"}
                    </a>
                  )}
                </div>
                <div className="col-12 col-md-2 mt-5 text-center">
                  <a className='btn btn-primary' href='/applicationform' target='_blank'>Apply Now</a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination btn-con-pagination">
          <button className='btn btn-primary' onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>{`${currentPage} of ${totalPages}`}</span>
          <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Careers;


// import React, { useState } from 'react';
// import PageBanner from '../Common/PageBanner'
// import NavbarStyleFour from "../_App/NavbarStyleFour";
// import Footer from "../_App/Footer";
// import { Link } from 'react-router-dom';
// import icon8 from "../../public/images/icon7.png";
// import icon6 from "../../public/images/icon5.png";
// import icon7 from "../../public/images/icon6.png";
// import role from '../..//public/Careers/role.png'
// import { FaPlus, FaMinus } from "react-icons/fa";
// import Divider from '@mui/material/Divider';

// const jobOpportunities = [
//   {
//     title: ".NET Developer",
//     location: "Hyderabad",
//     requirements: [
//       "2+ experience as a .NET Developer.",
//       "Degree in Web Design, Software Engineering, or similar.",
//       "In-depth knowledge of .NET infrastructure.",
//       "Experience developing web-based applications in C#, HTML, JavaScript, MVC Framework",
//       "Experience working with MS SQL Server and MySQL Knowledge of practices and procedures for full software design life cycle",
//       "Outstanding analytical, problem-solving, and communication skills."
//     ]
//   },

//   {
//     title: "Java With Spring Boot",
//     location: "Hyderabad",
//     requirements: [
//       "2+ experience as a Java with Spring boot.",
//       "Java and Spring Boot.",
//       "Proficient knowledge of JPA and Hibernate.",
//       "Good knowledge in Database Schema design.",
//       "Expertise in deploying Spring Boot Spring Application.",
//       "Experience on Front - end technologies likes Angular React is Plus."
//     ]
//   },

//   {
//     title: "Php Developer",
//     location: "Hyderabad",
//     requirements: [
//       '3 + experience as a Php Developer.',
//       ' Knowledge of front - end technologies including CSS3, JavaScript, and HTML5.',
//       ' Understanding of object - oriented PHP programming.',
//       ' Integration of multiple data sources and databases into one system.',
//       ' Proficient with code versioning tools including Git, Mercurial, CVS, and SVN.',
//       ' Familiarity with SQL / NoSQL databases.',
//     ]
//   },

//   {
//     title: "UI/UX Developer",
//     location: "Hyderabad",
//     requirements: [
//       '2+ experience as a UI/UX Developer.',
//       'Proficiency in HTML, CSS, and JavaScript for rapid prototyping.',
//       'Conceptualize and design UI/UX solutions for websites, web apps and mobile apps.',
//       'Ensure high-quality standards and brand consistency.',
//       'Solid experience in creating wireframes, storyboards, user flows, process flows and site maps.',
//       'Demonstrable UI design skills with a strong portfolio.',
//     ]
//   },

//   {
//     title: "UX Designer",
//     location: "Hyderabad",
//     requirements: [
//       '2+ experience as a UX Designer.',
//       'Proficiency in Photoshop, Illustrator, or other visual design and wire-framing tools.',
//       'Solid experience in creating wireframes, storyboards, user flows, process flows.',
//       'Understanding of component libraries.',
//       'Curiosity and creativity - ability to think outside the box through iterative design.',
//       'Excellent organizational, project management, and collaboration skills to manage a wide range of stakeholder.'
//     ]
//   },

//   {
//     title: "SEO & SMM Executive",
//     location: "Hyderabad",
//     requirements: [
//       '1+ experience as a SEO & SMM Executive.',
//       'Search engine optimization.',
//       'Social Media Marketing.',
//       'Link Building.',
//       'Product Promotion.',
//       'Online marketing.',
//     ]
//   },

//   {
//     title: "Business Development Executive",
//     location: "Hyderabad",
//     requirements: [
//       ' Build relationships with prospective clients',
//       'Maintain consistent contact with existing clients',
//       'Manage sales pipeline',
//       ' Analyze market and establish competitive advantages',
//       'Track metrics to ensure targets are hit.',
//       `Bachelor's degree 2+ years in sales industry`,
//       'Experience in full sales cycle including deal',
//       '3closing Demonstrated sales success',
//       ' Strong negotiation skills',
//       'Strong communication and presentation skills',
//       'CRM experience is preferred',
//     ]
//   },

//   {
//     title: "Business Development Manager",
//     location: "Hyderabad",
//     requirements: [
//       'Build relationships with prospective clients',
//       'Maintain consistent contact with existing clien',
//       'Manage sales pipeline',
//       ' Analyze market and establish competitive advantages',
//       'Track metrics to ensure targets are hit.',
//       `Bachelor's degree 5+ years in sales industry`,
//       'IT Service and product sales with good communication skills.',
//       'closing Demonstrated sales success',
//       ' Strong negotiation skills',
//       'Strong communication and presentation skills',
//       'CRM experience is preferred.',
//       'Ability to generate leads and approach clients for closures to meet targets.',
//     ]
//   },

//   {
//     title: "Telecom Marketing Executive",
//     location: "Hyderabad",
//     requirements: [
//       'Build relationships with prospective clients',
//       'Maintain consistent contact with existing clients',
//       'Manage sales pipeline',
//       ' Analyze market and establish competitive advantages',
//       'Track metrics to ensure targets are hit.',
//       ' 3+ experience as a Telecom Marketing Executive.',
//       'Excellent communication skills, written and verbal.',
//       ' Good know- how of telecom campaigns, up selling etc.',
//       ' Strong negotiation skills',
//       ' Strong Marketing, Corporate Sales account management experience in Banking/ Insurance / Telecom / VAS field.',
//       ' Knowledge of Telecom VAS, Apps Market place, e commerce, Call- Center scenario, Content based services etc. is added advantag',
//     ]
//   },


// ];

// const chunkSize = 3;
// const Careers = () => {

//   const [bdeExpand, setbdeExpand] = useState(false);
//   const [DigitalExpand, setDigitalExpand] = useState(false);
//   const [itExpand, setitExpand] = useState(false);

//   const handledigitalToggle = () => {
//     setDigitalExpand(!DigitalExpand);
//   };

//   const handleItToggle = () => {
//     setitExpand(!itExpand);
//   };

//   const handleToggle = () => {
//     setbdeExpand(!bdeExpand);
//   };

//   const [currentPage, setCurrentPage] = useState(1);


//   const totalPages = Math.ceil(jobOpportunities.length / chunkSize);


//   const startIndex = (currentPage - 1) * chunkSize;
//   const endIndex = Math.min(startIndex + chunkSize, jobOpportunities.length);

//   const currentPageJobs = jobOpportunities.slice(startIndex, endIndex);

//   const handleNextPage = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const handlePrevPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   return (
//     <>
//       <NavbarStyleFour />
//       <PageBanner pageTitle='Embark on a journey to your new career' />
//       <div className='container' style={{ marginTop: '20px', marginBottom: '100px' }}>
//         <h3 className='text-center mt-2 mb-2' style={{ fontSize: '24px' }}>Launch Your Career: Step into the World of Internships Today!</h3>

//         <div className="row">

//           <div className='col-12 col-md-4'>
//             <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
//               <div className='d-flex justify-content-between' onClick={handleItToggle}>
//                 <h3 style={{ fontSize: '18px', color: '#ffffff' }}>IT Internship</h3>
//                 {itExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
//               </div>
//               {itExpand && (
//                 <div>
//                   <p className='text-white'>Internship on various technologies like html,css,bootstrap,php,angular,java,node etc with realtime experience</p>
//                   <Link className='btn btn-primary' to='/applicationform'>Enroll Now</Link>
//                 </div>
//               )}
//             </div>

//           </div>

//           <div className='col-12 col-md-4'>
//             <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
//               <div className="d-flex justify-content-between" onClick={handleToggle}>
//                 <h3 style={{ fontSize: '18px', color: '#ffffff' }}>Business Development Executive</h3>
//                 {bdeExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
//               </div>
//               {bdeExpand && (
//                 <div>
//                   <p className='text-white'>Internship on various technologies like Sales Department, Marketing Department, Customer support etc with realtime experience</p>
//                   <Link className='btn btn-primary' to='/applicationform'>Enroll Now</Link>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className='col-12 col-md-4'>
//             <div className='card shadow p-3' style={{ backgroundColor: '#091D3E', color: '#ffffff' }}>
//               <div className='d-flex justify-content-between' onClick={handledigitalToggle}>
//                 <h3 style={{ fontSize: '18px', color: '#ffffff' }}>Digital Marketing</h3>
//                 {DigitalExpand ? <FaMinus className={`mt-2`} style={{ marginLeft: '10px' }} /> : <FaPlus className={`mt-2`} style={{ marginLeft: '10px' }} />}
//               </div>
//               {DigitalExpand && (
//                 <div>
//                   <p className='text-white'>Internship on various digital marketing technoques in SEO, Social media etc with realtime experience</p>
//                   <Link className='btn btn-primary mt-4' to='/applicationform'>Enroll Now</Link>
//                 </div>
//               )}
//             </div>

//           </div>

//         </div>
//       </div>

//       <div>
//         <h3 className='text-center' style={{ fontSize: '24px' }}>New Opportunities</h3>
//         <div id='New-oppurchunities'>
//           {currentPageJobs.map((job, index) => (
//             <div key={index} className="container card p-3">
//               <div className='row'>
//                 <div className="col-12 col-md-4">
//                   <div className="d-flex">
//                     <p className='career-page-heading'>Full TIME</p>
//                     <p>{job.location}</p>
//                   </div>
//                   <div className='d-flex'>
//                     <img src={role} alt="job role" style={{ height: '25px', width: '25px', marginRight: '5px', marginTop: '3px' }} />
//                     <h3>{job.title}</h3>

//                   </div>

//                 </div>
//                 <div className="col-12 col-md-6">
//                   <h4>We are currently hiring {job.title},</h4>
//                   <h5>Requirements:</h5>
//                   <ol>
//                     {job.requirements.map((requirement, index) => (
//                       <li key={index}>{requirement}</li>
//                     ))}
//                   </ol>
//                 </div>
//                 <div className="col-12 col-md-2 mt-5 text-center">
//                   <Link className='btn btn-primary' to='/applicationform' target='_blank'>Apply Now</Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className="pagination btn-con-pagination ">
//           <button className='btn btn-primary' onClick={handlePrevPage} disabled={currentPage === 1}>
//             Previous
//           </button>
//           <span>{`${currentPage} of ${totalPages}`}</span>
//           <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages}>
//             Next
//           </button>
//         </div>
//       </div>
//       <Footer />
//     </>
//   )
// }

// export default Careers