import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import WebDev from './WebDev'
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import PageBanner from '../Common/PageBanner'
import ApplicationDev from './ApplicationDev'
import DigitalMarketing from './digitalmarkrting'
import Contactformpopup from "../Contact/Contactformpopup";

export default function CarouselWithTextAndImage() {
  const handleScrolltop=()=>{
    window.scrollTo(0, 0);
  }
  return (
    <div>
      <NavbarStyleFour />
      <PageBanner pageTitle='OUR PROJECTS' />
      <WebDev />
      <ApplicationDev />
      <DigitalMarketing />
       <div className="d-flex ms-auto">
       <Link to='/clients' onClick={handleScrolltop} className="btn btn-info text-white mt-1 more-client-btn">
          More Clients
        </Link>
       </div>
       <Contactformpopup />
      <Footer />
    </div>

  );
}


