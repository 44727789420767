
import React, { useState, useEffect } from 'react'
import './ApplicationForm.css'


const Page3 = () => {


  const [FormDataPage3, setFormDataPage3] = useState({
    motherTounge: "",
    languages: [
      { languageName: '', canUnderstand: false, canSpeak: false, canRead: false, canWrite: false },
    ],
    workExperience: [
      {
        organization: "",
        fromDate: "",
        toDate: "",
        durationInMonths: "",
        designationAndCtc: "",
        majorResponsibilities: "",
        reasonForSepration: '',
      }
    ],
    hardwarePlatforms: "",
    technicalSkills: "",
    technicalLanguages: "",
    companyReferences: [
      {
        reportingManagerName: "",
        companyNameAndDesgination: '',
        refEmail: '',
        refphoneNumber: ""
      },
      {
        reportingManagerName: "",
        companyNameAndDesgination: '',
        refEmail: '',
        refphoneNumber: ""
      },
      {
        reportingManagerName: "",
        companyNameAndDesgination: '',
        refEmail: '',
        refphoneNumber: ""
      },
    ],
    knowAnyoneAtInfoswan: null,
    currentlyWorking: [
      {
        WorkingPersonName: "",
        WorkingPersonRelationShip: "",
        WorkingPersonDesignation: ""
      },
      {
        WorkingPersonName: "",
        WorkingPersonRelationShip: "",
        WorkingPersonDesignation: ""
      },
    ],
    emergencyContact:[
      {
        emergencyContactName:"",
        emergencyContactPhNo:'',
        emergencyContactRelationShip:'',
      },
      {
        emergencyContactName:"",
        emergencyContactPhNo:'',
        emergencyContactRelationShip:'',
      },
      {
        emergencyContactName:"",
        emergencyContactPhNo:'',
        emergencyContactRelationShip:'',
      },
    ]
  });

  const handlePresentWorking = (value) => {
    setFormDataPage3(prevData => ({ ...prevData, knowAnyoneAtInfoswan: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataPage3(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...FormDataPage3.languages];
    updatedLanguages[index][field] = value;
    setFormDataPage3({ ...FormDataPage3, languages: updatedLanguages });
  };


  const addRow = () => {
    setFormDataPage3({
      ...FormDataPage3,
      workExperience: [
        ...FormDataPage3.workExperience,
        {
          organization: "",
          fromDate: "",
          toDate: "",
          durationInMonths: "",
          designationAndCtc: "",
          majorResponsibilities: "",
          reasonForSepration: '',
        },
      ],
    });
  };

  const addLanguageField = () => {
    setFormDataPage3({
      ...FormDataPage3,
      languages: [
        ...FormDataPage3.languages,
        { languageName: '', canUnderstand: false, canSpeak: false, canRead: false, canWrite: false },
      ],
    });
  };

  const handleSave = e => {
    e.preventDefault();
    localStorage.setItem('FormDataPage3', JSON.stringify(FormDataPage3));
  };

  useEffect(() => {
    const savedFormDataPage3 = JSON.parse(localStorage.getItem('FormDataPage3'));
    if (savedFormDataPage3) {
      setFormDataPage3(savedFormDataPage3);
    }
  }, []);


  const handleDateChange = (e, index, key) => {
    const { value } = e.target;
    const updatedFormData = { ...FormDataPage3 };
    updatedFormData.workExperience[index][key] = value;
    const fromDate = new Date(updatedFormData.workExperience[index].fromDate);
    const toDate = new Date(updatedFormData.workExperience[index].toDate);
    const diffYears = toDate.getFullYear() - fromDate.getFullYear();
    const diffMonths = diffYears * 12 + (toDate.getMonth() - fromDate.getMonth());
    updatedFormData.workExperience[index].durationInMonths = diffMonths.toString();
    setFormDataPage3(updatedFormData);
  };



  const handleWorkExoInputChange = (e, index, key, nestedKey) => {
    const { value } = e.target;
    const updatedWorkExperience = [...FormDataPage3.workExperience];
    if (nestedKey) {
      updatedWorkExperience[index][key][nestedKey] = value;
    } else {
      updatedWorkExperience[index][key] = value;
    }
    setFormDataPage3({
      ...FormDataPage3,
      workExperience: updatedWorkExperience,
    });
  };



  const handleEmergencyContactsChange = (index, key, value) => {
    const updatedContacts = [...FormDataPage3.emergencyContact];
    updatedContacts[index][key] = value;
    setFormDataPage3(prevState => ({
      ...prevState,
      emergencyContact: updatedContacts
    }));
  };


  const handlecomapyRefChange = (e, index, key) => {
    const { value } = e.target;
    const updatedFormData = { ...FormDataPage3 };
    const updatedReferences = [...updatedFormData.companyReferences];
    updatedReferences[index][key] = value;
    updatedFormData.companyReferences = updatedReferences;
    setFormDataPage3(updatedFormData);
  };



  const handleCurrentlyWorkingChange = (e, index, key) => {
    const { value } = e.target;
    setFormDataPage3(prevState => ({
      ...prevState,
      currentlyWorking: prevState.currentlyWorking.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    }));
  };



  return (
   <div className="container">
     <form className='border p-2 m-2 res-remove-margin' onSubmit={handleSave}>
      <h3 className='text-heading  mt-3'>LANGUAGES</h3>
      <div className="form-container border p-3 m-3 res-remove-margin">
        <div className="form-left-datails pass-span-container mr-2 col-sm-d-none">
          <span>L</span>
          <span>A</span>
          <span>N</span>
          <span>G</span>
          <span>U</span>
          <span>A</span>
          <span>G</span>
          <span>E</span>
          <span>S</span>
        </div>
        <div className="form-fill res-border-none res-font-size" style={{ overflowX: 'auto' }}>
          <div className='border d-flex'>
            <div className='border d-flex flex-wrap w-50 p-2'>
              <h3 className='app-main-heading mt-2'>MOTHER TONGUE:</h3>
              <input 
              className='form-control w-50'
              type="text" 
              name='motherTounge'
              value={FormDataPage3.motherTounge}   
              onChange={handleInputChange} 
               />
            </div>
            <h3 className='text-center w-50 mt-3 app-main-heading'>PROFICIENCY</h3>
          </div>

          <button type="button" className="d-block ms-auto btn btn-primary m-1 p-1" onClick={addLanguageField}>Add</button>


          <div className='d-flex justify-content-around text-center'>
            <h3 className='app-language-heading'>LANGUAGE (please highlight foreign languages)</h3>
            <h3 className='paragraph-app'>CAN <br />UNDERSTAND</h3>
            <h3 className='paragraph-app'>CAN <br />SPEAK</h3>
            <h3 className='paragraph-app'>CAN <br />READ</h3>
            <h3 className='paragraph-app'>CAN <br />WRITE</h3>

          </div>
          <div>

            {FormDataPage3.languages.map((language, index) => (
              <div key={index} className='d-flex justify-content-around p-2'>
                <input
                  type='text'
                  value={language.languageName}
                  onChange={(e) => handleLanguageChange(index, 'languageName', e.target.value)}
                  placeholder='Language Name'
                  className='form-control w-25  ml-5 margin-input-right-app'
                />
                <input
                  type='checkbox'
                  className=' mt-3 ml-5 margin-input-right-app'
                  checked={language.canUnderstand}
                  onChange={(e) => handleLanguageChange(index, 'canUnderstand', e.target.checked)}
                />
                <input
                  className=' mt-3 ml-5 mt-3 ml-5'
                  type='checkbox'
                  checked={language.canSpeak}
                  onChange={(e) => handleLanguageChange(index, 'canSpeak', e.target.checked)}
                />
                <input
                  className=' mt-3 ml-5'
                  type='checkbox'
                  checked={language.canRead}
                  onChange={(e) => handleLanguageChange(index, 'canRead', e.target.checked)}
                />
                <input
                  className=' mt-3'
                  type='checkbox'
                  checked={language.canWrite}
                  onChange={(e) => handleLanguageChange(index, 'canWrite', e.target.checked)}
                />
              </div>
            ))}

          </div>
        </div>

      </div>

      {/* WORK EXPERIENCE */}

      <h3 className='text-heading mt-3 mb-2 '>WORK EXPERIENCE</h3>
      <div className='m-3'>
        <div className="form-fill border p-3 res-remove-margin">
          <div className='d-flex justify-contact-between'>
            <div>
              <p className="fw-bold paragraph-app">PLEASE WRITE ‘NA’ IF NOT APPLICABLE.</p>
              <p className='font-sm paragraph-app'>Specify clearly in case of part time/ contract work experience</p>
            </div>
            <button onClick={addRow} className='d-block ms-auto btn btn-primary p-1'>Add</button>
          </div>
          <div style={{ overflowX: 'auto' }}>
            <table className='family-particulars'>
              <thead>
                <tr className='border' >
                  <th >ORGANIZATION</th>
                  <tr >
                    <th className='border p-2 w-25 ' colspan="3">PERIOD(MM/YYYY)</th>
                  </tr>
                  <tr className='d-flex form-control-none border'>
                    <th colspan="1" className='border ' >FROM</th>
                    <th colspan="1" className=' border '>TO</th>
                    <th colspan="1" className='  border' >DURATION <br />
                      (in months)</th>
                  </tr>
                  <th>DESIGNATION, CTC</th>
                  <th>MAJOR RESPONSIBILITIES</th>
                  <th>REASON FOR SEPARATION</th>
                </tr>
              </thead>
              <tbody>
                {FormDataPage3.workExperience.map((workExp, index) => (
                  <tr key={index}>
                    <td className='border' ><textarea type="text" className='form-control ' value={workExp.organization} onChange={(e) => handleWorkExoInputChange(e, index, 'organization')} /></td>
                    <tr className='d-flex border'>
                      <td className='form-control border '   >
                        <input className='form-control ' style={{ outline: 'none', border: 'none', borderRadius: 'none' }} value={workExp.fromDate} type="date" onChange={(e) => handleDateChange(e, index, 'fromDate')} />
                      </td>
                      <td className='form-control border'>
                        <input className='form-control ' style={{ outline: 'none', border: 'none', borderRadius: 'none' }} value={workExp.toDate} type="date" onChange={(e) => handleDateChange(e, index, 'toDate')} />
                      </td>
                      <td className='form-control border'>
                         <input className='form-control ' style={{ outline: 'none', border: 'none', borderRadius: 'none' }} value={workExp.durationInMonths} type="text" readOnly /></td>

                    </tr>
                    <td className='border pb-0' ><textarea type="text" value={workExp.designationAndCtc} className='form-control ' onChange={(e) => handleWorkExoInputChange(e, index, 'designationAndCtc')} /></td>
                    <td className='border' ><textarea type="text" value={workExp.majorResponsibilities} className='form-control ' onChange={(e) => handleWorkExoInputChange(e, index, 'majorResponsibilities')} /></td>
                    <td className='border' ><textarea type="text" value={workExp.reasonForSepration} className='form-control ' onChange={(e) => handleWorkExoInputChange(e, index, 'reasonForSepration')} /></td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>

        </div>
      </div>

      <div>
        <div className="form-container border p-3 m-3 res-remove-margin">
          <div className="form-left-datails pass-span-container col-sm-d-none mr-2">
            <span>T</span>
            <span>E</span>
            <span>C</span>
            <span>H</span>
            <span>N</span>
            <span>I</span>
            <span>C</span>
            <span>A</span>
            <span>L</span>
            <br />
            <span>S</span>
            <span>K</span>
            <span>I</span>
            <span>L</span>
            <span>L</span>
            <span>S</span>
          </div>
          <div className="p-3 w-100 res-remove-margin">
            <div className="border p-3 ">
              <p className='paragraph-app'>HARDWARE PLATFORMS WORKED ON: </p>
              <textarea
                className="form-control border"
                name="hardwarePlatforms"
                value={FormDataPage3.hardwarePlatforms}
                onChange={handleInputChange}
              />
            </div>
            <div className="border p-3">
              <p className='paragraph-app'>OPERATING SYSTEMS / DATABASES USED </p>
              <textarea
                className="form-control border"
                name="technicalSkills"
                value={FormDataPage3.technicalSkills}
                onChange={handleInputChange}
              />
            </div>
            <div className="border p-3">
              <p className='paragraph-app'>LANGUAGES & TOOLS FAMILIAR WITH: </p>
              <textarea
                className="form-control border"
                name="technicalLanguages"
                value={FormDataPage3.technicalLanguages}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-container  border p-3 m-3 res-remove-margin">
        <div className="form-left-datails pass-span-container col-sm-d-none mr-2">
          <span>R</span>
          <span>E</span>
          <span>F</span>
          <span>E</span>
          <span>R</span>
          <span>E</span>
          <span>N</span>
          <span>C</span>
          <span>E</span>
        </div>
        <div className="form-fill res-border-none p-3">
          <p className='fw-bold paragraph-app'>LIST THREE PROFESSIONAL REFERENCES (out of which one should be your latest company reporting manager)</p>
          <div style={{ overflowX: 'auto' }}>
            <table className="family-particulars">
              <thead>
                <tr className='border'>
                  <td className='border'>S. NO.</td>
                  <td className='border'>REPORTING MANAGER NAME & ADDRESS</td>
                  <td className='border'>COMPANY NAME & DESIGNATION</td>
                  <td className='border'>E-MAIL</td>
                  <td className='border'>TEL. No.</td>
                </tr>
              </thead>
              <tbody>
                {FormDataPage3.companyReferences.map((row, index) => (
                  <tr key={index}>
                    <td className='border'>{index + 1}</td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={row.reportingManagerName}
                        onChange={(e) => handlecomapyRefChange(e, index, 'reportingManagerName')}
                      />
                    </td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={row.companyNameAndDesgination}
                        onChange={(e) => handlecomapyRefChange(e, index, 'companyNameAndDesgination')}
                      />
                    </td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={row.refEmail}
                        onChange={(e) => handlecomapyRefChange(e, index, 'refEmail')}
                      />
                    </td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={row.refphoneNumber}
                        onChange={(e) => handlecomapyRefChange(e, index, 'refphoneNumber')}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-wrap  border p-3" >
            <p className='me-5 res-m-0 paragraph-app'>DO YOU KNOW ANYONE WORKING AT INFOSWAN PRESENTLY?</p>
            <label className='margin-input-lest mt-2'>NO</label>
              <input
              className='margin-input-right-app margin-input-lest'
                  type="checkbox"
                  checked={FormDataPage3.knowAnyoneAtInfoswan === false}
                  onChange={() => handlePresentWorking(false)}
                />
                <label className='margin-input-lest mt-2'>YES</label>
           <input
           className='margin-input-lest'
                  type="checkbox"
                  checked={FormDataPage3.knowAnyoneAtInfoswan === true}
                  onChange={() => handlePresentWorking(true)}
                /> 
            <p className='ms-5 res-m-0 paragraph-app'>IF YES, PLEASE LIST THEM BELOW</p>
          </div>
          <div style={{ overflowX: 'auto' }}>
            <table className="family-particulars">
              <thead>
                <tr className='border'>
                  <td className='border'>NAME</td>
                  <td className='border'>RELATIONSHIP</td>
                  <td className='border'>DESIGNATION</td>
                </tr>
              </thead>
              <tbody>
                {FormDataPage3.currentlyWorking.map((member, index) => (
                  <tr key={index}>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={member.WorkingPersonName}
                        onChange={(e) => handleCurrentlyWorkingChange(e, index, 'WorkingPersonName')}
                        readOnly={FormDataPage3.knowAnyoneAtInfoswan === false}
                      />
                    </td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={member.WorkingPersonRelationShip}
                        onChange={(e) => handleCurrentlyWorkingChange(e, index, 'WorkingPersonRelationShip')}
                        readOnly={FormDataPage3.knowAnyoneAtInfoswan === false}
                      />
                    </td>
                    <td className='border'>
                      <textarea
                        type="text"
                        className="form-control w-100-force"
                        value={member.WorkingPersonDesignation}
                        onChange={(e) => handleCurrentlyWorkingChange(e, index, 'WorkingPersonDesignation')}
                        readOnly={FormDataPage3.knowAnyoneAtInfoswan === false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex border p-3 res-p-0" >
            <p className='me-5 paragraph-app'>CONTACT PERSON IN CASE OF EMERGENCY</p>
          </div>
          <div style={{ overflowX: 'auto' }}>
  <table className='family-particulars'>
    <thead>
      <tr className='border'>
        <td className='border'>NAME & ADDRESS</td>
        <td className='border'>TEL. NO. (EVEN P. P.)</td>
        <td className='border'>RELATIONSHIP</td>
      </tr>
    </thead>
    <tbody className='border'>
      {FormDataPage3.emergencyContact.map((contact, index) => (
        <tr key={index}>
          <td className='border'>
            <textarea
              type="text"
              className='form-control w-100-force'
              value={contact.emergencyContactName}
              onChange={(e) => handleEmergencyContactsChange(index, 'emergencyContactName', e.target.value)}
            />
          </td>
          <td className='border'>
            <textarea
              type="text"
              className='form-control w-100-force'
              value={contact.emergencyContactPhNo}
              onChange={(e) => handleEmergencyContactsChange(index, 'emergencyContactPhNo', e.target.value)}
            />
          </td>
          <td className='border'>
            <textarea
              type="text"
              className='form-control w-100-force'
              value={contact.emergencyContactRelationShip}
              onChange={(e) => handleEmergencyContactsChange(index, 'emergencyContactRelationShip', e.target.value)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
      </div>

      <button className='d-flex ms-auto btn btn-primary mt-2' type="submit">Save</button>
    </form>
   </div>
  )
}



export default Page3;