import React from "react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import Accordion from 'react-bootstrap/Accordion';
import servicesDetailsImg1 from "../../public/images/LogoSKT/Managed-Services-1536x1536.png";
import PageBanner from "../Common/PageBanner";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { Link } from "react-router-dom";
import Contactformpopup from "../Contact/Contactformpopup";
const ServiceDetailsContent = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='MANAGED SERVICES' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>Managed Services</h3>
                <p>
                  Skilltechnologies is a leading provider of managed services in data management, program management, platform support, and operations management. We are committed to providing our clients with the best possible services, and we do this by employing a team of highly skilled and experienced professionals.
                </p>
                {/* <h3>Data Management</h3>
                <p>
                  We manage your data infrastructure and ensure that your data is always available, secure, and compliant. We also help you to get the most out of your data by providing you with the insights you need to make better decisions.
                </p>
                <h3>Program Management</h3>
                <p>
                  We help you to manage complex data and technology projects. We work closely with you to understand your needs and to develop a plan that meets your budget and timeline. We also help you to identify and mitigate risks, and to keep your project on track.
                </p>
                <h3>Platform Support</h3>
                <p>
                  We help you to deploy and manage the platforms you are running. We also provide you with 24/7 support to help troubleshoot and resolve any issues that may arise. We also help you to keep your platforms up-to-date and secure.
                </p>
                <h3>Operations Management</h3>
                <p>
                  We help you to manage your operations and to ensure that your systems are always running smoothly. All the SOP driven operations are carefully executed with necessary checks and balances before providing the output. We identify and resolve operational issues, and we help you to improve the efficiency and effectiveness of your operations.                </p> */}
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Data Management</Accordion.Header>
                    <Accordion.Body>
                      <p>
                      We manage your data infrastructure and ensure that your data is always available, secure, and compliant. We also help you to get the most out of your data by providing you with the insights you need to make better decisions.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Program Management</Accordion.Header>
                    <Accordion.Body>
                      <p>We help you to manage complex data and technology projects. We work closely with you to understand your needs and to develop a plan that meets your budget and timeline. We also help you to identify and mitigate risks, and to keep your project on track.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Platform Support</Accordion.Header>
                    <Accordion.Body>
                      <p>We help you to deploy and manage the platforms you are running. We also provide you with 24/7 support to help troubleshoot and resolve any issues that may arise. We also help you to keep your platforms up-to-date and secure.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Operations Management</Accordion.Header>
                    <Accordion.Body>
                      <p> We help you to manage your operations and to ensure that your systems are always running smoothly. All the SOP driven operations are carefully executed with necessary checks and balances before providing the output. We identify and resolve operational issues, and we help you to improve the efficiency and effectiveness of your operations.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
            <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
             <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>
  );
};

export default ServiceDetailsContent;
