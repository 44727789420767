import React from "react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import Accordion from 'react-bootstrap/Accordion';

import servicesDetailsImg1 from "../../public/images/LogoSKT/process.png";
import PageBanner from "../Common/PageBanner";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { Link } from "react-router-dom";
import Contactformpopup from "../Contact/Contactformpopup";
const ProcessExcellence = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='Process Excellence' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>Process Excellence</h3>
                <p>
                  We help businesses with Reduced costs, Improved quality, Increased efficiency, Shorter lead times,
                  Increased customer satisfaction, Improved employee morale with our Lean & Six Sigma Specialists.
                  We offer custom made solutions including:
                </p>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Change management</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Skilltechnologiescan help businesses to manage the change process associated with implementing techniques
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Workflow Automations</Accordion.Header>
                    <Accordion.Body>
                      <p>Skilltechnologiescan help identify opportunity areas for improvement & suggest best
                        practices including Automations to your work flows with our easy to use template & connectors</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Continuous Improvement</Accordion.Header>
                    <Accordion.Body>
                      <p>  Skilltechnologiescan help businesses to establish a culture of continuous improvement,
                        with focus on cutting down non-value addition time and adopt KAIZEN models</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
              <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
              <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>
  );
};

export default ProcessExcellence;
