import React, { useState, useEffect } from 'react'
import './ApplicationForm.css'


const Page2 = () => {


  const [FormDataPage2, setFormDataPage2] = useState({
    hasPassport: null,
    passportNo: '',
    dateOfIssue: '',
    validUpto: '',
    issuedBy: '',
    place: '',
    passportPincode: '',
    addressInPassport: {
      passportHouseNo: '',
      passportStreetName: '',
      passportVillageName: '',
      passportCityName: '',
      passportCountry: '',
      passportState: '',
      passportPincode: '',
    },
    emigrationCheckRegd: null,
    dateOfApplication: '',
    toWhom: '',

    educationDetails: [
      {
        label: "BOARD/UNIVERSITY",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "SCHOOL/COLLEGE",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "AREA OF SPECIALIZATION",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "YEAR AND MONTH OF FINAL EXAMINATION",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "TOTAL AGGREGATE MARKS SCORED FOR ALL SUBJECTS / SEMESTERS / YEARS",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "MAX. MARKS FOR ALL SUBJECTS / SEMESTERS / YEARS",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "SIMPLE AVERAGE PERCENTAGE/ CGPA/ GRADE FOR ALL YOUR SUBJECTS/ SEMESTERS",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "POSITION / RANK IN THE CLASS",
        XClass: "",
        XIIStd: "",
        graduation: "",
        postGraduation: '',
        others: '',
      },
      {
        label: "CHOOSE CERTIFICATE",
        XClass: null,
        XIIStd: null,
        graduation: null,
        postGraduation: null,
        others: null,
      },
    ],
    gapsinEducation: '',
    achievements: '',
    circularACtivities: '',
  });



  const handleEmigrationCheckChange = value => {
    setFormDataPage2(prevData => ({ ...prevData, emigrationCheckRegd: value }));
  };

  const handleCheckboxChange = value => {
    setFormDataPage2(prevData => ({ ...prevData, hasPassport: value }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormDataPage2(prevData => ({ ...prevData, [name]: value }));
  };

  const handlepassportDetails = event => {
    const { name, value } = event.target;
    if (FormDataPage2.hasPassport) {
      setFormDataPage2(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleaddressInPassport = event => {
    const { name, value } = event.target;
    setFormDataPage2(prevState => ({
      ...prevState,
      addressInPassport: {
        ...prevState.addressInPassport,
        [name]: value,
      },
    }));
  };



  const handleSave = e => {
    e.preventDefault();
    const formDataToSave = { ...FormDataPage2, };
    localStorage.setItem('FormDataPage2', JSON.stringify(formDataToSave));
  };

  const [Page2formErrors, setPage2formErrors] = useState({});



  useEffect(() => {
    const savedFormDataPage2 = JSON.parse(localStorage.getItem('FormDataPage2'));
    if (savedFormDataPage2) {
      setFormDataPage2(savedFormDataPage2);
    }
  }, []);


  const handleEducationChange = (index, e, name) => {
    const { value } = e.target;
    const updatedEducation = [...FormDataPage2.educationDetails];
    updatedEducation[index][name] = value;
    setFormDataPage2({ ...FormDataPage2, educationDetails: updatedEducation });
  };


  const handleFileChange = (index, e) => {
    const { files } = e.target;
    const updatedEducation = [...FormDataPage2.educationDetails];
    updatedEducation[index].certificate = files[0];
    setFormDataPage2({ ...FormDataPage2, educationDetails: updatedEducation });
  };

  
  return (
   <div className="container">
     <form onSubmit={handleSave} className='border p-2 m-2 res-remove-margin'>
      {/* PASSPORT INFORMATION */}
      <h2 className='text-heading mb-2'>PASSPORT DETAILS : </h2>
      <div className="form-container border p-3 m-3 res-remove-margin">
        <div className="form-left-datails pass-span-container mr-2 col-sm-d-none">
          <span>P</span>
          <span>A</span>
          <span>S</span>
          <span>S</span>
          <span>P</span>
          <span>O</span>
          <span>R</span>
          <span>T</span>
          <br />
          <span>D</span>
          <span>E</span>
          <span>T</span>
          <span>A</span>
          <span>I</span>
          <span>L</span>
          <span>S</span>
        </div>
        <div className='w-100'>
          <div className='d-flex felx-column res-display-felx-column p-2'>
            <h3 className='res-m-0 app-main-heading' style={{ marginRight: '235px' }}>DO YOU HAVE A PASSPORT?</h3>
            <div className='res-display-felx-row'>
              <label className='ml-5 res-m-0 res-ml-2 margin-lrdt' htmlFor="applied">NO
                <input
                className='margin-input-lest'
                  type="checkbox"
                  checked={FormDataPage2.hasPassport === false}
                  onChange={() => handleCheckboxChange(false)}
                />
              </label>
              <label className='ml-5 res-m-0 res-ml-2' htmlFor="applied">YES
                <input
                 className='margin-input-lest'
                  type="checkbox"
                  checked={FormDataPage2.hasPassport === true}
                  onChange={() => handleCheckboxChange(true)}
                />
              </label>
            </div>
          </div>
          <p className='ms-5 res-m-0 paragraph-app'>IF YES, PLEASE FILL IN THE PARTICULARS</p>

          {/* Passport Details */}
          <>
            <div className="d-flex justify-content-between border w-100  passport">
              <div className='p-2 w-100'>
                <p className='paragraph-app '>PASSPORT NO:</p>
                <input
                  type="text"
                  name="passportNo"
                  value={FormDataPage2.passportNo}
                  onChange={handlepassportDetails}
                  placeholder="Passport Number"
                  className='form-control w-100'
                  readOnly={FormDataPage2.hasPassport === false}
                />
              </div>
              <div className=' p-2 w-100'>
                <p className='paragraph-app '>DATE OF ISSUE:</p>
                <input
                  type="date"
                  name="dateOfIssue"
                  value={FormDataPage2.dateOfIssue}
                  onChange={handlepassportDetails}
                  className='form-control w-100'
                  readOnly={FormDataPage2.hasPassport === false}
                />
              </div>
              <div className='p-2 w-100'>
                <p className='paragraph-app '>VALID UPTO:</p>
                <input
                  type="date"
                  name="validUpto"
                  value={FormDataPage2.validUpto}
                  onChange={handlepassportDetails}
                  className='form-control'
                  readOnly={FormDataPage2.hasPassport === false}
                />
              </div>
              <div className=' p-2 w-100'>
                <p className='paragraph-app '>ISSUED BY:</p>
                <input
                  type="text"
                  name="issuedBy"
                  value={FormDataPage2.issuedBy}
                  onChange={handlepassportDetails}
                  className='form-control'
                  readOnly={FormDataPage2.hasPassport === false}
                />
              </div>
              <div className=' p-2 w-100'>
                <p className='paragraph-app '>PLACE:</p>
                <input
                  type="text"
                  name="place"
                  value={FormDataPage2.place}
                  onChange={handlepassportDetails}
                  className='form-control'
                  readOnly={FormDataPage2.hasPassport === false}
                />
              </div>
            </div>
          </>
          {/* Adrees Details in Passport */}

          <div className='border m-0  row'>
            <div className="border res-border-none p-3 col-md-6">
              <p className='paragraph-app '>ADDRESS IN PASSPORT:</p>
              <input
                type="text"
                className="form-control w-100"
                placeholder="House No / Flat No"
                name="passportHouseNo"
                value={FormDataPage2.addressInPassport.passportHouseNo}
                onChange={handleaddressInPassport}
                readOnly={FormDataPage2.hasPassport === false}
              />

              <input
                type="text"
                name="passportStreetName"
                value={FormDataPage2.addressInPassport.passportStreetName} // Specify the property to display
                onChange={handleaddressInPassport}
                placeholder="Street Name"
                className='form-control w-100'
                readOnly={FormDataPage2.hasPassport === false}
              />
              <input
                type="text"
                name="passportVillageName"
                value={FormDataPage2.addressInPassport.passportVillageName} // Specify the property to display
                onChange={handleaddressInPassport}
                placeholder="Village Name"
                className='form-control w-100'
                readOnly={FormDataPage2.hasPassport === false}
              />
              <input
                type="text"
                name="passportCityName"
                value={FormDataPage2.addressInPassport.passportCityName} // Specify the property to display
                onChange={handleaddressInPassport}
                placeholder="City Name"
                className='form-control w-100'
                readOnly={FormDataPage2.hasPassport === false}
              />

              <input
                type="text"
                name="passportState"
                value={FormDataPage2.addressInPassport.passportState} // Specify the property to display
                onChange={handleaddressInPassport}
                placeholder="State"
                className='form-control w-100'
                readOnly={FormDataPage2.hasPassport === false}
              />
              <input
                type="text"
                name="passportPincode"
                value={FormDataPage2.addressInPassport.passportPincode} // Specify the property to display
                onChange={handleaddressInPassport}
                placeholder="Pincode"
                className='form-control w-100'
                readOnly={FormDataPage2.hasPassport === false}
              />
              <input
                type="text"
                name="passportCountry"
                value={FormDataPage2.addressInPassport.passportCountry}
                onChange={handleaddressInPassport}
                className='form-control w-100'
                placeholder='Passport Country'
                readOnly={FormDataPage2.hasPassport === false}
              />
            </div>

            <div className='col-md-6 mt-5'>
              <div className='d-flex res-display-felx-column justify-content-around mt-2'>
                <h3 className='res-m-0 app-main-heading' style={{ marginRight: '100px' }}>EMIGRATION CHECK REGD:</h3>

                <div className='res-display-felx-row mb-2 '>
                  <label className=' res-m-0 res-ml-2 ' htmlFor="noEmigration">NO
                    <input
                      type="checkbox"
                      id="noEmigration"
                      checked={FormDataPage2.emigrationCheckRegd === false}
                      onChange={() => handleEmigrationCheckChange(false)}
                      className='res-ml-2 margin-input-lest'
                    />
                  </label>
                  <label className='ml-5 res-m-0 res-ml-2 margin-input-lest' htmlFor="yesEmigration">YES
                    <input
                      type="checkbox"
                      id="yesEmigration"
                      checked={FormDataPage2.emigrationCheckRegd === true}
                      onChange={() => handleEmigrationCheckChange(true)}
                      className='res-ml-2 margin-input-lest'
                    />
                  </label>
                </div>
              </div>

              <div className='p-3 res-p-0'>
                <h3 className=' res-text-left app-main-heading'>IN CASE YOU HAVE ONLY APPLIED FOR PASSPORT,</h3>
                <div className='d-flex justify-content-center mt-3 res-display-felx-column'>
                  <p className='paragraph-app '>DATE OF APPLICATION:</p>
                  <input
                    type="date"
                    className='form-control w-75'
                    value={FormDataPage2.dateOfApplication}
                    onChange={(e) => setFormDataPage2({ ...FormDataPage2, dateOfApplication: e.target.value })}
                    readOnly={FormDataPage2.emigrationCheckRegd === false}
                  />
                </div>
                <div className='d-flex justify-content-center mt-3 res-display-felx-column'>
                  <p className='paragraph-app'>TO WHOM:</p>
                  <input
                    type="text"
                    className='form-control w-75'
                    value={FormDataPage2.toWhom}
                    onChange={(e) => setFormDataPage2({ ...FormDataPage2, toWhom: e.target.value })}
                    readOnly={FormDataPage2.emigrationCheckRegd === false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EDUCATIONAL DETAILS */}
      <h2 className='text-heading mt-3 mb-2'>EDUCATIONAL INFORMATION :</h2>
      {/* responsive 1 */}
      <div className="form-container border p-3 m-3 res-remove-margin">
        <div className="form-left-datails pass-span-container mr-2 col-sm-d-none">
          <span>E</span>
          <span>D</span>
          <span>U</span>
          <span>C</span>
          <span>A</span>
          <span>T</span>
          <span>I</span>
          <span>O</span>
          <span>N</span>
          <span><br /></span>
          <span>D</span>
          <span>E</span>
          <span>T</span>
          <span>A</span>
          <span>I</span>
          <span>L</span>
          <span>S</span>

        </div>
        <div style={{ overflowX: 'auto' }}>
          <div>
            <table className='family-particulars education-1-tbl border'>
              <thead >

                <td className='border'>LEVEL</td>
                <td className='border'>X STD</td>
                <td className='border'>XII STD/ DIP/ EQUIV</td>
                <td className='border'>GRADUATION</td>
                <td className='border'>POST GRADUATION</td>
                <td className='border'>OTHERS</td>

              </thead>
              <tbody>
                {FormDataPage2.educationDetails.map((education, index) => (
                  <tr key={index}>
                    <td className='border'>{education.label}</td>
                    <td className='border'>
                      {education.label === "CHOOSE CERTIFICATE" ? (
                        <input
                          type="file"
                          className="form-control res-w-200"
                          onChange={(e) => handleFileChange(index, e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control res-w-200"
                          value={education.XClass} // Assuming XClass is the correct property to display
                          onChange={(e) => handleEducationChange(index, e, 'XClass')}
                        />
                      )}
                    </td>
                    <td className='border'>
                      {education.label === "CHOOSE CERTIFICATE" ? (
                        <input
                          type="file"
                          className="form-control res-w-200"
                          onChange={(e) => handleFileChange(index, e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control res-w-200"
                          value={education.XIIStd} // Assuming XIIStd is the correct property to display
                          onChange={(e) => handleEducationChange(index, e, 'XIIStd')}
                        />
                      )}
                    </td>
                    <td className='border'>
                      {education.label === "CHOOSE CERTIFICATE" ? (
                        <input
                          type="file"
                          className="form-control res-w-200"
                          onChange={(e) => handleFileChange(index, e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control res-w-200"
                          value={education.graduation} // Assuming graduation is the correct property to display
                          onChange={(e) => handleEducationChange(index, e, 'graduation')}
                        />
                      )}
                    </td>
                    <td className='border'>
                      {education.label === "CHOOSE CERTIFICATE" ? (
                        <input
                          type="file"
                          className="form-control res-w-200"
                          onChange={(e) => handleFileChange(index, e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control res-w-200"
                          value={education.postGraduation} // Assuming postGraduation is the correct property to display
                          onChange={(e) => handleEducationChange(index, e, 'postGraduation')}
                        />
                      )}
                    </td>
                    <td className='border'>
                      {education.label === "CHOOSE CERTIFICATE" ? (
                        <input
                          type="file"
                          className="form-control res-w-200"
                          onChange={(e) => handleFileChange(index, e)}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control res-w-200"
                          value={education.others} // Assuming others is the correct property to display
                          onChange={(e) => handleEducationChange(index, e, 'others')}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>


            </table>


          </div>
        </div>
        <div>
        </div>
      </div>

      <div className="form-container p-3 ml-3 mr-3 res-remove-margin">
        <div className='w-100 border'>
          <div>
            <p className='border pl-1 paragraph-app '>ALL PERCENTAGES / CGPA SHOULD BE SIMPLE AVERAGE FOR ALL YOUR SUBJECTS / SEMESTERS / YEARS</p>
            <div className='border p-3'>
              <p className='paragraph-app '>GAPS IN EDUCATION (If Any):</p>
              <textarea
                className='form-control border'
                value={FormDataPage2.gapsinEducation}
                onChange={(e) => setFormDataPage2({ ...FormDataPage2, gapsinEducation: e.target.value })}
              ></textarea>
            </div>
            <div className='border p-3'>
              <p className='paragraph-app '>SCHOLASTIC ACHIEVEMENTS (Ranks, Merit Scholarships, Prizes, etc.,): </p>
              <textarea
                className='form-control border'
                value={FormDataPage2.achievements}
                onChange={(e) => setFormDataPage2({ ...FormDataPage2, achievements: e.target.value })}
              ></textarea>
            </div>
            <div className='border p-3'>
              <p className='paragraph-app '>EXTRA CURRICULAR ACTIVITIES:</p>
              <textarea
                className='form-control border'
                value={FormDataPage2.circularACtivities}
                onChange={(e) => setFormDataPage2({ ...FormDataPage2, circularACtivities: e.target.value })}
              ></textarea>
            </div>
          </div>

        </div>

        <div>
        </div>

      </div>

      <button className='d-flex ms-auto btn btn-primary mt-2' type="submit">Save</button>
    </form>
   </div>
  )
}




export default Page2;
