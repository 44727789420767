import React from "react";
import logo from '../../public/images/LogoSKT/SKT-LOGO-DARK.png';
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
const ForgotPasswordForm = () => {
  return (
    <>
    <NavbarStyleFour />
      <div className="ptb-80">
        <div className="container">
          <div className="auth-form">
            <div className="auth-head">
              <a href="/">
                <img src={logo} width={110} height={36} />
              </a>
              <p>
                Enter the email address associated with your account and we will send you OTP to reset your passowrd
              </p>
            </div>

            <form>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPasswordForm;
