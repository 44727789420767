import React, { useState, useEffect } from "react";
import blink from '../../public/Digitalmarketingimgs/blinkfamilysalon.png'
import h2h from '../../public/Digitalmarketingimgs/halohuesstudios.png'
import kassy from '../../public/Digitalmarketingimgs/kassayhomes.png'
import mschool from '../../public/Digitalmarketingimgs/mountcarmelschool.png' 
import poornas from '../../public/Digitalmarketingimgs/poornas hairandbeauty.png'
import rapidclinic from '../../public/Digitalmarketingimgs/rapidclinichyd.png'
import salaamnamaste from '../../public/Digitalmarketingimgs/salaamnamaste.png'
import smilemakers from '../../public/Digitalmarketingimgs/smilemakersinternational.png'
import dendemands from '../../public/Digitalmarketingimgs/dendemands.png'
import hardcrop from '../../public/Digitalmarketingimgs/hardcropgym.png'
import medline from '../../public/Digitalmarketingimgs/medline.png'
import oskin from '../../public/Digitalmarketingimgs/oskin.png'
import soulsalon from '../../public/Digitalmarketingimgs/soulsalon.png'
import Swiper from "swiper";
import '../../styles/style.css'

export default function DigitalMarketing() {


  const [Digitalslider, setDigitalslider] = useState(null);
  useEffect(() => {
    if (!Digitalslider) {
      const brandDigitalslider = new Swiper('#DigitalMarketing', {
        breakpoints: {
          0: { slidesPerView: 1 },
          576: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          992: { slidesPerView: 2 },
          1200: { slidesPerView: 3 },
        },
        loop: true,
      });
      setDigitalslider(brandDigitalslider);
    }
  }, [Digitalslider]);

  useEffect(() => {
    const slideNextWithDelay = () => {
      if (Digitalslider) {
        setTimeout(() => {
          Digitalslider.slideNext();
          slideNextWithDelay();
        }, 2000);
      }
    };

    slideNextWithDelay();

    return () => clearTimeout();
  }, [Digitalslider]);

  const slideToPrev = () => {
    if (Digitalslider) {
      Digitalslider.slidePrev();
    }
  };

  const slideToNext = () => {
    if (Digitalslider) {
      Digitalslider.slideNext();
    }
  };

  return (
    <>
      <section class="bg-top-center space " style={{ heigh: '150px', width: '100%', paddingLeft: '80px', paddingRight: '50px' }}>
        <div>
          <div class="title-area text-center">
            <h3 class="sub-title">Digital Marketing</h3>
          </div>
          <div class="slider-area">
            <div class="swiper th-slider has-shadow" id="DigitalMarketing" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'>
              <div class="swiper-wrapper">

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1' }}>
                  <div className="single-ml-projects-box">
                    <img src={smilemakers} alt="image" width={850} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/smilemakersinternational/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={salaamnamaste} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/salaamnamaste_hyd/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={rapidclinic} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/rapidclinichyd/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={poornas} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/poornas_hairandbeauty/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={mschool} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/mountcarmelschool.kondapur/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={kassy} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/kassay_homes/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={h2h} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/halohuesstudios/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={blink} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.instagram.com/blink_familysalon/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
  {/* Fackbook Page */}

{/* 
https://www.facebook.com/medlinediagnosticlab
https://www.facebook.com/DenDemands
https://www.facebook.com/soulsalonblr
https://www.facebook.com/HardCorpGym
https://www.facebook.com/oskinclinichyd/

*/}

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={dendemands} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.facebook.com/DenDemands">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={medline} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.facebook.com/medlinediagnosticlab">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={soulsalon} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.facebook.com/soulsalonblr">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={hardcrop} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.facebook.com/HardCorpGym">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={oskin} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://www.facebook.com/oskinclinichyd/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>     
        </div>
           </section>
    </>

  );
}


