import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'
import MainBanner from './components/BigdataAnalytics/MainBanner'
import './styles/responsive.css'
import './styles/slick.css'
import './styles/style.css.map'
import './styles/style.css'
import './styles/style.scss'
import './styles/rtl.scss'
import './styles/responsive.scss'
import BlogGridPost from './components/Blog/BlogGridPost';
import PayDues from './components/PayDues/PayDues'
import PaymentGateway from './components/PayDues/PaymentGateway'
import GoTop from './components/_App/GoTop';
import ServicesStyle3 from './components/Services/Services'
import LoginForm from './components/Auth/LoginForm'
import SignUpForm from './components/Auth/SignUpForm'
import ForgotPasswordForm from './components/Auth/ForgotPasswordForm'
import ContactForm from './components/Contact/ContactForm'
import ProjectsCardStyle2 from './components/Projects/ProjectsCardStyle2'
import About from './components/AboutUs/About'
import BlogDetailsContent from './components/Blog/BlogDetailsContent'
import BusinessIntelegence from './components/OurServices/BusinessIntelegence'
import DataEngineering from './components/OurServices/DataEngineering'
import ProcessExcellence from './components/OurServices/processExcellence'
import ManagedServices from './components/OurServices/ManagedServices'
import ApplicationDevelopment from './components/OurServices/ApplicationDevelopment'
import DigitalMarketing from './components/OurServices/DigitalMarketing'
import WebsiteDevelopment from './components/OurServices/WebsiteDevelopment'
import Admin from './Admin/MainAdmin/Admin';
import Dashboard from './Admin/AdminComponents/Dashboard';
import Createorder from './Admin/AdminComponents/Createorder';
import Vieworders from './Admin/AdminComponents/OrderDetails';
import AdminProfile from './Admin/AdminComponents/AdminProfile';
import ITSupport from './components/OurServices/ItSupport'
import PrivacyPolicy from './components/Others/PrivacyPolicy';
import Faq from './components/Others/Faq';
import Terms from './components/Others/Terms';
import ReturnPolicy from './components/Others/ReturnPolicy';
import Clients from './components/Projects/Clients';
import Awards from './components/_App/reganisation'
import UnlockingBusiness from './components/Blog/UnlockingBusiness';
import DigitalMarketingTabs from './components/OurServices/DigitalMarketingTabs';
import WhatsApp from './components/_App/WhatsApp';
import Blog3 from './components/Blog/Blog3';
import Blog4 from './components/Blog/Blog4';
import ApplicationForm from './components/ApplicationForm/MainForm';
import Blog5 from './components/Blog/Blog5';
import Blog6 from './components/Blog/Blog6';
import SEO from './components/OurServices/DigitalMarketing/SEO';
import Global from './components/OurServices/DigitalMarketing/Global';
import Ecom from './components/OurServices/DigitalMarketing/Ecom';
import WhySMM from './components/OurServices/DigitalMarketing/WhySMM';
import WearebestSmm from './components/OurServices/DigitalMarketing/WearebestSmm';
import ServicesSsm from './components/OurServices/DigitalMarketing/ServicesSsm';
import Careers from './components/careers/Careers';
import YoutubeWt from './components/OurServices/DigitalMarketing/youtube1';
import Youtube02 from './components/OurServices/DigitalMarketing/youtube02';
import Youtube03 from './components/OurServices/DigitalMarketing/youtube03';
import GoogleAdwords from './components/OurServices/DigitalMarketing/googleawards';
import GoogleAdwords02 from './components/OurServices/DigitalMarketing/googleadwords02';
import GoogleAdwords03 from './components/OurServices/DigitalMarketing/googleadwords03';
import DigitalM from './components/Blog/DigitalM';
import DataS from './components/Blog/DataS';
import Lot from './components/Blog/Lot';
import SuccessGIT from './Admin/AdminComponents/CommonAdmin/SuccessGIT'
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainBanner />} />
          <Route path="/blog" element={<BlogGridPost />} />
          <Route path="/paydues" element={<PayDues />} />
          <Route path="/paymentgateway" element={<PaymentGateway />} />
          <Route path="/services" element={<ServicesStyle3 />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/sign-up" element={<SignUpForm />} />
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/projects" element={<ProjectsCardStyle2 />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/blog/4" element={<BlogDetailsContent />} />
          <Route path="/businessintelegence" element={<BusinessIntelegence />} />
          <Route path="/dataengineering" element={<DataEngineering />} />
          <Route path="/processexcellence" element={<ProcessExcellence />} />
          <Route path="/managedservices" element={<ManagedServices />} />
          <Route path="/applicationdevelopment" element={<ApplicationDevelopment />} />
          <Route path="/websitedevelopment" element={<WebsiteDevelopment />} />
          <Route path="/digitalmarketing" element={<DigitalMarketing />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/createorder" element={<Createorder />} />
          <Route path="/admin/vieworder" element={<Vieworders />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
          <Route path="/e-commerce" element={<ITSupport />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/ReturnPolicy" element={<ReturnPolicy />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/our-recognition" element={<Awards />} />
          <Route path="/blog/2" element={<UnlockingBusiness />} />
          <Route path="/marketingtabs" element={<DigitalMarketingTabs />} />
          <Route path="/blog/8" element={<Blog3 />} />
          <Route path="/blog/5" element={<Blog4 />} />
          <Route path="/blog/9" element={<Blog5 />} />
          <Route path="/blog/1" element={<Blog6 />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/applicationform" element={<ApplicationForm />} />
          <Route path="/seo/local" element={<SEO />} />
          <Route path="/seo/global" element={<Global />} />
          <Route path="/seo/ecom" element={<Ecom />} />
          <Route path="/seo/whysmm" element={<WhySMM />} />
          <Route path="/seo/bestsmm" element={<WearebestSmm />} />
          <Route path="/seo/servicessm" element={<ServicesSsm />} />
          <Route path="/Youtube/What-is-youtube-marketing" element={<YoutubeWt />} />
          <Route path="/Youtube/importance-of-youtube-marketing" element={<Youtube02 />} />
          <Route path="/Youtube/advantages-of-youtube-marketing" element={<Youtube03 />} />
          <Route path="/googleadwords/want-to-grow" element={<GoogleAdwords />} />
          <Route path="/googleadwords/useful-of-google-adwords" element={<GoogleAdwords02 />} />
          <Route path="/googleadwords/why-choose-skill-technologies" element={<GoogleAdwords03 />} />
          <Route path='/blog/6' element={<DigitalM />} />
          <Route path='/blog/7' element={<DataS />} />
          <Route path="/blog/3" element={<Lot />} />
          <Route path="/contact/successGIT" element={<SuccessGIT />} />
        </Routes>
      </Router>
      <GoTop />
      <WhatsApp />
    </>
  );
}

export default App;
