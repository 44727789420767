import React, { useRef, useState } from 'react'
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
const Customer = () => {
    const [formData, setFormData] = useState({
        name: '',
        businessName: '',
        location: '',
        phone: '',
        email: '',
        website: '',
        gstIN: ''
    });
    const [jsonData, setJsonData] = useState([
        {
            "orderId": 1,
            "name": "John Doe",
            "businessName": "Doe Enterprises",
            "location": "New York",
            "phone": "+1234567890",
            "email": "john.doe@",
            "website": "www.",
            "gstIN": "GSTIN12345"
        },
        {
            "orderId": 2,
            "name": "Jane Smith",
            "businessName": "Smith Co.",
            "location": "Los Angeles",
            "phone": "+1987654321",
            "email": "jane.smith@",
            "website": "www.",
            "gstIN": "GSTIN67890"
        },
        {
            "orderId": 3,
            "name": "Michael Johnson",
            "businessName": "Johnson Corporation",
            "location": "Chicago",
            "phone": "+1122334455",
            "email": "michael.johnson@",
            "website": "www.",
            "gstIN": "GSTIN24680"
        }
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [alignToCenter, setAlignToCenter] = useState(true);
    const toggleAlignment = () => {
        setAlignToCenter((prevAlignToCenter) => !prevAlignToCenter);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const modalStyle = {
        content: {
            position: 'absolute',
            top: alignToCenter ? '50%' : 'auto',
            right: alignToCenter ? '50%' : 0,
            bottom: alignToCenter ? '50%' : 0,
            left: alignToCenter ? '50%' : 'auto',
            transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
            margin: 0,
            height: alignToCenter ? '80%' : '80%',
            width: alignToCenter ? '40%' : '30%', // Adjusted width when centered
            borderRadius: '10px',
        },
    };
    const exportData = () => {
        const doc = new jsPDF();
        doc.text(JSON.stringify(jsonData), 10, 10);
        doc.save('data.pdf');
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const saveData = () => {
        const newData = {
            orderId: jsonData.length + 1, // Generate a new orderId
            ...formData
        };
        setJsonData((prevJsonData) => [...prevJsonData, newData]);
        closeModal();
    };
    return (
        <div>
            <div className="d-flex justify-content-end">
                <a className='text-success pointer' onClick={openModal}>Add New +</a>
            </div>
            <div className="tbl-container">
                <table className='frontend-table table-res'>
                    <thead>
                        <tr className='bg-theme text-white' style={{ backgroundColor: '#0A1F41' }}>
                            <th scope="col">Order ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Business Name</th>
                            <th scope="col">Location</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">Website</th>
                            <th scope="col">GST IN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jsonData.map(item => (
                            <tr key={item.orderId}>
                                <td>{item.orderId}</td>
                                <td>{item.name}</td>
                                <td>{item.businessName}</td>
                                <td>{item.location}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td>{item.website}</td>
                                <td>{item.gstIN}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Add User Modal"
                    id='modal-res'
                    overlayClassName="Overlay zindexpp fade-in"
                    style={modalStyle}
                >
                    <>
                        <a onClick={closeModal} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
                            <div className="cross" style={{ height: '20px', width: '20px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                            </div>
                        </a>
                        <h3 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Add Customer</h3>
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="name" className='form-control' onChange={handleInputChange} />
                            </div>
                            <div>
                                <label htmlFor="businessName">Business Name</label>
                                <input type="text" id="businessName" name="businessName" className='form-control' onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap  justify-content-around">
                            <div>
                                <label htmlFor="location">Location</label>
                                <input type="text" id="location" name="location" className='form-control' onChange={handleInputChange} />
                            </div>
                            <div>
                                <label htmlFor="phone">Phone</label>
                                <input type="number" id="phone" name="phone" className='form-control' onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-around">
                            <div>
                                <label htmlFor="email">Email</label>
                                <input type="text" id="email" name="email" className='form-control' onChange={handleInputChange} />
                            </div>
                            <div>
                                <label htmlFor="website">Website</label>
                                <input type="text" id="website" name="website" className='form-control' onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className='ms-4'>
                            <label htmlFor="gstIN">GST IN</label>
                            <input type="text" id="gstIN" name="gstIN" className="form-control w-50" onChange={handleInputChange} />
                        </div>
                        <button type="button" className="btn btn-primary d-flex ms-auto" onClick={saveData}>Save</button>
                    </>
                </Modal>

            </>
            <button className="btn btn-primary mt-2" onClick={exportData}>Export</button>
        </div>
    )
}

export default Customer