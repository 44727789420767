import React, { useEffect, useRef } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import Chart from 'chart.js/auto';
import './admin.css'
// import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography, Breadcrumbs, Link } from '@mui/material';
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  // Export,
} from 'devextreme-react/pie-chart';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ProductsYearlyoptions = {
  animationEnabled: true,
  theme: "light2",
  axisX: {
    reversed: true,
  },
  axisY: {
    includeZero: true,
    // labelFormatter: this.addSymbols,
    interval: 1000,
    labelFontColor: "#008B8B",
    titleFontColor: "#008B8B"
  },
  data: [{
    type: "bar",
    color: "#6495ED",
    dataPoints: [
      { y: 2478, label: "2000", color: "#dc3545" },
      { y: 5267, label: "2010", color: "#6610f2" },
      { y: 734, label: "2011", color: "#20c997" },
      { y: 784, label: "2015", color: "#ffc107" },
      { y: 433, label: "2020", color: "#6610f2" },
    ]
  }]
};
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Dashboard = () => {

  function pointClickHandler(e) {
    toggleVisibility(e.target);
  }

  function legendClickHandler(e) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
    toggleVisibility(item);
  }

  function toggleVisibility(item) {
    item.isVisible() ? item.hide() : item.show();
  }

  // Sales Overview Garph Code 
  const SaleslineChartoptions = {
    animationEnabled: true,
    axisY: {
      includeZero: true,
      interval: 25,
      maximum: 100
    },
    toolTip: {
      shared: true
    },
    data: [
      {
        type: "spline",
        color: '#e83e8c',
        showInLegend: true,
        dataPoints: [
          { y: 90, label: "2006" },
          { y: 65, label: "2007" },
          { y: 40, label: "2008" },
          { y: 40, label: "2009" },
          { y: 30, label: "2010" },
          { y: 65, label: "2011" },
          { y: 50, label: "2012" }
        ]
      },
      {
        type: "spline",
        color: '#6610f2',
        showInLegend: true,
        dataPoints: [
          { y: 50, label: "2006" },
          { y: 75, label: "2007" },
          { y: 50, label: "2008" },
          { y: 75, label: "2009" },
          { y: 50, label: "2010" },
          { y: 75, label: "2011" },
          { y: 100, label: "2012" }
        ]
      }
    ]
  };



  const totalsalesChartoptions = {
    animationEnabled: true,
    axisY: {
      includeZero: true,
      interval: 30,
      maximum: 150,
      Label: 'Products'
    },
    toolTip: {
      shared: true
    },
    data: [
      {
        type: "spline",
        color: '#6610f2',
        Label: 'Products',
        showInLegend: true,
        dataPoints: [
          { y: 10, label: "Jan" },
          { y: 41, label: "Feb" },
          { y: 35, label: "Mar" },
          { y: 51, label: "Apr" },
          { y: 49, label: "May" },
          { y: 62, label: "Jun" },
          { y: 69, label: "Jul" },
          { y: 91, label: "Aug" },
          { y: 148, label: "Sept" },
        ]
      },
    ]
  };


  const YearlyProjects = {
    axisY: {
      includeZero: true,
      interval: 1000,
      maximum: 6000,
      label: 'Projects' // corrected typo from Label to label
    },
    data: [
      {
        type: 'column',
        dataPoints: [
          { label: '2006', y: 2478, color: '#e83e8c' },
          { label: '2010', y: 5267, color: '#6f42c1' },
          { label: '2011', y: 734, color: '#dc3545' },
          { label: '2012', y: 784, color: '#fd7e14;' },
          { label: '2018', y: 433, color: '#6610f2' }
        ]
      }
    ]
  };

  // last one
  const data = [
    { name: 'A', lineData: 2, barData: 3 },
    { name: 'B', lineData: 3, barData: 4 },
    { name: 'C', lineData: 5.5, barData: 6 },
    { name: 'D', lineData: 8.5, barData: 7 },
    { name: 'E', lineData: 1.5, barData: 2 },
    { name: 'F', lineData: 5, barData: 6 },
    { name: 'G', lineData: 1, barData: 2 },
    { name: 'H', lineData: 4, barData: 5 },
    { name: 'I', lineData: 3, barData: 3 },
    { name: 'J', lineData: 8, barData: 9 },
  ];
  //   Tasks Data

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current !== null) {
      chartRef.current.destroy(); // Destroy previous chart instance
    }

    // Chart.js configuration
    const ctx = document.getElementById('mixed-chart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Task 1', 'Task 2', 'Task 3', 'Task 4'],
        datasets: [
          {
            label: 'Completed Tasks',
            data: [408, 547, 675, 734,],
            backgroundColor: 'light grey',
            borderWidth: 1
          },
          {
            label: 'Task Started',
            data: [133, 221, 783, 2478,],
            backgroundColor: 'light grey',
            borderWidth: 1
          },
          {
            label: 'Completed Tasks',
            data: [408, 547, 675, 734,],
            type: 'line',
            fill: false,
            borderColor: '#6610f2',
            borderWidth: 2
          },
          {
            label: 'Task Started',
            data: [133, 221, 783, 2478,],
            type: 'line',
            fill: false,
            borderColor: '#dc3545',
            borderWidth: 2
          }
        ]
      },
      options: {
        axisY: {
          includeZero: true,
          interval: 500,
          maximum: 2500,
          label: 'Projects' // corrected typo from Label to label
        },
      }
    });

    return () => {
      if (chartRef.current !== null) {
        chartRef.current.destroy(); // Cleanup on component unmount
      }
    };
  }, []);
  let chartWidth = 400;
  let chartHeight = 200;

  const screenWidth = window.innerWidth;
  if (screenWidth <= 600) {
    chartWidth = 10;
    chartHeight = 10;
  }
  const areas = [
    {
      country: 'Asia',
      area: 5267
    },
    {
      country: 'Europe',
      area: 2478,
    },
  ];
  return (
    <div id='main-dash'>

      <div role="presentation" className='d-flex ms-4 mb-3 heading-margin-res' >
        <Typography variant="text" sx={{ fontWeight: 900, fontSize: 25, marginLeft: -1, color: '#00352D' }}>  Dash Board</Typography>
        <Breadcrumbs aria-label="breadcrumb" className='m-3'>
          <Link
            className='res-d-none'
            underline="hover"
            color="text.primary"
            aria-current="page"
          >
            Dash Board
          </Link>
        </Breadcrumbs>
      </div>

      <div className="container">
        <div className='row'>
          {/* <div className="col-md-6">
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: 10, label: 'series A' },
                      { id: 1, value: 15, label: 'series B' },
                      { id: 2, value: 20, label: 'series C' },
                    ],
                  },
                ]}
                width={chartWidth}
                height={chartHeight}
              />
          </div> */}
          <div className="col-md-6 pichart-res">
            {/* <h3 className="card-title">Total Lead</h3> */}
              <PieChart
                id="pie"
                dataSource={areas}
                palette={['#6610f2', '#dc3545']}
                onPointClick={pointClickHandler}
                onLegendClick={legendClickHandler}
              >
                <Series
                  argumentField="country"
                  valueField="area"
                >
                  <Label visible={true}>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>
                <Size />
              </PieChart>
          </div>
          <div className="col-md-6">
            <div className="card-view p-2">
              <h3 className="card-title">Products Yearly Sales</h3>
              <div className='w-100'>
                <CanvasJSChart options={ProductsYearlyoptions} />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-lg-6">
            <div className="card-view p-2">
              <h3 className="card-title">Sales Overview</h3>
              <div>
                <CanvasJSChart options={SaleslineChartoptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-view p-2">
              <h3 className="card-title">Total Sales</h3>
              <div>
                <CanvasJSChart options={totalsalesChartoptions} title="Total Sales" />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6">
            <div className="card-view p-2">
              <h3 className="card-title">Yearly Projects</h3>
              <div>
                <div>
                  <div id="chart">
                    <CanvasJSChart options={YearlyProjects} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-view p-2">
              <h3 className="card-title">Total Revenue</h3>
              <div>
                <BarChart
                  series={[
                    { data: [35, 44, 24, 34, 55, 60, 70], color: '#6610f2', label: "Total Income" },
                    { data: [51, 6, 49, 30, 45, 50, 55], color: 'pink', label: "Total Outcome" },
                  ]}
                  height={400}
                  xAxis={[{ data: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'], scaleType: 'band' }]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-md-6 p-2">
            <div className="card-view p-2">
              <h3 className="card-title">Sales Statistics</h3>
              <div className='w-100'>
                <BarChart
                  series={[
                    { data: [51, 6, 49, 30].map(value => value * 50), color: 'pink', label: "Total Cost" },
                    { data: [35, 44, 24, 34].map(value => value * 50), color: '#6610f2', label: "Total Revenue" },
                  ]}
                  height={400}
                  xAxis={[{ data: ['0', '100', '150', '200'], scaleType: 'band' }]}
                  margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 p-2">
            <div className="card-view p-2">
              <div className="card-body w-100">
                <h3 className="card-title">Completed Tasks</h3>
                <canvas id="mixed-chart" width="800" height="450"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Dashboard;