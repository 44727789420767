import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour'
import Footer from '../../_App/Footer'

const Global = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>Get result-oriented global SEO services that will assist your company in expanding its international reach and increasing sales. We can handle your Global SEO needs, providing search engine optimization for your company on both a local and global scale. Make it Active, LLC can help you increase your search engine presence and use our decades of experience to help your website.</p>
                        <p>Global SEO is an SEO strategy that helps your company become more visible in Google's global search results. Every company that has an actual location or serves a geographical area can take advantage of global SEO.</p>
                        <p>If your company sells products and services internationally, or if your website targets users all over the world, it's critical to consider your search visibility in other countries. International or global SEO is a method of increasing your rankings and visibility in other countries.</p>
                        <p>Here are four key types of SEO that you can get started on.</p>
                        <ul>
                            <li>On page-SEO - SEO Title, Meta Description, Alt+images, Link building Internal</li>
                            <li>Off-page – SEO</li>
                            <li>Keyword Analysis</li>
                            <li>Content Optimization</li>
                            <li>SEO Title and Meta Description</li>
                            <li>Backlink building</li>
                            <li>Guest posting</li>
                            <li>Google Search Console tool</li>
                            <li>Site Maps creation</li>
                            <li>Installing word press and Seo plugins.</li>
                        </ul>
                        <p>We'll concentrate on these areas:</p>
                        <ul>
                            <li>International Technical SEO</li>
                            <li>Local Audience Intent Research</li>
                            <li>International Site Structure</li>
                            <li>Competitor Analysis</li>
                            <li>Content Transcreation</li>
                            <li>On page Optimization</li>
                            <li>Link Acquisition</li>
                        </ul>
                        <p>
                            <span className="fw-bold">On Page Optimization:</span>
                            <p>On-page SEO is the process of optimising individual web pages in order to improve their search engine rankings and generate more relevant traffic.</p>
                        </p>
                        <p>
                            <span className="fw-bold">Canonicalization </span>
                            <p>Canonicalization is beneficial to SEO because it assists Google in making sense of duplicate content and reduces the risk of selecting the incorrect URL as the canonical version. Google can accurately consolidate link equity, index, and rank the main version of your content for relevant queries if you use canonical tags. It also saves crawl budget, allowing them to spend more time exploring other crucial portions of your website.</p>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Global