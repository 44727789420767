import React from "react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import Accordion from 'react-bootstrap/Accordion';
import servicesDetailsImg1 from "../../public/images/LogoSKT/Business detail.png";
import PageBanner from "../Common/PageBanner";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { Link } from "react-router-dom";
import Contactformpopup from "../Contact/Contactformpopup";
const ServiceDetailsContent = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='BUSINESS INTELLIGENCE' />

      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>Business Intelligence</h3>
                <p>
                  We help businesses to collect, clean, and analyse data, and to create data visualisations
                  that help them to make better decisions. We offer a variety of BI solutions, including:
                </p>
                {/* <h3>Dashboards</h3>
                <p>
                  Our dashboards are custom-designed to meet the specific needs of each business.
                  They provide businesses with a real-time view of their data and allow them to
                  track key metrics and identify trends and patterns.
                </p>
                <h3>Reports</h3>
                <p>
                  We create custom data visualisation reports that help businesses to communicate
                  their findings to others in a clear and concise way.
                </p>
                <h3>Consulting services</h3>
                <p>
                  We offer a variety of data consulting services, including data warehousing,
                  data mining, and data governance. We can help businesses to implement a BI
                  solution that is right for their needs and to get the most value out of their data.
                </p> */}
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Dashboards</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Our dashboards are custom-designed to meet the specific needs of each business.
                        They provide businesses with a real-time view of their data and allow them to
                        track key metrics and identify trends and patterns.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Reports</Accordion.Header>
                    <Accordion.Body>
                      <p>We create custom data visualisation reports that help businesses to communicate
                        their findings to others in a clear and concise way.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Consulting services</Accordion.Header>
                    <Accordion.Body>
                      <p>We offer a variety of data consulting services, including data warehousing,
                        data mining, and data governance. We can help businesses to implement a BI
                        solution that is right for their needs and to get the most value out of their data.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
            <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
             <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>
  );
};

export default ServiceDetailsContent;
