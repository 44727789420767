import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour'
import Footer from '../../_App/Footer'

const Ecom = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>Our E-Commerce SEO experts can help you enhance your website, mobile app, and product promotions for search engines. Automotive We can attract modern buyers with our next generation.</p>
                        <p>Skill Technologies understands that every business is unique, so we developed an inbound marketing strategy to help your customers easily find your business. Skill technologies can assist you if you are a retailer struggling to generate sales from your ecommerce website.</p>
                        <p className="fw-bold lead">Why Should A Business Owner Consider Ecommerce SEO Services?</p>
                        <p>Simply having an E-commerce site will not solve your online business problems. You should promote your website on popular search engines. Increase your website ranking to gain online visibility. This ranking can only be improved through an ongoing SEO strategy.</p>
                        <p>To expand our business in the global market we need to face the competitors in the IT industry. We analyse the 360-degreee approach to expand your business in all corners of the globe. We are build your business with trusted customers and increase your eCommerce services to reach the your target customers.</p>
                        <p>To meet end-to-end customer reach we provide the eCommerce SEO marketing in the form of eCommerce website design, email marketing, 100% campaign focus, keyword research & optimization, etc.</p>
                        <p>We create, we promote, we engage, we manage to win your market requirements in your business environment. Our company follows different strategies by providing full service of digital marketing area.</p>
                        <p>Our eCommerce SEO specialists can assist you in optimising your website and mobile app for search engines. Automotive. With our next generation, we can attract the modern buyer.</p>
                        <ul>
                            <li>Keyword Research</li>
                            <li>Implementation Of Keywords</li>
                            <li>Optimization By Page</li>
                            <li>Optimizing Navigation</li>
                            <li>Content creation</li>
                        </ul>
                        <p>Easy solutions for all difficult IT problems to ensure availability to hundreds of IT advisors, users, and business.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ecom