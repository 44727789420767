import React from "react";
import * as Icon from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from './../../public/images/LogoSKT/logo-png-skt.png'
import shape1 from './../../public/images/shape1.png'
import shape2 from './../../public/images/shape2.svg'
import map from "./../../public/images/map.png"
import { FaWhatsapp, FaPinterest } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const handleNavLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  const handleWhatsAppClick = () => {
    const phoneNumber = '9014183086'; 
    const message = 'Hello Skill Technologies, Im interested in your services and would appreciate it if you could share a quotation.Thank you!'; // Optional: Replace with your message
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank', 'noopener noreferrer');
  };
  return (
    <>
      <footer className="footer-area bg-f7fafd" style={{fontFamily:'Raleway'}}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="logo" width={150} height={45} />
                  </Link>
                </div>
                <p>
                  "At Skill Technologies, we redefine success through digital solutions like E-Commerce, Mobile Apps, Digital Marketing, and Website Design. Partner with us for innovative, results-driven strategies in the digital age."
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-in"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-footer-widget ps-5">
                <h3>Company</h3>

                <ul className="list">
                  <li>
                    <a href="/" onClick={handleNavLinkClick}>About Us</a>
                  </li>
                  <li>
                    <Link to="/services" onClick={handleNavLinkClick}>Services</Link>
                  </li>
                  <li>
                    <Link to="/projects" onClick={handleNavLinkClick}>Our Works</Link>
                  </li>
                  <li>
                    <Link to="/blog" onClick={handleNavLinkClick}>Blog</Link>
                  </li>
                  <li>
                    <Link to="/our-recognition" onClick={handleNavLinkClick}>Our Recognition</Link>
                  </li>
                  <li>
                    <Link to="/companyprofile" target="_blank" onClick={handleNavLinkClick}>Our Profile</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-footer-widget">
                <h3>Support</h3>

                <ul className="list">
                  <li>
                    <Link to="/faq" onClick={handleNavLinkClick}>FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy" onClick={handleNavLinkClick}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms" onClick={handleNavLinkClick}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/ReturnPolicy" onClick={handleNavLinkClick}>Refund and Cancellation Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={handleNavLinkClick}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6"
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-footer-widget">
                <h3>Address</h3>

                <ul className="footer-contact-info">
                  <li>
                    <Icon.MapPin />
                    #301, 3rdFloor, Plot 187, Raghavendra colony, Kondapur, Above Allen Solly Showroom, Raghavendra Colony, Kondapur, Telangana 500084.
                  </li>
                  <li>
                    <Icon.MapPin />
                    722 Grant Street, Suite G Herndon, VA 20170 USA And 1020 Elden Street, Suite 102 Herndon, VA 20170 USA
                  </li>

                  <li>
                    <Icon.Mail />
                    Email:{" "}
                    <a href="mailto:info@skilltechnologies.in">info@skilltechnologies.in</a>
                  </li>
                  <li>
                    <Icon.PhoneCall />
                    Phone: <a href="tel:+04049523129">040 49523129</a>
                  </li>
                  <li>
                    <Icon.PhoneCall />
                    Mobile: <a href="tel:9014183086">9014183086</a>
                  </li>
                </ul>
                <p className="fw-bold text-dark" style={{ fontSize: '14px' }}>Connect with us</p>
                <ul className="social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/skilltechnologies.hyd"
                      className="facebook"
                      target="_blank"
                    >
                      <Icon.Facebook />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://twitter.com/SkillTechnolog1"
                      className="twitter"
                      target="_blank"
                    >
                      <FaXTwitter />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/skill_technologies/"
                      className="instagram"
                      target="_blank"
                    >
                      <Icon.Instagram />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/53526948"
                      className="linkedin"
                      target="_blank"
                    >
                      <Icon.Linkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.pinterest.com/skilltechnologies/"
                      className="pintrest"
                      target="_blank"
                    >
                      <FaPinterest />
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:+919014183086"
                      className="whatsapp"
                      target="_blank"
                      onClick={handleWhatsAppClick}
                    >
                      <FaWhatsapp />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="copyright-area">
                <p>
                  Copyright &copy; {currentYear} Skill Technologies. All rights reserved by{" "}
                  <a href="//home" target="_blank">
                    Skill Technologies (A Unit of Infoswan Private Limited)
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <img
          src={map}
          className="map"
          alt="map"
          width={910}
          height={443}
        />

        {/* Shape Images */}
        <div className="shape1">
          <img
            src={shape1}
            alt="shape"
            width={202}
            height={202}
          />
        </div>

        <div className="shape8 rotateme">
          <img src={shape2} alt="shape" width={22} height={22} />
        </div>
      </footer>
    </>
  );
};

export default Footer;
