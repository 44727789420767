import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour';
import Footer from '../../_App/Footer';
const GoogleAdwords03 = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>
                        Online advertising enables you to target people who are likely to be interested in your products and services while excluding those who aren't. You can also see if those people clicked on your ads. Online advertising also allows you to reach potential customers who use a variety of devices, including desktops, laptops, tablets, and smart phones.
                        </p>
                        <p>Skill Technologies has been providing target-based business solutions in digital marketing for the past ten years. We are primarily focused on reaching people in diverse areas with relevant customer interests. Our digital marketing experts’ team will guide your company's goals as well as customer satisfaction.</p>
                        <p>We investigate ads on the Google Ads market to target specific areas by using keywords, ad location, age, and location.</p>
                        <p>We find Easy solutions for all difficult IT problems to ensure availability to hundreds of IT advisors, users, and business. We control your cost, we manage your campaigns, we measure your success.</p>
                        <p className='mb-5'>We value the customer trust. We deliver our services as well. We generate potential to your business.</p>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default GoogleAdwords03;