import React from 'react'
import Footer from '../../_App/Footer'
import NavbarStyleFour from '../../_App/NavbarStyleFour'
import './dm.css'
const WhySMM = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>Social media has become the most influential and important virtual space where the platform is not only used for social networking but is also a great way to digitally advertise your brand and products. The power of social media is admirable because you can reach a large number of people within seconds of posting an ad, lowering your costs and ensuring that your ads reach your target audience through these social media advertisements.</p>
                        <p>As the current trend, Social Media is working as news teller to 13 years of teenage to 60 years old. Social media marketing helps you to engage with the customer.</p>
                        <p>For an online business, social media is an essential component. Social media marketing is a branding tool that can boost conversions, sales tracking, page views, and brand awareness.</p>
                        <p className="lead fw-bold">
                        Social Media Marketing Services:
                        </p>
                        <ul>
                            <li>Developing a business page</li>
                            <li>Insight competitor analysis</li>
                            <li>Targeting Audience using Age, Demographics, Similar Interests, Gender</li>
                            <li>Facebook Campaign Ads</li>
                            <li>Analyzation and live tracking</li>
                            <li>Engaging content</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default WhySMM