import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour';
import Footer from '../../_App/Footer';

const GoogleAdwords02 = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>
                        Organizations today require a strong bench of leaders to drive business outcomes and satisfy employees, customers, and investors who are demanding greater transparency and accountability.
                        </p>
                        <p>Google AdWords is Google's online advertising programmed.</p>
                        <p>Types of campaigns:</p>
                        <ul>
                            <li>Search network with Display select</li>
                            <li>Search network</li>
                            <li>Display network</li>
                            <li>Shopping</li>
                            <li>Online video</li>
                        </ul>
                        <p>Advantages of Google AdWords:</p>

                        <ul className='mb-5'>
                            <li>Reach people searching for what you offer</li>
                            <li>Control your budget</li>
                            <li>Measurable, accountable & flexible</li>
                            <li>Cost effective</li>
                            <li>Maximum relevance</li>
                            <li>Highly Targeted</li>
                            <li>Remarketing</li>
                        </ul>
                        
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default GoogleAdwords02;