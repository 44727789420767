import React from 'react'
import NavbarStyleFour from '../_App/NavbarStyleFour'
import Footer from '../_App/Footer'
const PaymentGateway = () => {
    return (
        <>
         <NavbarStyleFour />
        <div className='text-dark text-center'>
            <div className="mt-5 pt-5">
                PaymentGateway Panding......
            </div>
        </div>
        <Footer/>
        </>
       
    )
}

export default PaymentGateway