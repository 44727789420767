import React from 'react'
import NavbarStyleFour from '../../_App/NavbarStyleFour';
import Footer from '../../_App/Footer';
const GoogleAdwords = () => {
    return (
        <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <p>
                        We use the best organic approaches to achieve the highest page rankings in the shortest amount of time. Our strategic methodology enables us to rank your company quickly. In 45 days, we met our clients' expectations.
                        </p>
                        <p>To achieve the greatest page rankings in the shortest amount of time, we employ the best organic approaches. Our strategic methodology allows us to quickly rank your company. Our clients' expectations were met in 45 days.</p>
                        <p>Google AdWords is an online advertising platform that allows you to reach a large number of customers in a short period of time. We can easily do online advertising to grow your business with the help of Google. Skill Technologies is the only one-stop shop for this service.</p>
                        <p>Online campaigns with low budgets, managing your business ads to measure your business growth in very short period.</p>
                        <p className='mb-5'>We do smart campaigns targeting to customers. Key word base campaigns, location based campaigns, age based campaigns, day based campaigns, device based campaigns etc. We show up the people search for what you offer.</p>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default GoogleAdwords;