import React from 'react'
import PageBanner from "../Common/PageBanner";
import servicesDetailsImg1 from "../../public/Digitalmarketingimgs/Websitedev.png";
import NavbarStyleFour from '../_App/NavbarStyleFour';
import Footer from '../_App/Footer';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Contactformpopup from "../Contact/Contactformpopup";
const WebsiteDevelopment = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='WEBSITE DESIGN AND DEVELOPMENT' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h2>Solutions That Drive Growth Through Digital Transformation</h2>
                <p>
                  Having an impressive UI goes much beyond capturing the attention of the user; it is capable of creating outstanding UX and building the business brand. Hence, every business needs to focus on creating immersive UI designs that drive improved UX.At Skill Technologies, we comprehend the value of user-centered designs and understand the impact these have on the user experience.
                </p>
                {/* <h3>UX Research</h3>
                <p>
                We understand the client’s requirements and analyze the business goals as well as the market trends to create a scope definition for the project.
                
                </p>
                <p>
                We specialize in creating interfaces that are aesthetically appealing, easy to navigate, and truly user-friendly.Our UI design services enable our clients to avail a scalable and robust user experience, which maximizes their ROI and promotes their branding strategy.
                </p> */}
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>UX Research</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      We understand the client’s requirements and analyze the business goals as well as the market trends to create a scope definition for the project.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Front-End Development</Accordion.Header>
                  <Accordion.Body>
                    <p>Our team comprises of skilled front-end developers who are capable of creating pixel-perfect, responsive front-end solutions</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>UX Design</Accordion.Header>
                  <Accordion.Body>
                    <p>UX design is a wide term that encompasses its visual layout, content, navigation, and features that come together to create an experience flow for the users.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Usability Testing</Accordion.Header>
                  <Accordion.Body>
                    <p>We present well-tested UI/UX designs which have been validated by the real-life users from the target industry using reliable testing tools.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>UI Design</Accordion.Header>
                  <Accordion.Body>
                    <p>Our design team excels at crafting appealing designs with the right mix of colors, typography, and illustrations, but keep user experience at the core.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
              <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
              <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>

  )
}

export default WebsiteDevelopment