import React from "react";
import logo from '../../public/images/LogoSKT/SKT-LOGO-DARK.png';
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import Contactformpopup from "../Contact/Contactformpopup";
const SignUpForm = () => {
  return (
    <>
    <NavbarStyleFour />
      <div  style={{marginTop:"100px"}}>
        <div className="container">
          <div className="auth-form">
            <div className="auth-head">
              <a href="/">
                <img src={logo} width={110} height={36} />
              </a>
              <p>Create a new account</p>
            </div>

            <form>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="ConfirmPassword"
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>
            </form>

            <div className="foot">
              <p>
                Already have an account yet? <a href="/login">Login</a>
              </p>
            </div>
          </div>
        </div>
        <Contactformpopup />
      </div>
      <Footer />
    </>
  );
};

export default SignUpForm;
