import React from 'react'
import Footer from '../../_App/Footer'
import NavbarStyleFour from '../../_App/NavbarStyleFour'

const WearebestSmm = () => {
  return (
    <div style={{ marginTop: '100px' }}>
            <NavbarStyleFour />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h2 className="fw-bold text-center mt-5">OverView</h2>
                    </div>
                    <div className="col-md-8 mt-5">
                        <ul>
                            <li>Developing a business page</li>
                            <li>Insight competitor analysis</li>
                            <li>Targeting Audience using Age, Demographics, Similar Interests, Gender</li>
                            <li>Facebook Campaign Ads</li>
                            <li>Analyzation and live tracking</li>
                            <li>Engaging content</li>
                        </ul>
                        <p>
                        You need information security services to proactively prevent and protect confidential data, as well as detect internal and external threats. Protect your business by choosing managed security service provider. Our security services can help ensure your business Trusted by the world's best organizations.
                        </p>
                        <p>for 15 years and running, it has been delivering smiles to hundreds of IT advisors, developers, users, and business owners. Easy solutions for all difficult IT problems</p>
                        <p>Provide users with appropriate view and access permissions to requests, problems, changes, contracts, assets, solutions, and reports with our experienced professionals.</p>
                        <p>Easy solutions for all difficult IT problems to ensure availability to hundreds of IT advisors, users, and business</p>
                        <table className='dm-table frontend-table'>
                            <thead>
                                <tr>
                                    <th>Client</th>
                                    <th>Industry</th>
                                    <th>Services</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Optime Logistics</td>
                                    <td>Cargo, Logistics, Transportation, Trucking, Shipping</td>
                                    <td>Consulting, Management, Security, Support</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
  )
}

export default WearebestSmm