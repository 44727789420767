import React, { useState } from 'react'
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import { FaEye } from "react-icons/fa";

const MyPost = () => {
    const [mypostJson, setMypostjson] = useState([
        {
            dateofpost: '2024-05-14',
            jobtitle: 'Executive',
            positionlevel: 'Junior',
            experience: 'fresher',
            jobtype: 'full-time',
            prefereddateofjoin: 'immediately'
        },
        {
            dateofpost: '2024-05-15',
            jobtitle: 'Software Engineer',
            positionlevel: 'Junior',
            experience: '1 year',
            jobtype: 'full-time',
            prefereddateofjoin: '2024-06-01'
        },
        {
            dateofpost: '2024-05-16',
            jobtitle: 'Data Analyst',
            positionlevel: 'Entry-level',
            experience: 'fresher',
            jobtype: 'part-time',
            prefereddateofjoin: 'immediately'
        },
        {
            dateofpost: '2024-05-17',
            jobtitle: 'Project Manager',
            positionlevel: 'Mid-level',
            experience: '5 years',
            jobtype: 'contract',
            prefereddateofjoin: '2024-07-01'
        },
        {
            dateofpost: '2024-05-18',
            jobtitle: 'Graphic Designer',
            positionlevel: 'Senior',
            experience: '3 years',
            jobtype: 'freelance',
            prefereddateofjoin: '2024-05-20'
        },
        {
            dateofpost: '2024-05-19',
            jobtitle: 'HR Specialist',
            positionlevel: 'Mid-level',
            experience: '2 years',
            jobtype: 'full-time',
            prefereddateofjoin: '2024-06-15'
        },
        {
            dateofpost: '2024-05-20',
            jobtitle: 'Marketing Coordinator',
            positionlevel: 'Entry-level',
            experience: 'fresher',
            jobtype: 'internship',
            prefereddateofjoin: 'immediately'
        },
        {
            dateofpost: '2024-05-21',
            jobtitle: 'Financial Analyst',
            positionlevel: 'Junior',
            experience: '1 year',
            jobtype: 'full-time',
            prefereddateofjoin: '2024-07-01'
        },
        {
            dateofpost: '2024-05-22',
            jobtitle: 'Sales Representative',
            positionlevel: 'Mid-level',
            experience: '3 years',
            jobtype: 'full-time',
            prefereddateofjoin: '2024-06-01'
        },
        {
            dateofpost: '2024-05-23',
            jobtitle: 'Content Writer',
            positionlevel: 'Senior',
            experience: '4 years',
            jobtype: 'part-time',
            prefereddateofjoin: 'immediately'
        }
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [editIndex, setEditIndex] = useState(null);
    // Calculate the displayed items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = mypostJson.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleDelete = (index) => {
        const updateTable = mypostJson.filter((item, ind) => index !== ind)
        setMypostjson(updateTable)
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenView, setIsModalOpenview] = useState(false);
    const [alignToCenter, setAlignToCenter] = useState(true);
    const toggleAlignment = () => {
        setAlignToCenter((prevAlignToCenter) => !prevAlignToCenter);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const modalStyle = {
        content: {
            position: 'absolute',
            top: alignToCenter ? '50%' : 'auto',
            right: alignToCenter ? '50%' : 0,
            bottom: alignToCenter ? '50%' : 0,
            left: alignToCenter ? '50%' : 'auto',
            transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
            margin: 0,
            height: alignToCenter ? '80%' : '80%',
            width: alignToCenter ? '40%' : '30%', // Adjusted width when centered
            borderRadius: '10px',
        },
    };
    const [formData, setFormData] = useState({
        dateofpost: '',
        jobtitle: '',
        positionlevel: '',
        experience: '',
        jobtype: '',
        prefereddateofjoin: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const newPost = { ...formData };
        // Check if it's an edit or a new post
        if (editIndex !== null) {
            // If it's an edit, update the existing post
            const updatedPosts = mypostJson.map((post, index) =>
                index === editIndex ? newPost : post
            );
            setMypostjson(updatedPosts);
        } else {
            // If it's a new post, add it to the existing posts
            setMypostjson([...mypostJson, newPost]);
        }
        setIsModalOpen(false);
        // Reset form data after submission
        setFormData({
            dateofpost: '',
            jobtitle: '',
            positionlevel: '',
            experience: '',
            jobtype: '',
            prefereddateofjoin: ''
        });
        // Reset editIndex after submission
        setEditIndex(null);
    };
    const handleEdit = (index) => {
        setEditIndex(index);
        const selectedPost = mypostJson[index];
        setFormData(selectedPost);
        setIsModalOpen(true);
    };
    const [viewData, setViewData] = useState(null);
    const handleViewopenmodal = (index) => {
        setViewData(mypostJson[index]);
        setIsModalOpenview(true);
    };
    const closeModalview = () => {
        setIsModalOpenview(false);
    };
    return (
        <div>
            <h3 className="text-center text-dark fw-bold mt-5">View Post</h3>
            <table className='myjobtable'>
                <thead>
                    <tr>
                        <td>Job Title</td>
                        <td>Position Level</td>
                        <td>Experience Type</td>
                        <td>Job Type</td>
                        <td>Experience Required</td>
                        <td>Prefered Date Of Joining</td>
                        <td>Date Of Post</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((job, index) => (
                        <tr key={index}>
                            <td>{job.jobtitle}</td>
                            <td>{job.positionlevel}</td>
                            <td>{job.experience}</td>
                            <td>{job.jobtype}</td>
                            <td>{job.experience}</td>
                            <td>{job.prefereddateofjoin}</td>
                            <td>{job.dateofpost}</td>
                            <td className='d-flex'>
                                <FaEye style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleViewopenmodal(index)} />
                                <FaEdit onClick={() => handleEdit(index)} style={{ cursor: 'pointer', marginRight: '10px' }} />
                                <FaTrash onClick={() => handleDelete(index)} style={{ cursor: 'pointer', marginRight: '10px' }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={mypostJson.length}
                paginate={paginate}
                currentPage={currentPage}
            />
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Add Post Modal"
                id='modal-res'
                overlayClassName="Overlay zindexpp fade-in zindexpp"
                style={modalStyle}
            >
                <>
                    <a onClick={closeModal} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
                        <div className="cross" style={{ height: '20px', width: '20px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                        </div>
                    </a>
                    <h3 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Edit Post</h3>
                    <form onSubmit={handleFormSubmit}>
                        <label>Date Of Post:</label>
                        <input type="text" className='form-control' name="dateofpost" value={formData.dateofpost} onChange={handleInputChange} />

                        <label>Job Title:</label>
                        <input type="text" className='form-control' name="jobtitle" value={formData.jobtitle} onChange={handleInputChange} />

                        <label>Position Level:</label>
                        <input type="text" className='form-control' name="positionlevel" value={formData.positionlevel} onChange={handleInputChange} />

                        <label>Experience:</label>
                        <input type="text" className='form-control' name="experience" value={formData.experience} onChange={handleInputChange} />

                        <label>Job Type:</label>
                        <input type="text" className='form-control' name="jobtype" value={formData.jobtype} onChange={handleInputChange} />

                        <label>Preferred Date Of Joining:</label>
                        <input type="text" className='form-control' name="prefereddateofjoin" value={formData.prefereddateofjoin} onChange={handleInputChange} />

                        <button type="submit" className='btn btn-primary mt-2'>Submit</button>
                    </form>
                </>
            </Modal>
            {/* view Modal */}
            <Modal
                isOpen={isModalOpenView}
                onRequestClose={closeModalview}
                contentLabel="View Post Modal"
                id='modal-res-view'
                overlayClassName="Overlay zindexpp fade-in zindexpp"
                style={modalStyle}
            >
                <>
                    <a onClick={closeModalview} className="d-flex justify-content-end mt-1" style={{ marginTop: '-20px' }}>
                        <div className="cross" style={{ height: '20px', width: '20px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                        </div>
                    </a>
                    <h3 className="text-center bg-theme text-white mt-2 pt-1 pb-1">Add Customer</h3>
                    {/* Display all properties of viewData */}
                    {viewData && (
                        <div className='mt-4'>
                            {/* <h3 className="text-center bg-theme text-white mt-2 pt-1 pb-1">View Post</h3>
                            {Object.keys(viewData).map((key, index) => (
                                <p key={index}>
                                    {key.replace(/([A-Z])/g, ' $1').trim()}: {viewData[key]}
                                </p>
                            ))} */}
                            <p className="field">Date Of Post: <span>{viewData.dateofpost}</span></p>
                            <p className="field">Job Title: <span>{viewData.jobtitle}</span></p>
                            <p className="field">Position Level: <span>{viewData.positionlevel}</span></p>
                            <p className="field">Experience: <span>{viewData.experience}</span></p>
                            <p className="field">Job Type: <span>{viewData.jobtype}</span></p>
                            <p className="field">Preferred Date Of Joining: <span>{viewData.prefereddateofjoin}</span></p>

                        </div>
                    )}
                </>
            </Modal>

        </div>
    )
}
const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='d-flex justify-content-center'>
            <ul className='pagination '>
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className='page-link '>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default MyPost