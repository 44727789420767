import React, { useState } from 'react'
import { CgProfile } from "react-icons/cg";
import './myprofile.css'
import { FaPencil } from "react-icons/fa6";
import { CiMenuKebab } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { GoArrowUpLeft, GoArrowDownRight } from "react-icons/go";

const Myprofile = () => {
  const navigate = useNavigate()
  const [image, setImage] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const [userData, setUserData] = useState({
    phone: '9178027896',
    email: 'prasad.mvrv@skilltechnologies.com',
    birthday: '24th July',
    address: '232, Kavuri Hills, Phose-II,Madhapur,Hydrabad-33',
    gender: 'Male',
    reportsTo: 'Prasad MVRV'
  });


  const [uselftdata, setuserlftdata] = useState({
    cin: '9178027896',
    gstin: '809384098',
    tel: '9084059840',
    pan: 'dlfjlas',
    unitname: 'XYZ',
    unitaddress: 'Kondapur',
    suppunbord: '100+',
    costusingapp: '56+'
  });

  const [PersonalInfoOpenModal, setPersonalInfoModel] = useState(false)
  const onHandleSubmit = (e) => {
    e.preventDefault();
    updateUserData(userData);
  };
  const updateUserData = (newData) => {
    setUserData(newData);
  };
  const [supportinfoModal, setSupportInfoModal] = useState(false)
  const onHandleSubmit2 = (e) => {
    e.preventDefault();
    updateUserData(userData);
    setPersonalInfoModel(false);
  };

  const supportInfo = {
    primary: 'some person',
    name: 'XYZ',
    empid: '80948',
    phone: '084509834',
    secname: 'BGT',
    secempid: '80409',
    secphone: '830948328'
  }
  // new modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alignToCenter, setAlignToCenter] = useState(false);
  const toggleAlignment = () => {
    setAlignToCenter((prevAlignToCenter) => !prevAlignToCenter);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const modalStyle = {
    content: {
      position: 'absolute',
      top: alignToCenter ? '50%' : 'auto',
      right: alignToCenter ? '50%' : 0,
      bottom: alignToCenter ? '50%' : 0,
      left: alignToCenter ? '50%' : 'auto',
      transform: alignToCenter ? 'translate(-50%, -50%)' : 'translate(0, 0)',
      margin: 0,
      height: alignToCenter ? '80%' : '80%',
      width: alignToCenter ? '40%' : '30%', // Adjusted width when centered
      borderRadius: '10px',
    },
  };
  return (
    <>
      {/* Saved Data In This field  */}
      <>
        <div>
          <div className="d-flex justify-content-between admin-profile-row profile-res costomContainer">
            <h6 className='d-flex mt-3'><span><CgProfile className='text-white p-1 rounded mr-3 rem-m' style={{ width: '40px', height: '40px', backgroundColor: 'black' }} /></span> <p className='mt-2'>Admin Profile</p></h6>
            <p className='mt-3'><span className='text-primary cursor-pointer' onClick={() => navigate('/admin/dashboard')}>Dashboard /</span> My Profile</p>
          </div>
        </div>
        <div className='row mt-3 costomContainer' style={{ textAlign: 'left' }}>
          <div className="col-md-6  left-pro">
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-md-6">
                <div className='res-margin-0'>
                  <label htmlFor="adminimageInput">
                    <div
                      style={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      {image ? (
                        <img
                          src={image}
                          alt="Selected Avatar"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#ddd',
                            color: '#666',
                          }}
                        >
                          <span>Choose</span>
                        </div>
                      )}
                    </div>
                  </label>
                  <input
                    id="adminimageInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className='d-flex'><h5>Prasad M </h5>  <p className='editUser-min profile-icon-hover ms-3' onClick={() => setPersonalInfoModel(true)}><FaPencil onClick={openModal} /></p></div>
                <p className="text-sm">Admin</p>
                <p>Unit ID : SKT-103</p>
                <p>Date of Join : 1st Jan 2020</p>
                <button className="btn btn-primary mt-3 zindex--">Send Message</button>
              </div>
            </div>
          </div>
          <div className="col-md-6 right-pro res-mt">
            <div className="row res-mt">
              <div className="col-4">
                <p>Phone:</p>
                <p>Email:</p>
                <p>Birthday:</p>
                <p>Address:</p>
                <p>Gender:</p>
                <p>Reports to:</p>
              </div>
              <div className="col-8">
                <p className="text-primary">{userData.phone}</p>
                <p className='text-primary font-v-sm-res'>{userData.email}</p>
                <p>{userData.birthday}</p>
                <p className='font-v-sm-res'>{userData.address}</p>
                <p>{userData.gender}</p>
                <p className='d-flex'><span>{userData.reportsTo}</span></p>
              </div>
            </div>
            <p className='editUser profile-icon-hover' onClick={() => setPersonalInfoModel(true)}><FaPencil onClick={openModal} /></p>
          </div>
        </div>
      </>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <p>CIN No:</p>
                    <p>GSTIN:</p>
                    <p>Tel:</p>
                    <p>PAN:</p>
                    <p>Unit Name:</p>
                    <p>Unit Address:</p>
                    <p>Suppliers Onboarded:</p>
                    <p>Customers Using App:</p>
                  </div>
                  <div className="col-6">
                    <p>{uselftdata.cin}</p>
                    <p>{uselftdata.gstin}</p>
                    <p className='text-primary'>{uselftdata.tel}</p>
                    <p>{uselftdata.pan}</p>
                    <p>{uselftdata.unitname}</p>
                    <p>{uselftdata.unitaddress}</p>
                    <p>{uselftdata.suppunbord}</p>
                    <p>{uselftdata.costusingapp}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Primary:</h5>
                <div className="row">
                  <div className="col-6">
                    <p>Name:</p>
                    <p>EMP ID:</p>
                    <p>Phone:</p>
                  </div>
                  <div className="col-6">
                    <p>some person</p>
                    <p>XYZ</p>
                    <p>80948</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">Secondary:</h5>
                <div className="row">
                  <div className="col-6">
                    <p>Name:</p>
                    <p>EMP ID:</p>
                    <p>Phone:</p>
                  </div>
                  <div className="col-6">
                    <p>BGT</p>
                    <p>80409</p>
                    <p>830948328</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* Model Code  */}
      {alignToCenter && isModalOpen && (
        <div
          className="Overlay"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black with 50% opacity
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          onClick={closeModal} // Close modal if clicked outside
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add User Modal"
        id='modal-res'
        overlayClassName="Overlay fade-in"
        style={modalStyle}
      >
        <>
          <a onClick={toggleAlignment}>{alignToCenter ? <GoArrowDownRight className='arrow-icon' /> : <GoArrowUpLeft className='arrow-icon' />}</a>
          <a onClick={closeModal} className="d-flex justify-content-end" style={{ marginTop: '-20px' }}>
            <div className="cross" style={{ height: '20px', width: '20px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </div>
          </a>
          <h3 className="text-center">Edit Profile</h3>
          <form onSubmit={onHandleSubmit2}>
            <label htmlFor="phone">secondary phone</label>
            <input type="text" className='form-control w-100' name="phone" />
            <label htmlFor="primary">Name</label>
            <input type="text" className='form-control w-100' name="primary" />
            <label htmlFor="Name">Email</label>
            <input type="email" className='form-control w-100' name="Name" />
            <label htmlFor="phone">DOB</label>
            <input type="text" className='form-control w-100' name="phone" />
            <label htmlFor="secname">Address</label>
            <input type="text" className='form-control w-100' name="phone" />
            <label htmlFor="empid">Gender</label>
            <input type="text" className='form-control w-100' name="empid" />
            <div className="flex justify-end mt-4">
              <button className='btn btn-success me-2' type="submit">Submit</button>
              <button className='btn btn-danger' onClick={() => setSupportInfoModal(false)}>Cancel</button>
            </div>
          </form>
        </>
      </Modal>
    </>
  )
}

export default Myprofile