import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Pagination, Autoplay } from "swiper/modules";
import teamImg1 from "./../../public/images/team-image/prasad.jpg";
import teamImg8 from "./../../public/images/team-image/AndeyNaveen.jpeg";
import teamImg2 from "./../../public/images/team-image/swetha.PNG";
import teamImg3 from "./../../public/images/team-image/vikas.png";
import teamImg4 from "./../../public/images/team-image/ankit.jpeg";
import teamImg5 from "./../../public/images/team-image/saikiran.jpeg";
import teamImg6 from "./../../public/images/team-image/satish.jpeg";
import teamImgUha from "./../../public/images/team-image/uha.jpeg";
import teamImg7 from "./../../public/images/team-image/saikumar.jpeg";
import Niharika from './../../public/images/team-image/Niharika.png';
import Sunil from '../../public/images/team-image/Sunil.png'
import Srujana from '../../public/images/team-image/Srujana.jpg'

const teamData = [
  {
    name: "Manchikanti VRV Prasad",
    role: "CEO & Founder",
    imgSrc: teamImg1,
  },
  {
    name: "Naveen Andey",
    role: "Director - Customer Success",
    imgSrc: teamImg8,
  },
  {
    name: "Swetha",
    role: "Director/Head Operations",
    imgSrc: teamImg2,
  },
  {
    name: "Kommana Vikas",
    role: "Software Developer",
    imgSrc: teamImg3,
  },
  {
    name: "Ankit Kumar",
    role: "Software Developer",
    imgSrc: teamImg4,
  },
  {
    name: "Satish T",
    role: "Software Developer",
    imgSrc: teamImg6,
  },
  {
    name: "Sunil Kumar K",
    role: "Software Developer",
    imgSrc: Sunil,
  },
  {
    name: "Saikiran",
    role: "Digital Marketing Executive",
    imgSrc: teamImg5,
  },
  {
    name: "Srujana P",
    role: "Business Development Executive",
    imgSrc: Srujana,
  },
];

const Team = () => {

  return (
    <>
      <div className="team-area ptb-80 bg-f9f6f6 container" id="team">
        <div className="container">
          <div className="section-title">
            <h2>Our Awesome Team</h2>
            <div className="bar"></div>
            <p>
              Unlocking Potential, Delivering Excellence: Together, We Make IT Happen!
            </p>
          </div>
        </div>
        <Swiper
          spaceBetween={30}
          pagination={{ clickable: true }}
          autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
          breakpoints={{
            0: { slidesPerView: 1 },
            576: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
            1500: { slidesPerView: 5 },
          }}
          modules={[Pagination, Autoplay]}
          className="team-slider"
        >
          {teamData.map((member, index) => (
            <SwiperSlide key={index}>
              <div className="single-team">
                <div className="team-image">
                  <img
                    src={member.imgSrc}
                    alt={`${member.name}`}
                    width={125}
                    height={125}
                  />
                </div>
                <div className="team-content">
                  <div className="team-info">
                    <h3>{member.name}</h3>
                    <span>{member.role}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Team;
