import React, { useState } from "react";
import PageBanner from './../Common/PageBanner'
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
import { LuAlertTriangle } from "react-icons/lu";
import { GoCheckCircleFill } from "react-icons/go";

const PayDues = () => {
  const [fetch, setFetch] = useState(false)
  const [yesChecked, setYesChecked] = useState(false);
  const [noChecked, setNoChecked] = useState(false);

  const handleYesChange = () => {
    setYesChecked(!yesChecked);
    if (noChecked) {
      setNoChecked(false);
    }
  };

  const handleNoChange = () => {
    setNoChecked(!noChecked);
    if (yesChecked) {
      setYesChecked(false);
    }
  };
  const FetchFields = () => {
    if (fetch === false) {
      setFetch(true)
    }
  }
  // form validation
  const [orderId, setOrderId] = useState('');
  const [startDt, setStartDt] = useState('');
  const [program, setProgram] = useState('');
  const [service, setService] = useState('');
  const [endDate, setEndDate] = useState('');
  const [yesChecked2, setYesChecked2] = useState(false);
  const [noChecked2, setNoChecked2] = useState(false);
  const [orderValue, setOrderValue] = useState('');
  const [paid, setPaid] = useState('');
  const [due, setDue] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [alert, setAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (
  //     orderId.trim() === '' ||
  //     startDt.trim() === '' ||
  //     program.trim() === '' ||
  //     service.trim() === '' ||
  //     endDate.trim() === '' ||
  //     (!yesChecked && !noChecked) ||
  //     orderValue.trim() === '' ||
  //     paid.trim() === '' ||
  //     due.trim() === '' ||
  //     paymentType.trim() === ''
  //   ) {
  //     setSuccessAlert(false)
  //     setAlert(true)
  //   } else {
  //     setAlert(false)
  //     setSuccessAlert(true)

  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation
    if (
      orderId.trim() === '' ||
      startDt.trim() === '' ||
      program.trim() === '' ||
      service.trim() === '' ||
      endDate.trim() === '' ||
      // (!yesChecked && !noChecked) ||
      // orderValue.trim() === '' ||
      paid.trim() === '' ||
      due.trim() === '' ||
      paymentType.trim() === ''
    ) {
      setSuccessAlert(false);
      setAlert(true);
    } else {
      setAlert(false);
      setSuccessAlert(true);
    }
  };
  // half payment
  const [dueh, setDueh] = useState('');
  const [paymentTypeh, setPaymentTypeh] = useState('full');
  const [showTextBox, setShowTextBox] = useState(false);

  const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
    if (type === 'part') {
      setShowTextBox(true);
    } else {
      setShowTextBox(false);
    }
  };
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle={'Pay Dues'} />
      <div className="checkout-area ptb-80">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="billing-details">
                  <h3 className="title">Billing Details</h3>
                  <div className="row">
                    <div>
                      <div className="d-flex">
                        <div>
                          <label htmlFor="orderid">Order Id</label>
                          <input type="text" className="form-control w-100" placeholder="Please Provide OrderID" />
                        </div>
                        <button className="btn btn-primary h-25 ms-3" style={{ marginTop: '35px' }} onClick={FetchFields}>Get Data</button>
                      </div>
                      {
                        fetch && (
                          <>
                            {/* {
                              alert && (
                                <div className="alert alert-danger d-flex align-items-center mt-2 res-width" role="alert">
                                  <LuAlertTriangle className="text-danger lead me-2" />
                                  <div>
                                    Please Fill All Fields
                                  </div>
                                </div>
                              )
                            }
                            {
                              successAlert && (
                                <div className="alert alert-success d-flex align-items-center mt-2 res-width" role="alert">
                                  <GoCheckCircleFill className="text-success lead me-2" />
                                  <div>
                                    Form Submitted Successfully
                                  </div>
                                </div>
                              )
                            }
                            <form onSubmit={handleSubmit}>
                              <p className="fw-bold">Order Details</p>
                              <div className="d-flex flex-wrap contact-from">
                                <div>
                                  <label htmlFor="orderId">Order ID</label>
                                  <input
                                    type="text"
                                    id="orderId"
                                    className="form-control"
                                    value={orderId}
                                    onChange={(e) => setOrderId(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="startDt">Start Date</label>
                                  <input
                                    type="date"
                                    id="startDt"
                                    className="form-control"
                                    value={startDt}
                                    onChange={(e) => setStartDt(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="program">Services</label>
                                  <select name="" id="" className="form-select w-100">
                                    <option>Basic Website</option>
                                    <option>Wordpress Development</option>
                                    <option value="">Application Development</option>
                                    <option value="">Ecommerce Website</option>
                                    <option value="">Social Media Marketing</option>
                                    <option value="">SEO Services</option>
                                    <option value="">Youtube Marketing</option>
                                    <option value="">Other Services</option>
                                  </select>
                                </div>
                                <div>
                                  <label htmlFor="service">Service Type</label>
                                  <input
                                    type="text"
                                    id="service"
                                    className="form-control"
                                    value={service}
                                    onChange={(e) => setService(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="endDate">End Date</label>
                                  <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <label htmlFor="orderValue">Order Value</label>
                                  <input
                                    type="text"
                                    id="orderValue"
                                    className="form-control w-100"
                                    value={orderValue}
                                    onChange={(e) => setOrderValue(e.target.value)}
                                  />
                                </div>
                                <div style={{marginLeft:'20px'}}>
                                  <label htmlFor="paid">Amount Paid</label>
                                  <input
                                    type="text"
                                    id="paid"
                                    className="form-control w-100 ml-3"
                                    value={paid}
                                    onChange={(e) => setPaid(e.target.value)}
                                  />
                                </div>
                                <div className="me-2">
                                  <label htmlFor="due">Due Amount</label>
                                  <input
                                    type="text"
                                    id="due"
                                    className="form-control w-100 ms-2"
                                    value={due}
                                    onChange={(e) => setDue(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="d-flex me-2">
                                <div className="d-flex align-items-center mt-5">
                                  <input
                                    type="radio"
                                    name="paymentType"
                                    id="fullPayment"
                                    checked={paymentType === 'full'}
                                    onChange={() => handlePaymentTypeChange('full')}
                                  />
                                  <label htmlFor="fullPayment" className="me-2 mt-1">Pay Full</label>
                                </div>
                                <div className="d-flex align-items-center mt-5">
                                  <input
                                    type="radio"
                                    name="paymentType"
                                    id="partPayment"
                                    checked={paymentType === 'part'}
                                    onChange={() => handlePaymentTypeChange('part')}
                                  />
                                  <label htmlFor="partPayment" className="me-2 mt-1">Part Payment</label>
                                  <div>
                                    {showTextBox && (
                                      <input
                                        type="text"
                                        id="partialAmount"
                                        className="form-control w-100"
                                        placeholder="Enter Partial Amount"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <button type="submit" className="btn btn-primary mt-3">
                                Pay Now
                              </button>
                            </form> */}
                            <h1 className="text-center mt-5 fw-bold">No Record Found</h1>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PayDues;
