import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";


import icon1 from "../../public/images/icon4.png";
import icon2 from "../../public/images/icon5.png";
import icon3 from "../../public/images/icon6.png";
import icon4 from "../../public/images/icon7.png";
import icon5 from "../../public/images/icon4.png";
import icon6 from "../../public/images/icon5.png";
import icon7 from "../../public/images/icon6.png";
import icon8 from "../../public/images/icon7.png";

import shape1 from "../../public/images/shape1.png";
import shape2 from "../../public/images/shape2.svg";
import shape3 from "../../public/images/shape3.svg";
import shape4 from "../../public/images/shape4.svg";
import shape5 from "../../public/images/shape5.png";
import PageBanner from '../Common/PageBanner'
import { FiPlusCircle } from "react-icons/fi";
import '../../styles/style.css';

const Services = () => {
  const handleScrolltop=()=>{
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className="bigdata-services-area bg-eef6fd mb-5">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <div className="bar"></div>
            <p>
              Empowering Your Digital Journey: Transforming Ideas into Innovative Solutions.
            </p>
          </div>
          <div className="solutions-area ptb-80">
        <div className="container">
          <div className="row justify-content-center">

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon1} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/websitedevelopment">Website Design & Development</a>
                </h3>
                <p>
                  We provide comprehensive digital marketing services to help businesses reach their target audience and achieve their marketing goals effectively.
                </p>

                <a href="/websitedevelopment" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon4} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/applicationdevelopment">Application Development</a>
                </h3>
                <p>
                  Mobile Application Development: We create custom mobile apps tailored to your needs, ensuring seamless functionality and exceptional user experience.
                </p>

                <a href="/applicationdevelopment" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon3} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/digitalmarketing">Digital Marketing</a>
                </h3>
                <p>
                  We provide comprehensive digital marketing services to help businesses reach their target audience and achieve their marketing goals effectively.
                </p>

                <a href="/digitalmarketing" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon4} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/e-commerce">E-Commerce Applications</a>
                </h3>
                <p>
                You need information security services to proactively prevent and protect confidential data, as well as detect internal and external threats. Protect your business by choosing managed security service provider. 
                </p>

                <a href="/e-commerce" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon5} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/processexcellence">PROCESS EXCELLENCE</a>
                </h3>
                <p>
                  Lean & Six Sigma Specialists reduce costs, improve quality, enhance efficiency, shorten lead times, and boost satisfaction.
                </p>

                <a href="/processexcellence" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon6} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/dataengineering">DATA ENGINEERING</a>
                </h3>
                <p>
                  Skill Technologies provides engineering services for designing, developing, and deploying data-driven solutions across all business scales.
                </p>

                <a href="/dataengineering" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon7} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/managedservices">MANAGED SERVICES</a>
                </h3>
                <p>
                  Skill Technologies provides businesses with engineering services for efficient implementation of data-centric solutions across operational scales.
                </p>

                <a href="/managedservices" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <img src={icon8} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  <a href="/businessintelegence">BUSINESS INTELLIGENCE</a>
                </h3>
                <p>
                  We aid businesses in data collection, cleaning, analysis, and creating visualizations for better decision-making.
                </p>

                <a href="/businessintelegence" onClick={handleScrolltop} className="learn-more-btn">
                  <FiPlusCircle /> Learn More
                </a>
              </div>
            </div>

          </div>
        </div>

        {/* Shape Images */}
        <div className="shape1">
          <img src={shape1} alt="shape" width={202} height={202} />
        </div>
        <div className="shape2 rotateme">
          <img src={shape2} alt="shape" width={22} height={22} />
        </div>
        <div className="shape3">
          <img src={shape3} alt="shape" width={28} height={28} />
        </div>
        <div className="shape4">
          <img src={shape4} alt="shape" width={21} height={20} />
        </div>
        <div className="shape5">
          <img src={shape5} alt="shape" width={182} height={146} />
        </div>
        <div className="shape6 rotateme">
          <img src={shape4} alt="shape" width={21} height={20} />
        </div>
        <div className="shape7">
          <img src={shape4} alt="shape" width={21} height={20} />
        </div>
        <div className="shape8 rotateme">
          <img src={shape2} alt="shape" width={22} height={22} />
        </div>
      </div>
        </div>
      </div>
    </>
  );
};

export default Services;
