import React, { useState, useEffect } from "react";

import Halohues from '../../public/images/ProjectsImages/Halohues.png'
import Elimalai from '../../public/images/ProjectsImages/Elamulai.png'
import Nakshtra from '../../public/images/ProjectsImages/nakstra.png'
import Sarwall from '../../public/images/ProjectsImages/Sarwall.png'
import IshaKids from '../../public/images/ProjectsImages/IshaKids.png'
import Ahios from '../../public/images/ProjectsImages/Ashios.png'
import MandM from '../../public/images/ProjectsImages/MandM.PNG'
import tribanga from  '../../public/images/ProjectsImages/tribanga.png'
import Elite from '../../public/images/ProjectsImages/EliteMoversandPackers.png'
import QuickBucks from '../../public/images/ProjectsImages/Quickbucks.png'
import Swiper from "swiper";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import PageBanner from '../Common/PageBanner'

import '../../styles/style.css'

export default function WebDev() {


  const [slider, setSlider] = useState(null);
  useEffect(() => {
    if (!slider) {
      const brandSlider = new Swiper('#serviceSlider1', {
        breakpoints: {
          0: { slidesPerView: 1 },
          576: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          992: { slidesPerView: 2 },
          1200: { slidesPerView: 3 },
        },
        loop: true,
      });
      setSlider(brandSlider);
    }
  }, [slider]);

  useEffect(() => {
    const slideNextWithDelay = () => {
      if (slider) {
        setTimeout(() => {
          slider.slideNext();
          slideNextWithDelay();
        }, 2000);
      }
    };

    slideNextWithDelay();

    return () => clearTimeout();
  }, [slider]);

  const slideToPrev = () => {
    if (slider) {
      slider.slidePrev();
    }
  };

  const slideToNext = () => {
    if (slider) {
      slider.slideNext();
    }
  };

  
 
  return (
    <>
   
      <section class="bg-top-center space " style={{ heigh: '150px', width: '100%', paddingBottom: '50px', paddingLeft: '80px', paddingRight: '50px' }}>
        <div>
          <div class="title-area text-center">
            <h3 class="sub-title">Website Design and Development</h3>
          </div>
          <div class="slider-area">
            <div class="swiper th-slider has-shadow" id="serviceSlider1" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"2"},"1200":{"slidesPerView":"3"}}}'>
              <div class="swiper-wrapper">

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1' }}>
                  <div className="single-ml-projects-box">
                    <img src={Halohues} alt="image" width={850} height={850} />
                    <div className="plus-icon">
                      <a href="https://halohuesstudios.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={Sarwall} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://dev.skilltechnologies.in/Sarwall/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={Elimalai} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://dev.skilltechnologies.in/Elumalai/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={Nakshtra} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://dev.skilltechnologies.in/nakshatra/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={IshaKids} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://ishakidsclinic.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={Ahios} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://theashios.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={MandM} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="https://murtyandmanyam.com/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" style={{marginLeft:'10px', zIndex:'1'}}>
                <div className="single-ml-projects-box">
                    <img src={tribanga} alt="image" width={800} height={850} />
                    <div className="plus-icon">
                      <a href="http://dev.skilltechnologies.in/Interior/">
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button onClick={slideToPrev} className="slider-arrow slider-prev" id="serviceSliderPrev">
              <i><FaArrowLeft className="" /></i>
            </button>
            <button onClick={slideToNext} className="slider-arrow slider-next" id="serviceSliderNext">
              <i><FaArrowRight className="" /></i>
            </button> */}
          </div>
        </div>
      </section>
    </>

  );
}


