import React from 'react'
import PageBanner from "../Common/PageBanner";
import servicesDetailsImg1 from "../../public/images/Whatwedo/DigitalMarketingimage.jpg";
import NavbarStyleFour from '../_App/NavbarStyleFour';
import Footer from '../_App/Footer';
import { useNavigate, Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Contactformpopup from "../Contact/Contactformpopup";
const DigitalMarketing = () => {
  const navigate = useNavigate()
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='DIGITAL MARKETING' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>Best Digital Marketing Services</h3>
                <p>
                  With a leading digital marketing agencies in hyderabad, increase your internet visibility. Use customized SEO to increase reach. Go here to find internet-based solutions.
                  <br /> Our services includes:
                </p>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Search Engine Optimization (SEO)</Accordion.Header>
                    <Accordion.Body>
                      <p>
                      Our firm provides high-end SEO services in Hyderabad for on-page, technical and off-page optimization.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Social Media Marketing (SMM)</Accordion.Header>
                    <Accordion.Body>
                      <p>Being the best leading social media marketing agency in Hyderabad, our company excels in delivering top-notch services.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Search Engine Marketing (SEM)</Accordion.Header>
                    <Accordion.Body>
                      <p>Our company is recognized as the premier PPC agency in Hyderabad, offering top-notch services.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            {/* <p>
                We also offer a variety of custom engineering services to meet the specific needs of our clients. If you have a data-driven challenge, we can help you to find a solution.
              </p> */}
            <div className='mt-3'>
              <Link to='/marketingtabs' className="btn btn-primary" >More Info</Link>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
              <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>

          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>

  )
}

export default DigitalMarketing