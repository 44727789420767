import React from "react";
import { Link } from "react-router-dom";
import vectorImg from "./../../public/images/bigdata-analytics/vector.png";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Pagination, Autoplay } from "swiper/modules";

import Industries from './Industries'
const IndustriesWeServe = () => {
  
  return (
    <>
      <div className="industries-serve-area">
        <div className="container">
          <div className="section-title">
            <h2 className="mt-5">Industries We Serve</h2>
            <div className="bar"></div>
            <p>
              Delivering comprehensive solutions tailored to your needs, enhancing your experience through our diverse range of services.
            </p>
          </div>

          <Industries />
          {/* <div className="row justify-content-center">
            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-buildings"></i>
                </div>
                Manufacturing
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="200"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-building-house"></i>
                </div>
                Healthcare
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-car"></i>
                </div>
                Automobile
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-credit-card"></i>
                </div>
                Banking
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-building"></i>
                </div>
                Real Estate
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bxs-plane-alt"></i>
                </div>
                Logistics
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="700"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-group"></i>
                </div>
                Insurance
                <Link to="" className="link-btn"></Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-6 col-sm-6 col-md-4"
              data-aos="fade-in"
              data-aos-delay="800"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="single-industries-serve-box">
                <div className="icon">
                  <i className="bx bx-bitcoin"></i>
                </div>
                Capital Markets
                <Link to="" className="link-btn"></Link>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </>
  );
};

export default IndustriesWeServe;
