import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import python from '../../public/images/Technologies/python.png';
import nodejs from '../../public/images/Technologies/nodejs.png';
import Angular from '../../public/images/Technologies/angular.png';
import java from '../../public/images/Technologies/java.png';
import react from '../../public/images/Technologies/reactjs.png';
import mongodb from '../../public/images/Technologies/mongobd.png';
import mysql from '../../public/images/Technologies/mysql.png';
import springboot from '../../public/images/Technologies/springboot.png';
import wp from '../../public/images/Technologies/wp.png';
import php from '../../public/images/Technologies/php.png';

const imagesData = [
  { src: python, alt: 'Python', height: 50, width: 100 },
  { src: nodejs, alt: 'Node.js', height: 50, width: 100 },
  { src: wp, alt: 'WordPress', height: 50, width: 100 },
  { src: php, alt: 'PHP', height: 50, width: 100 },
  { src: react, alt: 'React.js', height: 50, width: 100 },
  { src: Angular, alt: 'Angular', height: 50, width: 100 },
  { src: java, alt: 'Java', height: 50, width: 100 },
  { src: mongodb, alt: 'MongoDB', height: 50, width: 100 },
  { src: springboot, alt: 'Spring Boot', height: 50, width: 100 },
  { src: mysql, alt: 'MySQL', height: 50, width: 100 }
];

const Brand = () => {
  const [slider, setSlider] = useState(null);

  useEffect(() => {
    if (!slider) {
      const brandSlider = new Swiper('#brandSlider1', {
        breakpoints: {
          0: { slidesPerView: 7 },
          576: { slidesPerView: 5 },
          768: { slidesPerView: 5 },
          992: { slidesPerView: 5 },
          1200: { slidesPerView: 5 },
          1400: { slidesPerView: 6 }
        },
        loop: true // Enable continuous loop
      });
      setSlider(brandSlider);
    }
  }, [slider]);

  useEffect(() => {
    const slideNextWithDelay = () => {
      if (slider) {
        setTimeout(() => {
          slider.slideNext();
          slideNextWithDelay();
        }, 3000);
      }
    };

    slideNextWithDelay();

    return () => clearTimeout();
  }, [slider]);

  return (
    <div className='mb-4'>
      <h3 className='text-center mt-5'>Technologies we use</h3>
      <div className="brand-area py-5 mt-1">
        <div className="container th-container">
          <div className="swiper th-slider" id="brandSlider1">
            <div className="swiper-wrapper">
              {imagesData.map((image, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="brand-box">
                    <img src={image.src} alt={image.alt} height={image.height} width={image.width} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
