import React from 'react'
import NavbarStyleFour from '../../../components/_App/NavbarStyleFour'
import Footer from '../../../components/_App/Footer'
import { useNavigate } from 'react-router-dom'

const SuccessGIT = () => {
    const navigate=useNavigate()
    return (
        <div>
            <NavbarStyleFour />
            <div style={{ fontFamily: 'Arial, sans-serif', color: '#333333', lineHeight: '1.6',marginTop:'100px' }}>
                <div style={{ width: '80%', margin: 'auto', padding: '20px', border: '1px solid #dddddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
                    <div style={{ textAlign: 'center', padding: '10px 0' }}>
                        <h1 style={{ margin: 0, color: '#0C0C39' }}>Thank You for Reaching Out!</h1>
                    </div>
                    <div style={{ margin: '20px 0' }}>
                        <p>Hi </p>
                        <p>Thank you for contacting <b>Skill Technologies</b> . Our team will GET BACK TO YOU shortly.</p>
                        <p>Best regards,</p>
                        <p>Swetha</p>
                        <p>Skill Technologies</p>
                        {/* <a href={`mailto:${"swetha.y@skilltechnologies.in"}`} className='btn btn-primary'>Explore Services</a> */}
                        <a className='btn btn-primary' onClick={()=>navigate('/services')}>Explore Services</a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SuccessGIT