import React from "react";
import { Link, useNavigate } from "react-router-dom";
import discoverImg1 from "./../../public/images/bigdata-analytics/discover-img1.png";
import discoverImg2 from "./../../public/images/bigdata-analytics/discover-img2.jpg";
import analyticsShape1 from "./../../public/images/bigdata-analytics/analytics-shape1.png";

import CanvasJSReact from '@canvasjs/react-charts';
import Chart from 'chart.js/auto';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DiscoverArea = () => {
  const navigate = useNavigate()
  const SaleslineChartoptions = {
    animationEnabled: true,
    axisY: {
      includeZero: true,
      interval: 50,
      maximum: 350,
      labelFontColor: "white",
    },
    axisX: {
      labelFontColor: "white",
    },
    toolTip: {
      shared: true
    },
    backgroundColor: 'rgba(255, 255, 255, 0)',
    legend: {
      fontColor: "white", // Set legend item text color to white
      cursor: "pointer",
    },
    data: [
      {
        type: "spline",
        color: 'green',
        showInLegend: true,
        name: "Clients Growth",
        dataPoints: [
          { y: 45, label: "2015", },
          { y: 65, label: "2016" },
          { y: 78, label: "2017" },
          { y: 86, label: "2018" },
          { y: 98, label: "2019" },
          { y: 110, label: "2020" },
          { y: 121, label: "2021" },
          { y: 134, label: "2022" },
          { y: 150, label: "2023" },
          { y: 198, label: "2024" }
        ]
      },
      {
        type: "spline",
        color: '#6610f2',
        showInLegend: true,
        name: "Revenue Growth IN Lakhs",
        dataPoints: [
          { y: 35, label: "2015" },
          { y: 49, label: "2016" },
          { y: 52, label: "2017" },
          { y: 63, label: "2018" },
          { y: 77, label: "2019" },
          { y: 89, label: "2020" },
          { y: 116, label: "2021" },
          { y: 150, label: "2022" },
          { y: 196, label: "2023" },
          { y: 254, label: "2024" }
        ]
      }
    ]
  };


  return (
    <>
      <div className="discover-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="discover-content mt-5">
                <h2>Building Success: Our Journey of Growth</h2>
                <p>
                Over the past nine years, Infoswan Group of Companies has consistently demonstrated remarkable growth,
                 steadily increasing its rate year after year. Through strategic investments, innovative technologies, 
                 and a commitment to excellence, Infoswan Group of Companies has not only expanded its market presence
                  but has also elevated industry standards. This sustained growth is a testament to our dedication to 
                  delivering exceptional solutions and services, driving us towards even greater success in the future
                </p>

                <button onClick={() => navigate('/projects')} className="btn btn-primary">
                  Discover More
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="discover-image">

                <div>
                  <CanvasJSChart options={SaleslineChartoptions} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="analytics-shape1">
          <img
            src={analyticsShape1}
            alt="img"
            width={523}
            height={586}
          />
        </div>
      </div>
    </>
  );
};

export default DiscoverArea;
