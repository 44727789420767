import React, { useState, useEffect } from 'react';
import Page1 from './Page-1';
import Page2 from './Page-2';
import Page3 from './Page-3';
import Page4 from './Page-4';
import NavbarStyleFour from '../_App/NavbarStyleFour';
import Footer from '../_App/Footer';

const ApplicationForm = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 4;
  const [allData, setAllData] = useState({});

  useEffect(() => {

    const page1 = localStorage.getItem('FormDataPage1');
    const page2 = localStorage.getItem('FormDataPage2');
    const page3 = localStorage.getItem('FormDataPage3');
    const page4 = localStorage.getItem('FormDataPage4');

    const formData = {
      page1: page1 ? JSON.parse(page1) : null,
      page2: page2 ? JSON.parse(page2) : null,
      page3: page3 ? JSON.parse(page3) : null,
      page4: page4 ? JSON.parse(page4) : null,
    };

    setAllData(formData);
  }, []);



  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderFormPage = () => {
    switch (currentPage) {
      case 1:
        return <Page1 nextPage={nextPage} />;
      case 2:
        return <Page2 nextPage={nextPage} />;
      case 3:
        return <Page3 nextPage={nextPage} />;
      case 4:
        return <Page4 />;
      default:
        return null;
    }
  };



  const isDataValid = () => {
    // Check if any of the form pages are null
    return Object.values(allData).every(pageData => pageData !== null);
  };

  return (
    <>
    <NavbarStyleFour />
      <div style={{marginTop:'100px'}}>

        {renderFormPage()}

        <div style={{ marginTop: '10px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'sticky', bottom: '0px' }}>
          <>
            <button className='btn btn-primary mr-2 p-2' onClick={prevPage} type='button' disabled={currentPage === 1}>Previous</button>
            <p>{currentPage} of {totalPages}</p>
            {currentPage !== totalPages && <button className='btn btn-primary ml-2 p-2' type='button' onClick={nextPage}>Next</button>}
            {currentPage === totalPages && <button className='btn btn-primary ml-2 p-2' type='button'>Submit</button>}
          </>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ApplicationForm;
