import React from "react";
import icon8 from "../../public/images/icon7.png";
import icon6 from "../../public/images/icon5.png";
import icon7 from "../../public/images/icon6.png";
import about from '../../public/images/Whatwedo/about-us.jpg'

const About = () => {

  return (
    <>
      {/* <NavbarStyleFour /> */}
      {/* <PageBanner pageTitle="About Us" /> */}
      <div className="about-area ptb-80">
        <div className="section-title">
          <h3 id="about">About Skill Technologies</h3>
          <div className="bar"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-image">
                <img
                  src={about}
                  alt="image"
                  style={{ width: '90%', height: '300px' }}
                  className="bg-banner-img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content">
                <div className="section-title">
                  <p className="fw-bold lead color-para">Quality With Efficiency To Provide Better Experiences!</p>

                  <p>
                    Maintain The Security And Availability Of Your Business.
                  </p>
                </div>
                <p>
                  Welcome to Skill Technologies, the top destination for the comprehensive digital solutions. With over a decade of experience in the IT industry, we have established ourselves as the leading provider of best website development, software development, and digital marketing services near kondapur, hyderabad. Our highly cost-effective and optimized solutions, coupled with our commitment to excellence, help our clients achieve their business goals in a timely and convenient manner.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-inner-area container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box Services-solutions-box">
                <div className="icon">
                  <img src={icon6} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  Our Vision
                </h3>
                <p>
                  Empowering businesses to thrive in the digital age through innovative solutions and unparalleled expertise.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box Services-solutions-box">
                <div className="icon">
                  <img src={icon7} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  Our Mission
                </h3>
                <p>
                  To deliver cutting-edge digital solutions that drive measurable results, exceed client expectations, and foster long-term partnerships.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-solutions-box Services-solutions-box">
                <div className="icon">
                  <img src={icon8} alt="icon" width={60} height={60} />
                </div>
                <h3>
                  Our Story
                </h3>
                <p>
                  Since 2015, Skill Technologies has been at the forefront of the digital revolution, helping businesses harness the power of technology to achieve their goals and we have proudly served over 100 clients across diverse industries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="funfacts-area ptb-80 mb-5">
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default About