// import React, { useState } from 'react'
// import AdminPageBanner from './CommonAdmin/AdminPageBanner'
// import Footer from './../../components/_App/Footer'
// import Modal from 'react-modal';
// const JobPost = () => {
//     const suggestions = [
//         { id: 1, title: 'Software Engineer' },
//         { id: 2, title: 'Web Developer' },
//         { id: 3, title: 'Data Analyst' },
//         { id: 4, title: 'Product Manager' },
//     ];
//     const [inputValue, setInputValue] = useState('');
//     const [comName, setComName] = useState('Skill Technologies')
//     const [showSuggestions, setShowSuggestions] = useState(false);
//     const [location, setLocation] = useState('Plot No 14, Green Hamlet, Silpa Park, Kondapur, Hyderabad, Telangana - 500084 India')
//     const handleInputChange = (e) => {
//         const value = e.target.value;
//         setInputValue(value);
//         setShowSuggestions(value.trim().length > 0);
//     };

//     const handleSuggestionClick = (title) => {
//         setInputValue(title);
//         setShowSuggestions(false);
//     };
//     // modal
//     const [modalIsOpen, setModalIsOpen] = useState(true);
//     const openModal = () => {
//         setModalIsOpen(true);
//     };

//     const closeModal = () => {
//         setModalIsOpen(false);
//     };
//     return (
//         <div>
//             <AdminPageBanner pageTitle={'Post A Job'} />
//             <div className="container">
//                 <h2 className='mt-5'>Tell us who you're hiring</h2>
//                 <div className="row">
//                     <div className="col-md-4">
//                         <label className='job-post-label' htmlFor="jobTitle">Job Title <span className="text-danger">*</span></label>
//                         <input
//                             type="text"
//                             id="jobTitle"
//                             value={inputValue}
//                             onChange={handleInputChange}
//                             className='form-control border'
//                         />
//                         {showSuggestions && (
//                             <ul className="suggestions-list">
//                                 {suggestions
//                                     .filter(suggestion =>
//                                         suggestion.title.toLowerCase().includes(inputValue.trim().toLowerCase())
//                                     )
//                                     .map(suggestion => (
//                                         <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion.title)}>
//                                             {suggestion.title}
//                                         </li>
//                                     ))}
//                             </ul>
//                         )}
//                     </div>
//                     <div className="col-md-4">
//                         <label htmlFor="companey">Companey <span className="text-danger">*</span></label>
//                         <input type="text" className='form-control border' value={comName} onChange={(e) => setComName(e.target.value)} />
//                     </div>
//                     <div className="col-md-4">
//                         <label htmlFor="workplacetype">Workplace Type</label>
//                         <select className="form-select select-custom border">
//                             <option value="select">---Select---</option>
//                             <option value="on-site">On-site</option>
//                             <option value="hybrid">Hybrid</option>
//                             <option value="remote">Remote</option>
//                         </select>
//                     </div>
//                 </div>
//                 <div className="row mt-3">
//                     <div className="col-md-4">
//                         <label htmlFor="Job Location">Job Location <span className="text-danger">*</span></label>
//                         <input type="text" className='form-control border' value={location} onChange={(e) => setLocation(e.target.value)} />
//                     </div>
//                     <div className="col-md-4">
//                         <label htmlFor="jobtype">Job Type</label>
//                         <select className="form-select select-custom border">
//                             <option value="select">---Select---</option>
//                             <option value="Full-Time">Full-Time</option>
//                             <option value="Part-Time">Part-Time</option>
//                             <option value="Contract">Contract</option>
//                             <option value="Temporary">Temporary</option>
//                             <option value="Internship">Internship</option>
//                             <option value="Others">Others</option>
//                         </select>
//                     </div>
//                     <div className="row">
//                         <div className="col-md-4">
//                             <label htmlFor="">Description</label>
//                             <textarea name="" id="" className='form-control'></textarea>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="btn btn-primary mt-3" onClick={() => openModal()}>Post</div>
//             </div>
//             <div>
//                 <Modal
//                     isOpen={modalIsOpen}
//                     onRequestClose={closeModal}
//                     className="popup-content-admin"
//                     overlayClassName="modal-overlay"
//                 >
//                     <>
//                         <img src="https://cdn.dribbble.com/users/1303437/screenshots/3686809/success_400x300.gif" className='success-job-post' alt="" />
//                         <p className="lead text-center">Job Posted Successfully</p>
//                         <div className="d-flex justify-content-center">
//                             <button className="btn btn-primary" onClick={()=>closeModal()}>OK</button>
//                         </div>
//                     </>
//                 </Modal>
//             </div>
//             <Footer />
//         </div>
//     )
// }

// export default JobPost
import React, { useState } from 'react'
import AdminPageBanner from './CommonAdmin/AdminPageBanner'
import Footer from './../../components/_App/Footer'
import Modal from 'react-modal';

const JobPost = () => {
    const suggestions = [
        { "id": 1, "title": "Software Engineer" },
        { "id": 2, "title": "Web Developer" },
        { "id": 3, "title": "Data Analyst" },
        { "id": 4, "title": "Product Manager" },
        { "id": 5, "title": "Frontend Developer (React)" },
        { "id": 6, "title": "Frontend Developer (Angular)" },
        { "id": 7, "title": "Frontend Developer (Vue.js)" },
        { "id": 8, "title": "Frontend Developer (JavaScript)" },
        { "id": 9, "title": "Frontend Developer (HTML/CSS)" },
        { "id": 10, "title": "Backend Developer (Node.js)" },
        { "id": 11, "title": "Backend Developer (Python)" },
        { "id": 12, "title": "Backend Developer (Java)" },
        { "id": 13, "title": "Backend Developer (.NET)" },
        { "id": 14, "title": "Full Stack Developer (React + Node.js)" },
        { "id": 15, "title": "Full Stack Developer (Angular + Node.js)" },
        { "id": 16, "title": "Full Stack Developer (Vue.js + Node.js)" },
        { "id": 17, "title": "Full Stack Developer (JavaScript + Node.js)" },
        { "id": 18, "title": "Full Stack Developer (Python + Django)" },
        { "id": 19, "title": "Full Stack Developer (Java + Spring Boot)" },
        { "id": 20, "title": "Full Stack Developer (.NET + ASP.NET)" },
        { "id": 21, "title": "Mobile App Developer (iOS)" },
        { "id": 22, "title": "Mobile App Developer (Android)" },
        { "id": 23, "title": "Mobile App Developer (React Native)" },
        { "id": 24, "title": "Mobile App Developer (Flutter)" },
        { "id": 25, "title": "DevOps Engineer" },
        { "id": 26, "title": "Cloud Solutions Architect" },
        { "id": 27, "title": "Data Engineer" },
        { "id": 28, "title": "Data Scientist" },
        { "id": 29, "title": "Machine Learning Engineer" },
        { "id": 30, "title": "Artificial Intelligence Engineer" },
        { "id": 31, "title": "UI/UX Designer" },
        { "id": 32, "title": "Software Quality Assurance Engineer" },
        { "id": 33, "title": "Software Testing Engineer" },
        { "id": 34, "title": "Cybersecurity Analyst" },
        { "id": 35, "title": "Blockchain Developer" },
        { "id": 36, "title": "Game Developer (Unity)" },
        { "id": 37, "title": "Game Developer (Unreal Engine)" },
        { "id": 38, "title": "Embedded Systems Engineer" },
        { "id": 39, "title": "IoT Developer" },
        { "id": 40, "title": "Robotics Engineer" },
        { "id": 41, "title": "Web Designer" },
        { "id": 42, "title": "Systems Administrator" },
        { "id": 43, "title": "Network Engineer" },
        { "id": 44, "title": "Database Administrator (DBA)" },
        { "id": 45, "title": "ERP Developer" },
        { "id": 46, "title": "CRM Developer" },
        { "id": 47, "title": "E-commerce Developer" },
        { "id": 48, "title": "Content Management System (CMS) Developer" },
        { "id": 49, "title": "API Developer" },
        { "id": 50, "title": "Chatbot Developer" },
        { "id": 51, "title": "Virtual Reality (VR) Developer" },
        { "id": 52, "title": "Augmented Reality (AR) Developer" },
        { "id": 53, "title": "Audio/Video Streaming Developer" },
        { "id": 54, "title": "Technical Support Engineer" }
    ]


    const [inputValue, setInputValue] = useState('');
    const [comName, setComName] = useState('Skill Technologies');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [location, setLocation] = useState('Plot No 14, Green Hamlet, Silpa Park, Kondapur, Hyderabad, Telangana - 500084 India');
    const [jobType, setJobType] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setShowSuggestions(value.trim().length > 0);
    };

    const handleSuggestionClick = (title) => {
        setInputValue(title);
        setShowSuggestions(false);
    };

    const openModal = () => {
        // Perform form validation before opening the modal
        const errors = {};
        if (!inputValue.trim()) {
            errors.jobTitle = 'Job title is required';
        }
        if (!comName.trim()) {
            errors.comName = 'Company name is required';
        }
        if (!location.trim()) {
            errors.location = 'Location is required';
        }
        if (!jobType) {
            errors.jobType = 'Job type is required';
        }
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setModalIsOpen(true);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    // new
    const [inputValuetag, setInputValuetag] = useState('');
    const [skills, setSkills] = useState([]);

    const handleInputChangetag = (e) => {
        setInputValuetag(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addSkill();
        }
    };

    const addSkill = () => {
        if (inputValuetag.trim() !== '') {
            setSkills([...skills, inputValuetag.trim()]);
            setInputValuetag('');
        }
    };

    const removeSkill = (indexToRemove) => {
        const updatedSkills = skills.filter((_, index) => index !== indexToRemove);
        setSkills(updatedSkills);
    };
    // 2nd
    const [commtags, setCommTags] = useState([]);

    const handleInputChange2 = (e) => {
        const inputValue = e.target.value;
        if (inputValue.endsWith(',')) {
            const newCommTag = inputValue.slice(0, -1).trim(); // Remove the comma and trim whitespace
            if (newCommTag !== '') {
                setCommTags([...commtags, newCommTag]);
            }
            e.target.value = ''; // Clear the textarea after adding the commtag
        }
    };

    const handleTagDelete = (index) => {
        const updatedCommTags = [...commtags];
        updatedCommTags.splice(index, 1);
        setCommTags(updatedCommTags);
    };

    return (
        <div>
            <AdminPageBanner pageTitle={'Post A Job'} />
            <div className="container">
                <h2 className='mt-5'>Tell us who you're hiring</h2>
                {/* <div className="row">
                    <div className="col-md-4" style={{ position: 'relative' }}>
                        <label className='job-post-label' htmlFor="jobTitle">Job Title <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            id="jobTitle"
                            value={inputValue}
                            onChange={handleInputChange}
                            className={`form-control border ${formErrors.jobTitle && 'is-invalid'}`}
                        />
                        {showSuggestions && (
                            <ul className="suggestions-list" style={{ position: 'absolute', top: '100%', width: '95%', left: 10, top: 80, zIndex: 1000, maxHeight: '150px', overflowY: 'auto' }}>
                                {suggestions
                                    .filter(suggestion =>
                                        suggestion.title.toLowerCase().includes(inputValue.trim().toLowerCase())
                                    )
                                    .map(suggestion => (
                                        <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion.title)}>
                                            {suggestion.title}
                                        </li>
                                    ))}
                            </ul>
                        )}
                        {formErrors.jobTitle && <div className="invalid-feedback">{formErrors.jobTitle}</div>}
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="companey">Company <span className="text-danger">*</span></label>
                        <input type="text" className={`form-control border ${formErrors.comName && 'is-invalid'}`} value={comName} onChange={(e) => setComName(e.target.value)} />
                        {formErrors.comName && <div className="invalid-feedback">{formErrors.comName}</div>}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="workplaceType">Workplace Type</label>
                        <select className={`form-select select-custom border ${formErrors.jobType && 'is-invalid'}`} onChange={(e) => setJobType(e.target.value)}>
                            <option value="">---Select---</option>
                            <option value="on-site">On-site</option>
                            <option value="hybrid">Hybrid</option>
                            <option value="remote">Remote</option>
                        </select>
                        {formErrors.jobType && <div className="invalid-feedback">{formErrors.jobType}</div>}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor="jobLocation">Job Location <span className="text-danger">*</span></label>
                        <input type="text" className={`form-control border ${formErrors.location && 'is-invalid'}`} value={location} onChange={(e) => setLocation(e.target.value)} />
                        {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="jobType">Job Type</label>
                        <select className="form-select select-custom border">
                            <option value="">---Select---</option>
                            <option value="Full-Time">Full-Time</option>
                            <option value="Part-Time">Part-Time</option>
                            <option value="Contract">Contract</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Internship">Internship</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-5">
                        <label htmlFor="">Job Title</label>
                        <select name="" id="" className="form-select">
                            <option value="Select">---Select---</option>
                            <option value="Executive">Executive</option>
                            <option value="Manager">Manager</option>
                            <option value="Service">Service</option>
                            <option value="Junior">Junior</option>
                            <option value="Management">Management</option>
                        </select>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="">Position Level</label>
                        <input type="text" className='form-control' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <label htmlFor="location">Location</label>
                        <input type="text" className="form-control" />
                    </div>
                    {/* <div className="col-md-4">
                        <label htmlFor="Job Type">Job Type</label>
                        <select>
                            <option value="">---Select---</option>
                            <option value="Onsite">Onsite</option>
                            <option value=""></option>
                        </select>
                    </div> */}
                    <div className="col-md-5">
                        <label htmlFor="Exptype" className='mt-4'>Experience Type</label>
                        <select className='form-select'>
                            <option value="select">---Select---</option>
                            <option value="Permanent">Permanent</option>
                            <option value="Temporary">Temporary</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <label htmlFor="Type">Job Type</label>
                        <select className="form-select">
                            <option value="fulltime">Full Time</option>
                            <option value="Parttime">Part Time</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="JobDesc">Job Description</label>
                        <textarea className='form-control'></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="skill">Skills</label>
                        <textarea
                            className='form-control'
                            name="skills"
                            id="skills"
                            value={inputValuetag}
                            onChange={handleInputChangetag}
                            onKeyDown={handleInputKeyPress}
                        ></textarea>
                        <div className="tags">
                            {skills.map((skill, index) => (
                                <span key={index} className="tag pointer bg-success text-white p-1 mt-5 me-2">
                                    {skill}
                                    <a
                                        className="tag-remove"
                                        onClick={() => removeSkill(index)}
                                    >
                                        &nbsp; &times;
                                    </a>
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="commtagInput">Communication</label>
                        <textarea id="commtagInput" className='form-control' onChange={handleInputChange2}></textarea>
                        <div>
                            {commtags.map((commtag, index) => (
                                <span key={index} className="commtag tag pointer bg-success text-white p-1 mt-5 me-2">
                                    {commtag}
                                    <a onClick={() => handleTagDelete(index)}> &nbsp; x</a>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="">Tools</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="">Experience Required</label>
                        <select className="form-select">
                            <option value="">0-1 Year</option>
                            <option value="">1-5 Year</option>
                            <option value="">5+ Years</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="">Prefered Date of Joining</label>
                        <input type="date" className='form-control' />
                    </div>
                </div>
                {/* <div className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor="SkillSet ">SkillSet</label>
                        <input className="form-control"></input>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="JD ">JD</label>
                        <input className="form-control"></input>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="jobDescription">Description</label>
                        <textarea className="form-control" rows="3"></textarea>
                    </div>
                </div> */}
                <div className="btn btn-primary mt-3" onClick={openModal}>Post</div>
            </div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="popup-content-admin"
                    overlayClassName="modal-overlay"
                >
                    <>
                        <img src="https://cdn.dribbble.com/users/1303437/screenshots/3686809/success_400x300.gif" className='success-job-post' alt="" />
                        <p className="lead text-center">Job Posted Successfully</p>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary" onClick={closeModal}>OK</button>
                        </div>
                    </>
                </Modal>
            </div>
            <Footer />
        </div>
    );
};

export default JobPost;
