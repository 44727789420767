import React from 'react'
import PageBanner from '../Common/PageBanner'
import Footer from '../_App/Footer'
import NavbarStyleFour from '../_App/NavbarStyleFour'

const ReturnPolicy = () => {
  return (
   <>
   <NavbarStyleFour />
   <PageBanner pageTitle= "Skill Technologies Refund And Return Policy" />
   <div className="container">
    <h2>General Policy</h2>
    <p>Given the nature of digital services offered by Skill Technologies “NO REFUND”,“NO CANCELLATION” will be entertained once the Payment has been made.. However, we are committed to ensuring customer satisfaction and may offer refunds or credits at our discretion</p>
    <h2>Cancellation Policy</h2>
    <p>Please note an order can only be canceled within 24 hours of placing the order. If you wish to cancel a service, please contact us as soon as possible. Refunds for cancelled services will be considered on a case-by-case basis and may incur cancellation fees</p>
    <p>Requests received later than 02 business days prior to the end of the current service period will be treated as cancellation of services for the next service period.</p>
    <h2>Refund Policy</h2>
    <p>Refunds, when approved, will be processed within 4 working days and will be credited back to the original method of payment</p>
    <h2>Project Termination</h2>
    <p>In the event that a project is terminated by either party, the client will be billed for all work completed up to the point of termination. Any advance payment for services not yet rendered will be refunded, minus any applicable cancellation fees.</p>
    <h2>Feedback And Complaints</h2>
    <p>We aim to resolve any issues or complaints efficiently and satisfactorily. Please direct any feedback or complaints to our customer service team at info@skilltechnologies.in</p>
    <h2>Governing Law</h2>
    <p>This Refund and Return Policy is governed by the laws of India and any disputes will be resolved in the courts of Hyderabad, Telangana</p>
   </div>
   <Footer />
   </>
  )
}

export default ReturnPolicy