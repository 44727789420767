import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import blogDetails from "../../public/images/blog-image/blog1.png";
import blogImg1 from "../../public/images/blog-image/blog1.png";
import NavbarStyleFour from "../_App/NavbarStyleFour";
import Footer from "../_App/Footer";
const BlogDetailsContent = () => {
  return (
    <>
      <NavbarStyleFour />
      <div className="blog-details-area ptb-80">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src={blogDetails}
                    alt="image"
                    width={860}
                    height={700}
                  />
                </div>

                <div className="article-content">
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <Icon.Clock /> April 2024
                      </li>
                      <li>
                        <Icon.User /> <Link href="#">Saikiran</Link>
                      </li>
                    </ul>
                  </div>

                  <h2>AI and Machine Learning In Digital Marketing</h2>

                  <p>
                    Explore how machine learning and artificial intelligence are changing digital marketing with tailored content recommendations and predictive analytics.
                  </p>

                  <p>
                    Digital marketing is changing as a result of artificial intelligence (AI) and machine learning (ML), which are giving marketers new and powerful tools to improve their approaches and interact with customers more successfully. This is an explanation of the ways in which these technologies are transforming digital marketing.
                  </p>

                  <blockquote>
                    <p>
                      In order to provide individualized content recommendations, AI and ML systems examine enormous volumes of data regarding user interests, behaviors, and interactions. Marketers are able to customize content offers, such as product recommendations, articles, or promotional messages, to each user's preferences by gaining insight into their unique interests and previous engagement patterns. User satisfaction, engagement, and conversion rates are all increased by this degree of customization.
                    </p>

                    <cite>Tom Cruise</cite>
                  </blockquote>

                  <p>
                    AI and ML-based algorithms perform a great job segmenting audiences based on a variety of attributes, such as hobbies, demographics, and purchasing habits. This tight segmentation allows marketers to create highly relevant and targeted messages that are tailored to specific audience categories. When marketers deliver the right content to the right audience at the right time, they may significantly boost engagement and conversion rates.
                  </p>
                </div>
                <div className="startp-post-navigation">
                  <div className="prev-link-wrapper">
                    <div className="info-prev-link-wrapper">
                      <Link href="#">
                        <span className="image-prev">
                          <img
                            src={blogImg1}
                            alt="image"
                            width={860}
                            height={700}
                          />
                          <span className="post-nav-title">Prev</span>
                        </span>

                        <span className="prev-link-info-wrapper">
                          <span className="prev-title">
                            Don't buy a tech gift until you read these rules
                          </span>
                          <span className="meta-wrapper">
                            <span className="date-post">January 21, 2022</span>
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-4 col-md-12">
              <BlogSidebar />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetailsContent;
