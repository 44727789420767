import React from 'react';
import PageBanner from '../Common/PageBanner';
import NavbarStyleFour from './NavbarStyleFour';
import Footer from './Footer';
import DGFTIEC from '../../public/Awards/1.png';
import ExcellenceAwards from '../../public/Awards/2.png';

const Awards = () => {
  const handleOpenInNewTab = () => {
    window.open('/companyprofile', '_blank');
  };

  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle="OUR RECOGNITIONS" />
      <div className="container">
        <div className="row">
          <div className="col-md-3 me-4 col-12">
            <div className="card" style={{ width: '16rem' }}>
              <div className="text-center">
                <img className="p-3" src={DGFTIEC} alt="card" />
              </div>
              <div className="card-body">
                <p className="text-primary">DGFTIEC</p>
                <h5 className="card-title">DGFTIEC</h5>
                <p className="card-text">Skill Tech is granted IEC (Importer -Exporter Code) by DGFT</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 ml-3 col-12">
            <div className="card" style={{ width: '16rem' }}>
              <div className="text-center">
                <img className="p-3" src={ExcellenceAwards} alt="card" />
              </div>
              <div className="card-body">
                <p className="text-primary">Excellence Awards</p>
                <h5 className="card-title">Excellence Awards</h5>
                <p className="card-text">Received excellence award form many major clients</p>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <button className="ms-auto btn btn-primary" onClick={handleOpenInNewTab}>Our Profile</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Awards;
