import React from 'react'
import './main.css'
import Continous from '../../public/WhySkt/Continus.png'
import Datdriven from '../../public/WhySkt/Data driven.png'
import economic from '../../public/WhySkt/Economic.png'
import Global from '../../public/WhySkt/Global.jpg'
import Work from '../../public/WhySkt/efficiency.png'
import Competatives from '../../public/WhySkt/Competative.PNG'
import Flexibility from '../../public/WhySkt/Scability.png'
import Future from '../../public/WhySkt/future-proof.jpg'
const WhySkt = () => {
    const bulletpoint = [
        {
            "point": "Economic Adaptability",
            "img" : economic
        },
        {
            "point": "Workforce Efficiency",
            "img" : Work
        },
        {
            "point": "Data-Driven Insights",
            "img" : Datdriven
        },
        {
            "point": "Global Competitiveness",
            "img" : Global
        },
        {
            "point": "Continuous Development",
            "img" : Continous
        },
        {
            "point": "Scalability and Flexibility",
            "img" : Flexibility
        },
        {
            "point": "Competitive Advantage",
            "img" : Competatives
        },
        {
            "point": "Future-Proofing Careers",
            "img" : Future,
        }
    ]

    return (
        <div className='container mb-5'>
            <div className="section-title">
                <h3>Why Skill Technologies</h3>
                <div className="bar"></div>
            </div>
            <div className="whyskt-cards ">
                {
                    bulletpoint.map((item, index) => (
                        <>
                            <div className="card-skt" key={index}>
                                <div className="card-text">
                                <img src={item.img} alt="" className='whyskt-img px-2'style={{borderRadius: '50px'}}/>
                                    {item.point}
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default WhySkt