import React from 'react'
import PageBanner from '../Common/PageBanner'
import img1 from '../../public/Clientimages/1.jpg'
import img2 from '../../public/Clientimages/2.jpg'
import img3 from '../../public/Clientimages/3.png'
import img4 from '../../public/Clientimages/4.png'
import img5 from '../../public/Clientimages/5.png'
import img6 from '../../public/Clientimages/6.png'
import img7 from '../../public/Clientimages/7.png'
import img8 from '../../public/Clientimages/8.png'
import img9 from '../../public/Clientimages/9.png'
import img10 from '../../public/Clientimages/10.png'
import img11 from '../../public/Clientimages/11.png'
import img12 from '../../public/Clientimages/12.png'
import img13 from '../../public/Clientimages/13.png'
import img14 from '../../public/Clientimages/14.png'
import img15 from '../../public/Clientimages/15.png'
import img16 from '../../public/Clientimages/16.png'
import img17 from '../../public/Clientimages/17.png'
import img18 from '../../public/Clientimages/18.png'
import img19 from '../../public/Clientimages/19.png'
import img20 from '../../public/Clientimages/20.jpg'
import img21 from '../../public/Clientimages/21.png'
import img22 from '../../public/Clientimages/22.png'
import img23 from '../../public/Clientimages/23.png'
import img24 from '../../public/Clientimages/24.png'
import img25 from '../../public/Clientimages/25.png'
import img26 from '../../public/Clientimages/26.png'
import img27 from '../../public/Clientimages/27.png'
import img28 from '../../public/Clientimages/28.png'
import img29 from '../../public/Clientimages/29.png'
import img30 from '../../public/Clientimages/30.png'
import img31 from '../../public/Clientimages/31.jpg'
import img32 from '../../public/Clientimages/32.png'
import img33 from '../../public/Clientimages/33.png'
import img34 from '../../public/Clientimages/34.png'
import img35 from '../../public/Clientimages/35.png'
import img36 from '../../public/Clientimages/36.jpg'
import img37 from '../../public/Clientimages/37.png'
import img38 from '../../public/Clientimages/38.jpg'
import img39 from '../../public/Clientimages/39.png'
import img40 from '../../public/Clientimages/40.png'
import img41 from '../../public/Clientimages/Group 101.png'
import img42 from '../../public/Clientimages/Serum Logo.jpg'
import img43 from '../../public/Clientimages/43.png'
import img44 from '../../public/Clientimages/44.jpg'
import img45 from '../../public/Clientimages/45.png'
import img46 from '../../public/Clientimages/46.png'
import img47 from '../../public/Clientimages/47.png'
import img48 from '../../public/Clientimages/48.png'
import img49 from '../../public/Clientimages/49.png'
import img50 from '../../public/Clientimages/50.png'
import img51 from '../../public/Clientimages/51.png'
import img52 from '../../public/Clientimages/52.png'
import { Card } from 'react-bootstrap';
import NavbarStyleFour from '../_App/NavbarStyleFour'
import Footer from '../_App/Footer'
const imagePaths = [
  img52, img49, img50, img51,  img41, img42, img43, img44, img45, img46, img47, img48, 
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
  img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
  img21, img22, img23, img24, img25, img26, img27, img28, img29, img30,
  img31, img32, img33, img34, img35, img36, img37, img38, img39, img40,
];

const Clients = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle="Our Clients" />
      <div className='container'>
        <div className="row">
          {imagePaths.map((img, index) => (
            <div key={index} className="col-md-2 mb-2">
              <Card className="shadow card-sizes">
                <img variant="top" src={img} alt={`Client ${index + 1}`} height={100} width={100} />
              </Card>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Clients;
