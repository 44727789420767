import React from 'react'
import PageBanner from "../Common/PageBanner";
import servicesDetailsImg1 from "../../public/Digitalmarketingimgs/itSupport.png";
import NavbarStyleFour from '../_App/NavbarStyleFour';
import Footer from '../_App/Footer';
import { Link } from 'react-router-dom';
import Contactformpopup from "../Contact/Contactformpopup";
const ItSupport = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='E-Commerce Applications' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <div className="services-details-desc">
                <h3>E-Commerce Applications</h3>
                <p>
                  Whether You Run An Independent FMCG Business, A Supermarket Chain, Or A Big Online Retail Company, We Have Solutions For All Your ECommerce Needs.
                </p>
                <p>
                  As one of the leading ITService Providers, our deep pool of over 20+ certified engineers and IT support staff are ready to help.
                </p>
                <p>
                  Skill Technologies with its expertise, handling ecommerce app development in following areas
                </p>
                <ul className='lists-type-services'>
                  <li className='lists-services'>eCommerce Apps for Grocery Shops & Retail Chains</li>
                  <li className='lists-services'>B2B eCommerce Solution for Distributors</li>
                  <li className='lists-services'>eCommerce Apps for cloth emporiums, general units</li>
                </ul>

              </div>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>
            <div className="d-flex justify-content-end mt-5">
            <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
             <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
           
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>

  )
}

export default ItSupport