import React from 'react'
import PageBanner from "../Common/PageBanner";
import servicesDetailsImg1 from "../../public/Digitalmarketingimgs/Appdev.png";
import NavbarStyleFour from '../_App/NavbarStyleFour';
import Footer from '../_App/Footer';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Contactformpopup from "../Contact/Contactformpopup";
const ApplicationDevelopment = () => {
  return (
    <>
      <NavbarStyleFour />
      <PageBanner pageTitle='APPLICATION DEVELOPMENT' />
      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 services-details">
              <h3>APPLICATION DEVELOPMENT</h3>
              <p>
                With the help of our application development services, discover the creative potential. Our team of knowledgeable developers creates unique solutions that boost productivity, increase efficiency, and improve your online presence-all of which benefit your company in real ways."
              </p>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Android Apps</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Skill Technologies is a leading Android app development company that offers expertise in Android app development services for the diverse business needs of clients.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>IOS Apps</Accordion.Header>
                  <Accordion.Body>
                    <p>To know about the product, customers & competitors offer integral communication services software assets.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Cross-Platform Apps</Accordion.Header>
                  <Accordion.Body>
                    <p>Cross-platform apps that deliver platform-independent experiences to meet your user, business, and industry needs.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-6 services-details-image">
              <img
                src={servicesDetailsImg1}
                alt="image"
                width={800}
                height={600}
              />
            </div>

            <div className="d-flex justify-content-end mt-5">
            <Link to="/services" className="btn btn-primary me-2">
                Back
              </Link>
             <Link to="/contact" className="btn btn-primary">
                Reach us
              </Link>
            </div>
          </div>

        </div>
      </div>
      <Contactformpopup />
      <Footer />
    </>

  )
}

export default ApplicationDevelopment